import Icon, { IconNames } from "@socion-cordio/common/src/components/atoms/icon";
import {
  Text,
  TextSize,
  FontWeight,
  FontFamily
} from "@socion-cordio/common/src/components/atoms/text";
import TextField from "@socion-cordio/common/src/components/atoms/textField";
import { colorPallete } from "@socion-cordio/common/src/assets/styles/colors";
import React, { ReactElement, useEffect, useState } from "react";
// import { country } from "@socion-cordio/common/src/components/atoms/constants";
import { ApiClient } from "@socion-cordio/common/src/network/apiClient";
import { loginEndPoints } from "@socion-cordio/common/src/repositories/endPoints";
import { LocalStorage } from "@socion-cordio/common/src/services/storage/storageService";
import {
  View,
  StyleSheet,
  Animated,
  ImageSourcePropType,
  ImageResizeMode,
  StyleProp,
  ImageStyle,
  ViewStyle,
  TextStyle
} from "react-native";
import Dropdown from "@socion-cordio/common/src/components/molecules/dropdown";
import Geolocation from "react-native-geolocation-service";
import axios from "axios";

interface IProps {
  icon?: IconNames;
  label?: string;
  textValue?: string;
  name?: string;
  placeholder?: string;
  dropdownPlaceholder?: any;
  value?: any;
  dropdownValue?: any;
  id?: string;
  handleKeyPress?: Function;
  inputStyle?: StyleProp<ViewStyle>;
  handleChange?: Function;
  handleBlur?: Function;
  errorStyle?: StyleProp<TextStyle>;
  onChangeValue?: (value: any) => void;
  noFormik?: boolean;
  editable?: boolean;
  disableDropDown?: boolean;
  setValidate?: Function;
  errors?: any;
  containerStyle?: StyleProp<ViewStyle>;
  countryCode?: any;
  dropDownContainerStyle?: StyleProp<ViewStyle>;
  updateddropdownCountryCode?: any;
  dropdownVal?: any;
  setCountryCodeObject?: any;
  containerRadius?: any;
  formikPropsTouched?: boolean;
  customChange?: Function;
  userInputStyleHeader?: StyleProp<TextStyle>;
  pickerStyle?: StyleProp<ViewStyle>;
  isFormikReinitialised?: boolean;
}

const styles = StyleSheet.create({
  textIcon: {
    color: colorPallete.textLight,
    fontSize: 14,
    fontFamily: FontFamily.Regular
  },
  input: {
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    borderTopRightRadius: 5,
    borderBottomRightRadius: 5,
    width: 233,
    height: 40,
    outline: "none"
    // borderColor: colorPallete.lightGreyTwo,
    // opacity: 0.4,
    // color: colorPallete.textBlack
  },
  label: {
    flex: 1,
    flexDirection: "row",
    marginBottom: 10
  },
  icon: {},
  inputContainer: {
    flex: 1,
    flexDirection: "row",
    marginLeft: 0
    // zIndex: -1
  },
  listItems: {
    paddingHorizontal: 5,
    paddingVertical: 2
  },
  placeholderStyle: {
    marginLeft: 5
  },
  arrowIconStyle: {
    top: -1
  },
  hideArrowIconStyle: {
    top: -1,
    display: "none"
  },
  containerStyle: {
    marginBottom: 0,
    display: "flex",
    width: "37ch",
    alignItems: "center",
    height: 20
  }
});

export default function MobileInput(props: IProps): ReactElement {
  const {
    icon,
    label,
    placeholder,
    textValue,
    name,
    value,
    dropdownValue,
    id,
    inputStyle,
    handleBlur,
    handleChange,
    handleKeyPress,
    onChangeValue,
    errorStyle = { start: -70 },
    noFormik,
    dropdownPlaceholder = "Select an item",
    editable = true,
    setValidate,
    containerStyle,
    countryCode = null,
    disableDropDown,
    updateddropdownCountryCode,
    dropdownVal,
    dropDownContainerStyle,
    containerRadius,
    formikPropsTouched,
    customChange,
    userInputStyleHeader,
    pickerStyle,
    isFormikReinitialised = false
  } = props;
  const [open, setOpen] = useState(false);
  const [val, setVal] = useState(0);
  const [updatedCountryCode, setUpdatedCountryCode] = useState(91);
  const [items, setItems] = useState([]);
  const [numberList, setNumberList] = useState([]);
  const [enteredValue, setNumberInputValue] = useState(null);

  useEffect(() => {
    async function fetchData() {
      let countryCode: any = await LocalStorage.getStorage("countryCode");
      let countryCodeObj: any = await LocalStorage.getStorage("countryCodeObj");
      countryCode === null || countryCodeObj === null
        ? [onPressForLocation()]
        : [setUpdatedCountryCode(countryCode), await getCountryCode(countryCode)];
    }
    fetchData();
  }, []);

  useEffect(() => {
    let filteredData = items.filter((i) => i.value == val);
    if (val) {
      let sanitisedCountryCode = val;
      setUpdatedCountryCode(sanitisedCountryCode);
    }
    filteredData.length !== 0
      ? [
          setSelectedCountryCodeHandler(filteredData[0].value, filteredData[0].isoShortCode),
          setUpdatedCountryCode(filteredData[0].value)
        ]
      : null;

    if (filteredData?.length && setValidate) setValidate(filteredData);
  }, [val]);

  const setSelectedCountryCodeHandler = (selectedCountryCode: any, selectedIsoShortCode: any) => {
    console.log("on selecteng cc", selectedCountryCode);
    const defaultCountryExist = items.filter(
      (element: any) => element.isoShortCode === selectedIsoShortCode
    );
    setUpdatedCountryCode(removeCharacterCountryCode(selectedCountryCode));
    setVal(selectedCountryCode);
  };

  const onPressForLocation = (toggle = false) => {
    navigator.permissions.query({ name: "geolocation" }).then(function (result) {
      if (navigator.permissions.query({ name: "geolocation" })) {
        if (result.state == "granted") {
          Geolocation.getCurrentPosition(
            (position: any) => {
              const params = {
                lat: position.coords.latitude,
                long: position.coords.longitude,
                lang: "en"
              };
              console.log("allowed");
              getUserCountryLocation(params);
            },
            (error: any) => {
              console.log(error);
            },
            {
              enableHighAccuracy: true,
              timeout: 15000,
              maximumAge: 10000,
              showLocationDialog: false
            }
          );
        } else if (result.state == "prompt") {
          navigator.geolocation.getCurrentPosition(revealPosition, positionDenied);
          console.log("location access to be prompted");
        } else if (result.state == "denied") {
          console.log("location access denied show localStorage");
          getCountryCode(91, "");
        } else {
          positionDenied();
          console.log("==============error on press loc====in else part on press loc =====");
        }
      }
    });
  };

  // const onPressForLocation = (toggle = false) => {
  //   // permissionHelper.permissionChecker(permission, title, message, (status: any) => {
  //   navigator.permissions.query({ name: "geolocation" }).then(function (result) {
  //     if (result.state == "granted") {
  //       Geolocation.getCurrentPosition(
  //         (position: any) => {
  //           const params = {
  //             lat: position.coords.latitude,
  //             long: position.coords.longitude,
  //             lang: "en"
  //           };
  //           // console.log("position:::    search in list with coordinates", position);
  //           getUserCountryLocation(params);
  //         },
  //         (error: any) => {
  //           // console.log("============== error on press loc =========", error);
  //         },
  //         { enableHighAccuracy: true, timeout: 15000, maximumAge: 10000, showLocationDialog: false }
  //       );
  //     } else if (result.state == "prompt") {
  //       navigator.geolocation.getCurrentPosition(revealPosition, positionDenied);
  //       // console.log("location access to be prompted");
  //     } else if (result.state == "denied") {
  //       // console.log("location access denied    show localStorage or +91");
  //     } else {
  //       // console.log("==============error on press loc====in else part on press loc =====");
  //     }
  //   });
  // };

  const revealPosition = () => {
    // console.log("Revel position");
    onPressForLocation();
  };

  const positionDenied = () => {
    // call api default value +91
    getCountryCode(91, undefined);
  };

  const getUserCountryLocation = async (params: any) => {
    try {
      const response: any = await axios.get(
        `https://maps.googleapis.com/maps/api/geocode/json?key=AIzaSyDVf-To9hehz4ORteAxgsP-yzHxsahVcDo&latlng=${params.lat},${params.long}&language=${params.lang}`
      );
      const result = response.data.results[0];
      result.address_components.map((item: any) => {
        if (item.types[0] === "country") {
          // console.log("item:::", item);
          const shortName = item.short_name;
          getCountryCode(null, shortName); //sending some default value 91.
        }
      });
    } catch (error) {}
  };

  const getCountryCode = async (selectedCountryCode?: any, isoShortCode?: string) => {
    let localStorageCountryCode = await LocalStorage.getStorage("countryCode");
    let countryCodeListResoponse: any;
    if (localStorageCountryCode === null) {
      countryCodeListResoponse = await ApiClient.get(loginEndPoints.getCountryCode());
      let formattedCountryCodeList = assignCountryCodeValuesHandler(
        countryCodeListResoponse.response
      );
      LocalStorage.setStorage("countryCodeList", countryCodeListResoponse.response);
      if (selectedCountryCode === null) {
        const IfCountryExist = countryCodeListResoponse.response.filter(
          (element: any) => element.isoShortCode === isoShortCode
        );
        if (IfCountryExist) {
          //when we find cc in response list.
          console.log("country exist", IfCountryExist);
          const sanitisedCoutryCode = removeCharacterCountryCode(IfCountryExist);
          setUpdatedCountryCode(sanitisedCoutryCode);
          setVal(sanitisedCoutryCode);
          setItems(formattedCountryCodeList);
        } else {
          setUpdatedCountryCode(91);
          setVal(91);
          setItems(formattedCountryCodeList);
        }
      } else {
        console.log("when denied location country default", selectedCountryCode);
        const defaultCountryExist = countryCodeListResoponse.response.filter(
          (element: any) => element.isoShortCode === "IN"
        );
        setUpdatedCountryCode(91);
        setVal(91);
        setItems(formattedCountryCodeList);
        LocalStorage.setStorage("countryCodeObj", defaultCountryExist[0]);
      }
    } else {
      console.log("set cc from ls");
      let localStorageCountryCodeList: any = await LocalStorage.getStorage("countryCodeList");
      setItems(assignCountryCodeValuesHandler(localStorageCountryCodeList));
    }
  };

  // const getCountryCode = async (value?: any, isoShortCode?: string) => {
  //   let result: any;
  //   let list: any;
  //   if (!localStorage["countryCodeList"]) {
  //     result = await ApiClient.get(loginEndPoints.getCountryCode());
  //     // console.log("value iff::", value, isoShortCode);
  //     // console.log("country list::", result.response);
  //     const countryExist = result.response.filter(
  //       (element: any) => element.isoShortCode === isoShortCode
  //     );
  //     console.log("countryExist::", countryExist);
  //     let countryCode: any;
  //     LocalStorage.setStorage("countryCodeList", result.response);
  //     list = assignCountryCodeValuesHandler(result.response);
  //     LocalStorage.setStorage("SelectedCountryCodeObj", countryExist[0]);
  //     // countryExist[0].code
  //     countryExist.length === 1
  //       ? [
  //           // console.log("set this country code"),
  //           (countryCode = (countryExist)),
  //           // console.log("countryCode", countryCode),
  //           validateHandler(list, countryCode)
  //         ]
  //       : countryExist.length > 1
  //       ? [
  //           // console.log("set first [0] element country code "),
  //           (countryCode = (countryExist)),
  //           validateHandler(list, countryCode)
  //         ]
  //       : [
  //           // console.log("set +91"),
  //           validateHandler(list, 91)
  //         ];

  //     // validateHandler(list, value);
  //   } else {
  //     // console.log("value  else:::", value);
  //     let tempList = await LocalStorage.getStorage("countryCodeList");
  //     // console.log("not here", tempList);
  //     list = assignCountryCodeValuesHandler(tempList);
  //     validateHandler(list, value);
  //   }

  //   setItems(list);
  //   // value == undefined ? valueSetHandler(newList, list, 91) : valueSetHandler(newList, list, value);
  // };

  const removeCharacterCountryCode = (countryCode: any) => {
    return parseInt(countryCode[0]?.code.replace(/[\D]/g, ""));
  };

  const assignCountryCodeValuesHandler = (result: any) => {
    const list: any = result?.map((data: any, id: any) => {
      return {
        label: `${data.code} (${data.country})`,
        value: parseInt(data.code),
        countryName: data.country,
        phoneNumberLength: data.phoneNumberLength,
        phoneNumberSizeMax: data.phoneNumberSizeMax,
        phoneNumberSizeMin: data.phoneNumberSizeMin,
        isoShortCode: data.isoShortCode,
        emailVerification: data.emailVerification,
        sequence: data.sequence,
        smsVerification: data.smsVerification
      };
    });
    return list;
  };

  // const validateHandler = (list: any, value: any) => {
  //   let newList: any;
  //   if (localStorage["user"]) {
  //     const userData = JSON.parse(localStorage["user"]);
  //     const userCountryCode = userData.countryCode.replace("+", "");
  //     // valueSetHandler(newList, list, +userCountryCode)
  //     value === userCountryCode ? valueSetHandler(newList, list, userCountryCode) : null;
  //     // console.log("iffff :::: ", value, userCountryCode);
  //   } else {
  //     //   value === undefined? valueSetHandler(newList, list, 91):null
  //     if (localStorage["countryCode"]) {
  //       const userCountryCode = JSON.parse(localStorage["countryCode"]);
  //       // console.log("userCountryCode", userCountryCode);
  //       value === userCountryCode ? valueSetHandler(newList, list, userCountryCode) : null;
  //       // console.log("elseee ::::   ==>> null", value, userCountryCode);
  //     } else {
  //       LocalStorage.setStorage("countryCode", value);
  //       // console.log("herree", value);
  //       setUpdatedCountryCode(value);
  //       setVal(value);
  //     }
  //   }
  // };

  // const valueSetHandler = (newList: any[], list: any[], value: number) => {
  //   newList = list.filter((item: any) => item.value === value);
  //   LocalStorage.setStorage("countryCode", value);
  //   // console.log("final value::: ", value);
  //   // newList[0].label = `+${newList[0].value}`;
  //   setUpdatedCountryCode(value);
  //   setVal(value);
  // };

  const onChange = (e: any) => {
    e.preventDefault();
    const enteredValue = e.target.value.replace(/\D/g, "");
    setNumberInputValue(enteredValue);
  };

  return (
    <View>
      {icon && textValue && (
        <View style={styles.label}>
          <Icon testID="home" name={icon} customStyle={styles.icon} />
          <Text
            fontWeight={FontWeight.Regular}
            testId="internal"
            textSize={TextSize.Small}
            style={[styles.textIcon, userInputStyleHeader]}
          >
            {" "}
            {textValue}
            {""}
          </Text>
        </View>
      )}

      <View style={styles.inputContainer}>
        <Dropdown
          open={open}
          dropdownValue={
            disableDropDown ? `${updateddropdownCountryCode}` : `${updatedCountryCode}`
          }
          // dropdownValue={val}
          items={items}
          setOpen={setOpen}
          setValue={disableDropDown ? dropdownVal : setVal}
          setItems={setItems}
          onChangeValue={(value) => onChangeValue(value)}
          dropdownPlaceholder={
            disableDropDown ? `+${updateddropdownCountryCode}` : `+${updatedCountryCode}`
          }
          disabled={disableDropDown ? true : false}
          // disabled={false}
          containerStyle={containerStyle}
          dropDownContainerStyle={dropDownContainerStyle}
          listItems={styles.listItems}
          placeholderStyle={styles.placeholderStyle}
          arrowIconStyle={disableDropDown ? styles.hideArrowIconStyle : styles.arrowIconStyle}
          containerRadius={containerRadius}
          pickerStyle={pickerStyle}
        />
        <TextField
          inputStyle={styles.input}
          name={name}
          placeholder={placeholder}
          label={label}
          value={isFormikReinitialised ? value : !editable ? value : enteredValue}
          id={id}
          handleChange={handleChange}
          handleBlur={handleBlur}
          handleKeyPress={handleKeyPress}
          errorStyle={errorStyle}
          noFormik={noFormik}
          editable={editable}
          onChange={onChange}
          formikPropsTouched={formikPropsTouched}
          customChange={customChange}
        ></TextField>
      </View>
    </View>
  );
}

import React, { ReactElement, useEffect, useRef, useState } from "react";
import { Form, Formik, FormikProps } from "formik";
import { StyleSheet, TouchableOpacity, View } from "react-native";
import { colorPallete } from "@socion-cordio/common/src/assets/styles/colors";
import {
  Text,
  TextSize,
  FontWeight,
  FontFamily
} from "@socion-cordio/common/src/components/atoms/text";
import UserInput from "@socion-cordio/common/src/components/molecules/userInput";
import Icon, { IconNames } from "@socion-cordio/common/src/components/atoms/icon";
import Button, { ButtonType } from "@socion-cordio/common/src/components/atoms/button";
import { Link } from "react-router-dom";
import { FlatList } from "react-native";
import { ApiClient } from "@socion-cordio/common/src/network/apiClient";
import { loginEndPoints } from "@socion-cordio/common/src/repositories/endPoints";
import { LocalStorage } from "@socion-cordio/common/src/services/storage/storageService";
import Geolocation from "react-native-geolocation-service";
import axios from "axios";
import { useTranslation } from "react-i18next";
import WrapperForm from "@socion-cordio/common/src/components/organisms/signup/signupNew/wrapperForm";
import { useHistory } from "react-router-dom";
import EmailMobileInput from "@socion-cordio/common/src/components/molecules/emailMobileInput";
import { HTTP_STATUS_CODES } from "@socion-cordio/common/src/network/constants";
import { toast } from "react-toastify";
import { LoginRepository } from "@socion-cordio/common/src/repositories/login/login";
import Select, { components, SingleValueProps } from "react-select";
interface Props {
  onLoginSubmit: (data: { countryCode: string; password: string; userName: string }) => void;
  setSelectedCountryObject?: Function;
  setEmailVerification?: Function;
}

const OPTIONS_MODE = [
  {
    value: "Mobile number",
    label: "Mobile number",
    icon: IconNames.mobile
  },
  {
    value: "Email ID",
    label: "Email ID",
    icon: IconNames.email
  }
];

export default function LoginForm(props: Props): ReactElement {
  const { onLoginSubmit } = props;
  const { t } = useTranslation();
  const [validate, setValidate] = useState([
    {
      label: "+91",
      phoneNumberLength: 10,
      phoneNumberSizeMax: 10,
      phoneNumberSizeMin: 10,
      value: 91,
      code: "+91",
      country: "India",
      emailVerification: false,
      isoShortCode: "IN",
      sequence: 1,
      smsVerification: true,
      verificationPriority: "SMS"
    }
  ]);
  const [minLength, setMinLength] = useState(10);
  const [maxLength, setMaxLength] = useState(10);
  const [IslocationAccess, setIslocationAccess] = useState(false);
  const [countryCodeList, setCountryCodeList] = useState([]);
  const [selectedSequence, setSelectedSequence] = useState(null);
  const [showCountryCodeList, setShowCountryCodeList] = useState(false);
  const [buttonTitleValue, setbuttonTitleValue] = useState("Select your country");
  const [isEmailVerification, setisEmailVerification] = useState(false);
  const [isPhoneVerification, setisPhoneVerification] = useState(false);
  const [selectedCountryCodeData, setSelectedCountryCodeData] = useState(null);
  const [dropdownVal, setdropdownVal] = useState(0);
  const [updateddropdownCountryCode, setUpdateddropdownCountryCode] = useState(91);
  const [showCheckBox, setShowCheckBox] = useState(false);
  const [isEmail, setIsEmail] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [loginModeList, setLoginModeList] = useState(null);
  const [isMobileInputSelected, setIsMobileInputSelected] = useState(true);
  const [loginOptionsList, setLoginOptionsList] = useState(null);
  const history: any = useHistory();
  const formikRef = useRef(null);

  useEffect(() => {
    setLoginModeList(OPTIONS_MODE[0]);
    async function fetchData() {
      let countryCode: any = await LocalStorage.getStorage("countryCode");
      let countryCodeObj: any = await LocalStorage.getStorage("countryCodeObj");

      countryCode === null || countryCodeObj === null
        ? onPressForLocation()
        : serCountryCodeFromLocalStorage();
    }
    fetchData();
    // setLoginOptionsList([OPTIONS_MODE[1]]);
  }, []);

  useEffect(() => {
    if (validate[0]?.phoneNumberSizeMax != validate[0]?.phoneNumberSizeMin) {
      setMinLength(validate[0]?.phoneNumberSizeMin);
      setMaxLength(validate[0]?.phoneNumberSizeMax);
    } else {
      setMinLength(10);
      setMaxLength(10);
    }
  }, [validate]);

  useEffect(() => {
    getCountryodeList();
  }, []);

  const serCountryCodeFromLocalStorage = async () => {
    let countryCodeObject: any = await LocalStorage.getStorage("countryCodeObj");
    let countryCodeList: any = await LocalStorage.getStorage("countryCodeList");
    const countryData = countryCodeList.filter(
      (element: any) => element.sequence === countryCodeObject.sequence
    );
    actionOnRow(countryData[0]);
  };

  const setMobileEmailOnPriority = async (data: any) => {
    if (data.verificationPriority === "SMS") {
      setLoginModeList(OPTIONS_MODE[0]);
      setLoginOptionsList([OPTIONS_MODE[1]]);
      setIsMobileInputSelected(true);
    } else {
      setLoginModeList(OPTIONS_MODE[1]);
      setLoginOptionsList([OPTIONS_MODE[0]]);
      setIsMobileInputSelected(false);
    }
  };

  const onPressForLocation = (toggle = false) => {
    navigator.permissions.query({ name: "geolocation" }).then(function (result) {
      if (navigator.permissions.query({ name: "geolocation" })) {
        if (result.state == "granted") {
          Geolocation.getCurrentPosition(
            (position: any) => {
              const params = {
                lat: position.coords.latitude,
                long: position.coords.longitude,
                lang: "en"
              };
              setIslocationAccess(true);
              setDefaultLocation(params);
            },
            (error: any) => {
              console.log(error);
            },
            {
              enableHighAccuracy: true,
              timeout: 15000,
              maximumAge: 10000,
              showLocationDialog: false
            }
          );
        } else if (result.state == "prompt") {
          navigator.geolocation.getCurrentPosition(revealPosition, positionDenied);
        } else if (result.state == "denied") {
          setIslocationAccess(false);
        } else {
          setIslocationAccess(false);
        }
      }
    });
  };

  const revealPosition = () => {
    onPressForLocation();
  };

  const positionDenied = () => {
    setIslocationAccess(false);
  };

  const setDefaultLocation = async (params: any) => {
    try {
      const response: any = await axios.get(
        `https://maps.googleapis.com/maps/api/geocode/json?key=AIzaSyDVf-To9hehz4ORteAxgsP-yzHxsahVcDo&latlng=${params.lat},${params.long}&language=${params.lang}`
      );
      const result = response.data.results[0];
      let countryCodeListResoponse = await ApiClient.get(loginEndPoints.getCountryCode());
      setCountryCodeList(countryCodeListResoponse.response);
      result.address_components.map((item: any) => {
        if (item.types[0] === "country") {
          const shortName = item.short_name;

          const IfCountryExist = countryCodeListResoponse.response.filter(
            (element: any) => element.isoShortCode === shortName
          );
          actionOnRow(IfCountryExist[0]);
          console.log("setDefaultLocation");
        }
      });
    } catch (error) {}
  };

  const getCountryodeList = async () => {
    let countryCodeListResoponse = await ApiClient.get(loginEndPoints.getCountryCode());
    setCountryCodeList(countryCodeListResoponse.response);
    LocalStorage.setStorage("countryCodeList", countryCodeListResoponse.response);
    return countryCodeListResoponse;
  };

  const getCountry = (data: any, IslocationAccess: boolean) => {
    setIslocationAccess(IslocationAccess);
  };

  const resentFormikFormHandler = () => {
    formikRef?.current?.resetForm({
      values: {
        userName: "",
        email: "",
        countryCode: +91,
        password: ""
      }
    }); //reset formik values
  };

  const actionOnRow = (item: any) => {
    formikRef?.current?.resetForm({
      values: {
        userName: "",
        email: "",
        countryCode: +91,
        password: ""
      }
    }); //reset formik values
    let validationCountryCodeObject: any = [];
    validationCountryCodeObject.push(item);
    setShowCountryCodeList(false);
    setbuttonTitleValue(`${item.country}(${item.code})`);
    if (item.smsVerification && item.emailVerification) {
      setShowCheckBox(true);
      setisEmailVerification(false);
      // setisPhoneVerification(true);
      props.setEmailVerification(false);
    } else if (item.smsVerification) {
      // setisPhoneVerification(true);
      setShowCheckBox(false);
      setisEmailVerification(false);
      props.setEmailVerification(false);
    } else if (item.emailVerification) {
      setisEmailVerification(true);
      setShowCheckBox(false);
      // To show Login with OTP even though if it is none email verification country
      // setisPhoneVerification(true);
      props.setEmailVerification(true);
    }
    setValidate(validationCountryCodeObject);
    setIslocationAccess(true);
    props.setSelectedCountryObject(item);
    setSelectedCountryCodeData(item); // to be passed to loginOtpScreen
    setMobileEmailOnPriority(item);
    setdropdownVal(item.code.replace(/[\D]/g, ""));
    setUpdateddropdownCountryCode(item.code.replace(/[\D]/g, ""));
  };

  const validateEmail = (email: string) => {
    let re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  const handleValidate = (values: any) => {
    let errors: any = {};
    const regex = /^(0*[1-9][0-9]*(\.[0-9]*)?|0*\.[0-9]*[1-9][0-9]*)$/;
    if (!values?.userName?.trim()) {
      errors.userName = isMobileInputSelected
        ? "Please enter mobile number "
        : "Please enter email ID";
    } else {
      if (isMobileInputSelected) {
        if (!regex.test(values?.userName) && values?.userName) {
          errors.userName = "Invalid characters";
        } else if (
          validate[0].phoneNumberSizeMin === validate[0].phoneNumberSizeMax &&
          values?.userName.length !== validate[0].phoneNumberSizeMin
        ) {
          errors.userName = `The phone number should be of ${validate[0].phoneNumberSizeMax} digits`;
        } else if (
          values?.userName.length < validate[0].phoneNumberSizeMin ||
          values?.userName.length > validate[0].phoneNumberSizeMax
        ) {
          errors.userName = `The phone number should be between ${validate[0].phoneNumberSizeMin} to ${validate[0].phoneNumberSizeMax} digits.`;
        }
      } else {
        if (!validateEmail(values?.userName.trim()) && values?.userName.trim()) {
          errors.userName = "Email entered is not valid";
        }
      }
    }
    if (!isPhoneVerification && !values.password) {
      errors.password = "Please enter your password";
    }
    return errors;
  };

  const validateEmailHandler = (values: any) => {
    let errors: any = {};
    if (!values.email) {
      errors.email = "Email Id is required";
    }
    if (!values.password) {
      errors.password = "Password is required";
    }
    if (
      !validateEmail(values?.email?.trim().replace(" ", "")) &&
      values?.email?.trim().replace(" ", "")
    ) {
      errors.email = "Email entered is not valid";
    }
    return errors;
  };

  const saveLogin = (
    values: {
      countryCode: string;
      password: string;
      email: string;
      userName: string;
    },
    isLoginWithOtp: boolean
  ) => {
    if (isLoginWithOtp) {
      setIsLoading(true);
      loginWithOtpHandler(values);
    } else {
      onLoginSubmit({
        countryCode: selectedCountryCodeData.code,
        password: values.password,
        userName: values.userName.trim().replace(" ", "")
      });
    }
  };

  const loginWithOtpHandler = (values: {
    countryCode: string;
    password: string;
    email: string;
    userName: string;
  }) => {
    let newCountryCode =
      typeof values.countryCode === "number" ? `+${values.countryCode}` : values.countryCode;
    let payload = {
      userName: values.userName.trim().replace(" ", ""),
      countryCode: newCountryCode,
      verificationType: isMobileInputSelected ? "PHONE_NUMBER" : "EMAIL_ID"
    };
    let message = !isMobileInputSelected ? "email" : "phone number";
    let response = LoginRepository.loginUser(payload);
    response
      .then((res) => {
        if (res.responseCode === HTTP_STATUS_CODES.ok) {
          setIsLoading(false);
          history.push("/new/login/otp", {
            userName: values.userName,
            countryCode: newCountryCode,
            verificationType: !isMobileInputSelected ? "EMAIL_ID" : "PHONE_NUMBER",
            selectedCountryCodeData: selectedCountryCodeData,
            IsEmailVerification: !isMobileInputSelected
          });
        } else if (
          res?.responseCode === HTTP_STATUS_CODES.badRequest ||
          res?.responseCode === HTTP_STATUS_CODES.notFound ||
          res?.responseCode === HTTP_STATUS_CODES.unauthorized
        ) {
          toast.error(
            `This is not a registered ${message}. You should sign up first and then try again.`
          );
          setIsLoading(false);
        } else {
          setIsLoading(false);
          toast.error(res.messageDetails);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        toast.error("Something went wrong.");
      });
  };

  const handleDropdown = (item: any, e: any) => {
    if (item.value === "Mobile number") {
      setIsMobileInputSelected(true);
      setLoginOptionsList([OPTIONS_MODE[1]]);
    } else {
      setIsMobileInputSelected(false);
      setLoginOptionsList([OPTIONS_MODE[0]]);
    }
    setLoginModeList([item]);
    resentFormikFormHandler();
  };

  const { Option } = components;
  const IconOption = (props: any) => (
    <Option {...props}>
      <View style={{ flexDirection: "row", alignItems: "center" }}>
        <Icon testID="home" name={props.data.icon} customStyle={{}} />
        <View style={{ marginLeft: 5 }}>{props.data.label}</View>
      </View>
    </Option>
  );

  const SingleValue = ({ children, ...props }: SingleValueProps<any>) => (
    <components.SingleValue {...props}>
      <View style={{ flexDirection: "row", alignItems: "center" }}>
        <Icon testID="home" name={props.data.icon} customStyle={{}} />
        <View style={{ marginLeft: 10 }}> {children}</View>
      </View>
    </components.SingleValue>
  );

  const renderHeaderComponent = () => (
    <View>
      <View style={styles.mainHeading}>
        <Text
          fontWeight={FontWeight.Regular}
          testId="program"
          textSize={TextSize.Regular}
          textStyle={styles.subHeading}
        >
          {t("common:pdaProgram")}
        </Text>
        <View style={{ marginTop: 10 }}>
          <Text
            fontWeight={FontWeight.Bold}
            testId="login"
            textSize={TextSize.Large}
            textStyle={{ fontSize: 26 }}
          >
            {t("common:loginText")}
          </Text>
        </View>
      </View>

      <View style={styles.buttonContainer}>
        <Text
          fontWeight={FontWeight.Regular}
          testId="internal"
          textSize={TextSize.Small}
          style={[styles.selectCountryCodeText]}
        >
          {t("common:selectYouCountryCode")}
        </Text>
        <Button
          type={ButtonType.Secondary}
          buttonStyles={
            !showCountryCodeList
              ? styles.button
              : [
                  styles.button,
                  { borderRadius: 0, borderTopRightRadius: 10, borderTopLeftRadius: 10 }
                ]
          }
          title={buttonTitleValue}
          onPress={() => {
            setShowCountryCodeList(!showCountryCodeList);
          }}
        />
        {showCountryCodeList && (
          <View style={styles.flatListContainer}>
            <FlatList
              data={countryCodeList}
              renderItem={({ item }) => (
                <TouchableOpacity onPress={() => actionOnRow(item)}>
                  <View style={styles.flatview}>
                    <Text
                      fontWeight={FontWeight.Regular}
                      testId="forgot"
                      textSize={TextSize.Small}
                      textStyle={styles.flatListText}
                    >
                      {item.country}({item.code})
                    </Text>
                  </View>
                </TouchableOpacity>
              )}
              showsVerticalScrollIndicator={false}
              keyExtractor={(item) => item.sequence}
              extraData={selectedSequence}
              contentContainerStyle={{}}
            />
          </View>
        )}
      </View>
    </View>
  );

  const renderDropdownHandler = () => (
    <>
      <Select
        isClearable={false}
        value={loginModeList}
        defaultValue={loginModeList}
        onChange={(item, e) => handleDropdown(item, e)}
        placeholder={"Select Mode"}
        options={loginOptionsList}
        isSearchable={false}
        menuPortalTarget={document.body}
        styles={customStyles}
        components={{
          IndicatorSeparator: () => null,
          Option: IconOption,
          SingleValue
        }}
      />
    </>
  );

  return (
    <View>
      {IslocationAccess ? (
        <WrapperForm
          footerText={t("common:dontHaveAnAccount")}
          footerTextType={t("common:signUpText")}
          onPressFooterTextType={() => history.push("/new/iam/signup")}
          component={
            <View style={styles.container}>
              <View style={{ marginTop: 30, height: 540 }}>
                <View>{renderHeaderComponent()}</View>
                {isPhoneVerification ? (
                  <View style={styles.subFormContainer}>
                    <Formik
                      initialValues={{
                        countryCode: +91,
                        password: null,
                        email: null,
                        userName: ""
                      }}
                      onSubmit={(values, { setSubmitting, resetForm }) => {
                        saveLogin(values, true);
                        setSubmitting(false);
                        resetForm({
                          values: { countryCode: +91, password: "", email: null, userName: "" }
                        });
                      }}
                      validate={handleValidate}
                      enableReinitialize={false}
                      innerRef={formikRef}
                    >
                      {(
                        formikProps: FormikProps<{
                          countryCode: string;
                          password: string;
                          email: string;
                          userName: string;
                        }>
                      ) => (
                        <Form>
                          <View style={{}}>
                            <View style={{ marginBottom: 103 }}>
                              <View
                                style={[
                                  styles.dropdownContainer,
                                  { marginBottom: 10, marginLeft: -10 }
                                ]}
                              >
                                {renderDropdownHandler()}
                              </View>
                              <View style={{ height: 55 }}>
                                <EmailMobileInput
                                  label=""
                                  handleChange={formikProps.handleChange("userName")}
                                  onChangeValue={(value) => [
                                    (formikProps.values.countryCode = validate[0].code),
                                    formikProps.handleChange("countryCode")
                                  ]}
                                  customChange={() =>
                                    formikProps.setFieldTouched("userName", false)
                                  }
                                  handleBlur={() => formikProps.setFieldTouched}
                                  inputStyle={styles.user}
                                  name="userName"
                                  placeholder={`Enter ${
                                    isMobileInputSelected ? "number" : "email"
                                  } `}
                                  countryCode={validate[0].code}
                                  dropdownValue={formikProps.values.countryCode}
                                  value={formikProps?.values?.userName?.replace(/\s/g, "")}
                                  id="userName"
                                  handleKeyPress={(e: any) => {
                                    if (e.key === "Enter") {
                                      formikProps.handleSubmit();
                                    }
                                  }}
                                  formikPropsTouched={
                                    formikProps.touched.userName === undefined
                                      ? false
                                      : formikProps.touched.userName
                                  }
                                  // isCountryCodeClickable={false}
                                  emailField={!isMobileInputSelected} //isEmail
                                />
                              </View>
                              {/* <View style={{}}>
                                <Text
                                  fontWeight={FontWeight.Regular}
                                  testId="forgot"
                                  textSize={TextSize.Small}
                                  textStyle={styles.forgot}
                                >
                                  {"Forgot password? "}
                                  <TouchableOpacity>
                                    <Link to="/new/forgot-password">
                                      <Text
                                        fontWeight={FontWeight.Regular}
                                        testId="forgot"
                                        textSize={TextSize.Small}
                                        textStyle={[styles.forgot, styles.clickHereText]}
                                      >
                                        {"click here"}
                                      </Text>
                                    </Link>
                                  </TouchableOpacity>
                                </Text>
                              </View> */}
                            </View>
                            <View>
                              <Button
                                type={ButtonType.Primary}
                                buttonStyles={styles.loginButton}
                                title="Get OTP"
                                onPress={() => {
                                  formikProps.handleSubmit();
                                }}
                                disabled={isLoading}
                              />
                            </View>
                          </View>
                        </Form>
                      )}
                    </Formik>
                    <TouchableOpacity
                      style={{ marginTop: 12 }}
                      onPress={() => {
                        setisPhoneVerification(false);
                        resentFormikFormHandler();
                      }}
                    >
                      <Text
                        fontWeight={FontWeight.Regular}
                        testId="forgot"
                        textSize={TextSize.Small}
                        textStyle={[
                          styles.forgot,
                          { textDecorationLine: "underline", alignSelf: "center" }
                        ]}
                      >
                        {"Login with Password"}
                      </Text>
                    </TouchableOpacity>
                  </View>
                ) : (
                  <View style={{ marginTop: 145, zIndex: -1 }}>
                    <Formik
                      initialValues={{
                        password: null,
                        email: null,
                        countryCode: +91,
                        userName: null
                      }}
                      onSubmit={(values, { setSubmitting, resetForm }) => {
                        saveLogin(values, false);
                        setSubmitting(false);
                        resetForm({
                          values: { countryCode: +91, password: "", email: null, userName: "" }
                        });
                      }}
                      // validate={validateEmailHandler}
                      validate={handleValidate}
                      enableReinitialize={false}
                      innerRef={formikRef}
                    >
                      {(
                        formikProps: FormikProps<{
                          password: string;
                          email: string;
                          countryCode: string;
                          userName: string;
                        }>
                      ) => (
                        <Form>
                          <View>
                            <View>
                              <View>
                                <View
                                  style={[
                                    styles.dropdownContainer,
                                    { marginBottom: 10, marginLeft: -10 }
                                  ]}
                                >
                                  {renderDropdownHandler()}
                                </View>
                                <View style={{ height: 55 }}>
                                  <EmailMobileInput
                                    label=""
                                    handleChange={formikProps.handleChange("userName")}
                                    onChangeValue={(value) => [
                                      (formikProps.values.countryCode = validate[0].code),
                                      formikProps.handleChange("countryCode")
                                    ]}
                                    customChange={() =>
                                      formikProps.setFieldTouched("userName", false)
                                    }
                                    handleBlur={() => formikProps.setFieldTouched}
                                    inputStyle={styles.user}
                                    name="userName"
                                    placeholder={`Enter ${
                                      isMobileInputSelected ? "number" : "email"
                                    } `}
                                    countryCode={validate[0].code}
                                    dropdownValue={formikProps.values.countryCode}
                                    value={formikProps?.values?.userName?.replace(/\s/g, "")}
                                    id="userName"
                                    handleKeyPress={(e: any) => {
                                      if (e.key === "Enter") {
                                        formikProps.handleSubmit();
                                      }
                                    }}
                                    formikPropsTouched={
                                      formikProps.touched.userName === undefined
                                        ? false
                                        : formikProps.touched.userName
                                    }
                                    // isCountryCodeClickable={false}
                                    emailField={!isMobileInputSelected}
                                  />
                                </View>
                                {/* <View style={{ height: 100 }}>
                                  <UserInput
                                    label=""
                                    handleChange={formikProps.handleChange("email")}
                                    handleBlur={() => formikProps.setFieldTouched}
                                    userStyle={styles.user}
                                    textIcon={styles.userInputStyleHeader}
                                    icon={IconNames.email}
                                    textValue="Email"
                                    name="email"
                                    placeholder="Enter Email"
                                    value={formikProps?.values?.email?.replace(/\s/g, "")}
                                    id="Email"
                                    handleKeyPress={(e: any) => {
                                      if (e.key === "Enter") {
                                        formikProps.handleSubmit();
                                      }
                                    }}
                                    formikPropsTouched={
                                      formikProps.touched.email === undefined
                                        ? false
                                        : formikProps.touched.email
                                    }
                                    customChange={() => formikProps.setFieldTouched("email", false)}
                                  />
                                </View> */}
                                <View style={{ height: 90, marginBottom: 5 }}>
                                  <UserInput
                                    isSecure
                                    label=""
                                    handleChange={formikProps.handleChange("password")}
                                    handleBlur={() => formikProps.setFieldTouched}
                                    userStyle={styles.user}
                                    textIcon={styles.userInputStyleHeader}
                                    icon={IconNames.password}
                                    textValue="Password"
                                    name="password"
                                    placeholder="Enter Password"
                                    value={formikProps.values.password}
                                    id="password"
                                    handleKeyPress={(e: any) => {
                                      if (e.key === "Enter") {
                                        formikProps.handleSubmit();
                                      }
                                    }}
                                    formikPropsTouched={
                                      formikProps.touched.password === undefined
                                        ? false
                                        : formikProps.touched.password
                                    }
                                    customChange={() =>
                                      formikProps.setFieldTouched("password", false)
                                    }
                                  />
                                </View>
                              </View>
                              <View style={{}}>
                                <Text
                                  fontWeight={FontWeight.Regular}
                                  testId="forgot"
                                  textSize={TextSize.Small}
                                  textStyle={styles.forgot}
                                >
                                  {"Forgot password? "}
                                  <TouchableOpacity>
                                    <Link to="/new/forgot-password">
                                      <Text
                                        fontWeight={FontWeight.Regular}
                                        testId="forgot"
                                        textSize={TextSize.Small}
                                        textStyle={[styles.forgot, styles.clickHereText]}
                                      >
                                        {"click here"}
                                      </Text>
                                    </Link>
                                  </TouchableOpacity>
                                </Text>
                              </View>
                            </View>
                            <Button
                              type={ButtonType.Primary}
                              buttonStyles={[styles.loginButton, { marginTop: 15 }]}
                              title="Login"
                              onPress={() => {
                                formikProps.handleSubmit();
                              }}
                            />
                          </View>
                        </Form>
                      )}
                    </Formik>
                    <TouchableOpacity
                      style={{ marginTop: 15 }}
                      onPress={() => {
                        setisPhoneVerification(true);
                        resentFormikFormHandler();
                      }}
                    >
                      <Text
                        fontWeight={FontWeight.Regular}
                        testId="loginWithOtp"
                        textSize={TextSize.Small}
                        textStyle={[
                          styles.forgot,
                          { textDecorationLine: "underline", alignSelf: "center" }
                        ]}
                      >
                        {"Login with OTP (One Time Password)"}
                      </Text>
                    </TouchableOpacity>
                  </View>
                )}
              </View>
            </View>
          }
        />
      ) : (
        <WrapperForm
          footerText={t("common:dontHaveAnAccount")}
          footerTextType={t("common:signUpText")}
          onPressFooterTextType={() => history.push("/new/iam/signup")}
          component={
            <View style={styles.container}>
              <View style={{ marginTop: 30, height: 540 }}>
                <View>{renderHeaderComponent()}</View>
              </View>
            </View>
          }
        />
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    alignItems: "center",
    backgroundColor: colorPallete.white,
    width: "100%",
    minHeight: 603,
    borderRadius: 20,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0
  },
  subFormContainer: {
    zIndex: -1,
    marginTop: 155
  },
  heading: {
    marginTop: 5,
    // marginLeft: 80,
    fontSize: 20
  },
  subLoginHeading: {
    marginTop: 40,
    // marginLeft: 80,
    fontSize: 20
  },
  logo: {
    height: 16.39,
    width: 80
  },
  cordio: {
    color: colorPallete.white,
    fontSize: 75
  },
  loginButton: {
    marginTop: 20,
    height: 40,
    width: 304,
    zIndex: -1,
    borderRadius: 10
  },
  actionContainer: {
    marginTop: 40
    // marginLeft: 80
  },
  actionContainerSecondary: {
    marginTop: 15
    // marginLeft: 80
  },
  mobile: {
    marginBottom: 38
  },
  user: {
    marginTop: 8
  },
  userInputStyleHeader: {
    fontFamily: FontFamily.Bold,
    color: colorPallete.textBlack,
    marginLeft: 5
  },
  forgot: {
    fontWeight: "400",
    fontSize: 12,
    position: "relative",
    zIndex: -1,
    display: "flex"
    // justifyContent: "center"
  },
  footer: {
    marginVertical: 5
    // marginLeft: 155
  },
  clickHereText: {
    color: colorPallete.cordioRed,
    textDecorationLine: "underline"
  },
  dropDownContainerStyle: {
    marginTop: 10,
    width: 304,
    zIndex: 10
  },
  subHeading: {
    fontSize: 19
  },
  mainHeading: {
    // marginTop: 50,
    alignItems: "center"
    // marginLeft: 80
  },
  portal: {
    marginTop: 5
  },
  textIcon: {
    color: colorPallete.textLight,
    marginBottom: 10,
    marginTop: 5
  },
  checkboxText: {
    paddingLeft: 10,
    fontSize: 14,
    marginVertical: 8
  },

  // Dropdown button
  buttonContainer: {
    position: "absolute",
    top: 120
  },
  button: {
    marginTop: 0,
    height: 40,
    width: 304,
    zIndex: -1,
    borderRadius: 10
  },
  selectCountryCodeText: {
    color: colorPallete.textLight,
    marginBottom: 10,
    marginTop: 5,
    alignSelf: "center",
    fontFamily: FontFamily.Regular
  },
  flatview: {
    paddingTop: 0,
    borderRadius: 2,
    paddingLeft: 10
  },
  flatListContainer: {
    borderWidth: 1,
    height: 300,
    width: 304,
    borderTopWidth: 0,
    borderRadius: 0,
    top: -3,
    borderTopLeftRadius: 0,
    borderBottomRightRadius: 0,
    borderColor: colorPallete.cordioRed,
    backgroundColor: colorPallete.white
  },
  flatListText: {
    fontWeight: "400",
    fontSize: 12,
    position: "relative",
    zIndex: -1
  },
  dropdownContainer: {
    position: "relative",
    zIndex: 2,
    width: 200
  }
});

const customStyles = {
  control: (styles: any, state: any) => ({
    ...styles,
    backgroundColor: "white",
    border: "none",
    boxShadow: "none",
    fontFamily: FontFamily.Bold,
    color: colorPallete.textBlack,
    fontSize: 14,
    cursor: "pointer",
    // marginLeft: -10,  // to adjust the dropdown
    ":hover": {
      border: "none"
    }
  }),
  menuList: (base: any) => ({
    ...base,
    fontFamily: FontFamily.Bold,
    backgroundColor: colorPallete.lightGrey,
    marginTop: -10
  }),
  option: (styles: any, { data, isDisabled, isFocused, isSelected }: any) => ({
    ...styles,
    padding: 8,
    paddingLeft: 10,
    paddingRight: 10,
    backgroundColor: isSelected ? colorPallete.lightGreyThree : colorPallete.lightGrey,
    color: colorPallete.textBlack,
    cursor: "pointer",
    ":hover": {
      backgroundColor: colorPallete.lightGreyTwo
    }
  }),
  singleValue: (styles: any, { data }: any) => ({
    ...styles,
    fontSize: 14
  })
};

import React, { ReactElement } from "react";
import { allRoutesNames as routeNames } from "navigation/allRouteNames";
import MapRoutes from "navigation/mapRoutes";
import RegistryWorkspace from "@socion-cordio/common/src/components/organisms/registryWorkspace";

interface IProps {
  children: Array<{}>;
}

function Index(props: IProps): ReactElement {
  return (
    <div>
      <RegistryWorkspace/>
      <MapRoutes
        routes={[...props.children]}
        basePath={`${routeNames.app}${routeNames.workspace}`}
      />
    </div>
  );
}

export default Index;

import Icon, { IconNames } from "@socion-cordio/common/src/components/atoms/icon";
import {
  Text,
  TextSize,
  FontWeight,
  FontFamily
} from "@socion-cordio/common/src/components/atoms/text";
import { colorPallete } from "@socion-cordio/common/src/assets/styles/colors";
import React, { ReactElement, useEffect, useState } from "react";
import { View, StyleSheet, TouchableOpacity, ScrollView } from "react-native";
import { ApiClient } from "@socion-cordio/common/src/network/apiClient";
import { Image } from "@socion-cordio/common/src/components/atoms/image";
import { mediaHelper } from "@socion-cordio/common/src/utils/mediaHelperMethods";
import { toast } from "react-toastify";
import { HTTP_STATUS_CODES } from "@socion-cordio/common/src/network/constants";
import { springshedEndPoints } from "@socion-cordio/common/src/repositories/endPoints";
import { LocalStorage } from "@socion-cordio/common/src/services/storage/storageService";
import LoaderCircle from "@socion-cordio/common/src/components/atoms/loaderCircle";
import Loader from "@socion-cordio/common/src/components/atoms/loader";
import RemoveLinkModal from "@socion-cordio/common/src/components/organisms/common-modals/removeLinkModal";
import { v4 as uuidv4 } from "uuid";
import Moment from "moment";
import { auxiliaryMethods } from "@socion-cordio/common/src/utils/auxiliaryMethods";
import SocionModal from "@socion-cordio/common/src/components/atoms/modal";
import { enableScroll, disableScroll } from "@socion-cordio/common/src/utils/scrollToTop";
import { googleWebAnalytics } from "@socion-cordio/web/src/utils/firebaseAnalytics";
import { EntityActions } from "@socion-cordio/common/src/modules/registry/entity/actions/actions";
import { REGISTRY_TYPE } from "@socion-cordio/common/src/constants/registry";
import { MESSAGES } from "@socion-cordio/common/src/constants/message";
import { useDispatch, useSelector } from "react-redux";
import { split } from "lodash";
import { UploadFileHelper } from "@socion-cordio/common/src/utils/registryHelpers/uploadFileHelper";
import { DocsRepository } from "@socion-cordio/common/src/repositories/registry/docs/docs";
import { dispatchEntityList } from "@socion-cordio/common/src/utils/registryHelpers/entityHelper";

interface IProps {
  ids?: string[];
  selectedItem?: any;
  itemType?: string;
  uploadText?: string;
}

export default function Documents(props: IProps): ReactElement {
  const deleteButtonText = `Delete Document`;
  const [entityDocsList, setEntityDocsList] = useState([]);
  const [uploadDocsArray, setUploadDocsArray] = useState([]);
  const [uploading, setUploading] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [deleteType, setDeleteType] = useState({
    doc: false,
    upload: false,
    type: ""
  });
  const [selectedDoc, setSelectedDoc] = useState({
    doc: null,
    upload: null
  });

  const hiddenFileInput = React.useRef(null);
  const isEntityEnabled = JSON.parse(localStorage.getItem("isEntityDeleted"));
  const dispatch = useDispatch();
  const [docIds, setDocIds] = useState(null);
  const { ids, selectedItem, itemType } = props;

  const {
    docs: docs,
    loading: { getDocs: loading },
    error: { getDocs: error }
  } = useSelector((state: any) => state.entities);

  const handleClick = (event: any) => {
    hiddenFileInput.current.click();
  };

  useEffect(() => {
    setDocIds(ids);
  }, []);

  useEffect(() => {
    if (docIds) {
      dispatchDocsEvent();
    } else {
      setUploadDocsArray([]);
      setEntityDocsList([]);
      setIsLoading(false);
      setUploading(false);
    }
  }, [docIds]);

  useEffect(() => {
    setIsLoading(loading);
  }, [loading]);

  useEffect(() => {
    if (error) {
      setIsLoading(false);
      setUploadDocsArray([]);
      setUploading(false);
    }
  }, [error]);

  useEffect(() => {
    if (docs !== null) {
      docs.forEach((doc: any) => {
        const name = split(doc?.name, "__");
        doc.name = name[1];
      });
      setUploadDocsArray([]);
      setUploading(false);
      setEntityDocsList(docs);
      setIsLoading(false);
    }
  }, [docs]);

  const dispatchDocsEvent = () => {
    const request = {
      id: docIds || [],
      type: itemType
    };
    console.log("EntityActions.getEntityDocs(request)", request);
    dispatch(EntityActions.getEntityDocs(request));
  };

  useEffect(() => {
    if (deleteType.doc || deleteType?.upload) disableScroll();
    else enableScroll();
  }, [deleteType]);

  const getUploadRequest = () => {
    if (itemType === REGISTRY_TYPE.entity_program) {
      return {
        path: `${selectedItem?.parentType.toLowerCase()}/${selectedItem?.parentId}/${itemType}/${
          selectedItem?.programId
        }`,
        id: selectedItem?.programId,
        type: itemType,
        parentId: selectedItem?.parentId
      };
    } else {
      const docId = itemType === REGISTRY_TYPE.entity ? selectedItem?.entityId : "";
      return {
        path: `${itemType}/${docId}`,
        id: docId,
        type: itemType,
        parentId: ""
      };
    }
  };

  const handleCustomChange = async (e: any) => {
    setUploading(true);
    let fileNamesArray: any = [];
    uploadDocsArray.map((i) => {
      fileNamesArray.push(i.name);
    });
    if (fileNamesArray.includes(e.target.files[0].name)) {
      toast.error("Document already added");
      setUploading(false);
      return;
    }
    const validTypes = ["mp4", "mpeg", "mov", "flv", "png", "jpg", "jpeg", "pdf"];
    const splitFileName = e?.currentTarget?.files[0]?.name.split(".");
    const extension = splitFileName[splitFileName.length - 1].toLowerCase();
    if (e.target.validity.valid && validTypes.includes(extension)) {
      setIsLoading(true);
      let docs = [...uploadDocsArray];
      // docs.push(e?.currentTarget?.files[0]);
      const file = {
        id: uuidv4(),
        name: e?.currentTarget?.files[0]?.name,
        fileType: e?.currentTarget?.files[0]?.type,
        file: e?.currentTarget?.files[0]
      };
      docs.push(file);
      setUploadDocsArray(docs);
      setIsLoading(false);
      const uploadReq = getUploadRequest();
      const uploadRes = await UploadFileHelper.upload(
        uploadReq.path,
        [e?.currentTarget?.files[0]],
        uploadReq.id,
        uploadReq.type,
        uploadReq.parentId
      ).catch((err) => {
        console.log("Error while Uploading Docs", err);
        setIsLoading(false);
        setUploading(false);
      });

      if (uploadRes) {
        const ids = uploadRes?.docIds;
        const exIds = docIds || [];
        if (ids && ids?.length > 0) {
          const idlist = [...exIds, ...ids];
          setDocIds(idlist);
        }
        setIsLoading(false);
        setUploading(false);
      }
    } else {
      setIsLoading(false);
      setUploading(false);
      toast.error(
        "Invalid File Type! Only Files with format of mp4, mpeg, mov, flv, png, jpg, jpeg, pdf will be uploaded!!"
      );
    }
  };

  const getUploadFileUrl = (file: any) => {
    const preview = URL.createObjectURL(file);
    return preview;
  };

  const deleteDocHandler = async (value: boolean) => {
    const type = deleteType?.doc ? "doc" : "upload";
    try {
      if (value === true) {
        switch (type) {
          case "upload":
            const uploadId = selectedDoc?.upload?.id;
            let docs = [...uploadDocsArray];
            docs = docs.filter((doc) => doc?.id !== uploadId);
            setUploadDocsArray(docs);
            setDeleteType({ ...deleteType, upload: false, type: "" });
            toast.success("Document deleted successfully");
            break;
          case "doc":
            const docId = selectedDoc?.doc?.id;
            const request = {
              id: [docId],
              type: itemType,
              taggedId:
                itemType === REGISTRY_TYPE.entity_program
                  ? selectedItem?.programId
                  : itemType === REGISTRY_TYPE.entity
                  ? selectedItem?.entityId
                  : ""
            };
            let response = await DocsRepository.deleteDoc(request).catch((err) => {
              console.log("Error while Deleting Docs", err);
            });
            if (response) {
              let ids = [...docIds];
              ids = ids.filter((id) => id !== docId);
              setDocIds(ids);

              setDeleteType({ ...deleteType, doc: false, type: "" });
              toast.success("Document deleted successfully");
              // dispatchEntity(dispatch);
            }

            break;
        }
      }
    } catch (error) {
      type === "upload"
        ? setDeleteType({ ...deleteType, upload: false, type: "" })
        : setDeleteType({ ...deleteType, doc: false, type: "" });
      toast.error("Something went wrong.");
    }
  };

  const handleOnClick = (event: any) => {
    const { target = {} } = event || {};
    target.value = "";
  };

  const setModalVisible = () => {
    deleteType.type === "upload"
      ? setDeleteType({ ...deleteType, upload: !deleteType.upload })
      : setDeleteType({ ...deleteType, doc: !deleteType.doc });
  };

  const openInNewTab = (url: string) => {
    var win = window.open(url, "_blank");
    win.focus();
  };

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <View style={[styles.documentsContainer, styles.labelValueContainer]}>
            <View style={styles.header}>
              <Text
                fontWeight={FontWeight.Regular}
                testId="internal"
                textSize={TextSize.Small}
                textStyle={[styles.alignTextStyles, styles.fontSize12]}
              >
                {"Documents"}
              </Text>

              <View style={styles.uploadContainer}>
                <Text
                  fontWeight={FontWeight.Bold}
                  testId="headerText"
                  textSize={TextSize.Small}
                  textStyle={[styles.headerText, styles.miniContainerHeaderText, { opacity: 0.6 }]}
                >
                  {props?.uploadText ? props?.uploadText : "Upload Documents"}
                </Text>
                {!uploading && (
                  <TouchableOpacity onPress={handleClick}>
                    <Image
                      testId="primaryAddImg"
                      source={require("@socion-cordio/common/src/assets/images/primary_add.svg")}
                      imageStyle={{
                        width: 30,
                        height: 30
                      }}
                    />
                    {/* <Icon
                      testID="uloadFile"
                      name={IconNames.uploadFile}
                      customStyle={[styles.alignContainerButton]}
                    /> */}
                    <input
                      style={{
                        display: "none",
                        zIndex: -1
                      }}
                      ref={hiddenFileInput}
                      type="file"
                      onChange={async (e) => {
                        await handleCustomChange(e);
                      }}
                      onClick={handleOnClick}
                    />
                  </TouchableOpacity>
                )}
              </View>

              {uploading && (
                <View style={styles.customLoader}>
                  <LoaderCircle />
                </View>
              )}
            </View>
            <View>
              <View style={styles.listContainer}>
                {uploadDocsArray?.length > 0 && (
                  <View>
                    {uploadDocsArray?.map((prop: any, key: number) => {
                      return (
                        <View key={key} style={styles.subContainer}>
                          <View style={styles.miniContainer}>
                            <a href={getUploadFileUrl(prop?.file)} target="_blank">
                              <Text
                                fontWeight={FontWeight.Bold}
                                testId="headerText"
                                textSize={TextSize.Small}
                                textStyle={[styles.headerText, styles.miniContainerHeaderText]}
                              >
                                {prop.name}
                              </Text>
                            </a>
                            <View style={styles.miniIconContainer}>
                              <TouchableOpacity
                                style={styles.alignButtons}
                                onPress={() => [
                                  setSelectedDoc({
                                    ...selectedDoc,
                                    upload: prop
                                  }),
                                  setDeleteType({ ...deleteType, upload: true, type: "upload" })
                                ]}
                              >
                                <Icon
                                  testID="delete"
                                  name={IconNames.deleteFile}
                                  customStyle={[styles.alignContainerButton]}
                                />
                              </TouchableOpacity>
                            </View>
                          </View>
                        </View>
                      );
                    })}
                  </View>
                )}
                {entityDocsList?.length > 0 && (
                  <View>
                    {entityDocsList?.map((prop: any, key: number) => {
                      console.log("fbdsnfbndbfs", prop);
                      return (
                        prop && (
                          <View key={key} style={styles.subContainer}>
                            <View style={styles.miniContainer}>
                              {/* <a href={prop?.url} target="_blank"> */}
                                <Text
                                  fontWeight={FontWeight.Bold}
                                  testId="headerText"
                                  textSize={TextSize.Small}
                                  onPress={() => openInNewTab(prop?.url)}
                                  textStyle={[styles.headerText, styles.miniContainerHeaderText]}
                                >
                                  {prop.name}
                                  
                                </Text>
                              {/* </a> */}
                              <View style={styles.miniIconContainer}>
                                {!isEntityEnabled && (
                                  <TouchableOpacity
                                    style={styles.alignButtons}
                                    onPress={() => {
                                      setSelectedDoc({
                                        ...selectedDoc,
                                        doc: prop
                                      }),
                                        setDeleteType({ ...deleteType, doc: true, type: "doc" });
                                    }}
                                  >
                                    <Icon
                                      testID="delete"
                                      name={IconNames.deleteFile}
                                      customStyle={[styles.alignContainerButton]}
                                    />
                                  </TouchableOpacity>
                                )}
                                <TouchableOpacity
                                  style={styles.alignButtons}
                                  onPress={() => {
                                    mediaHelper.mediaDownloadEntityHandler(
                                      prop?.mimeType,
                                      prop?.url,
                                      prop?.name
                                    );
                                  }}
                                >
                                  <Icon
                                    testID="download"
                                    name={IconNames.downloadList}
                                    customStyle={[styles.alignContainerButton]}
                                  />
                                </TouchableOpacity>
                              </View>
                            </View>
                          </View>
                        )
                      );
                    })}
                  </View>
                )}
                {entityDocsList?.length <= 0 && uploadDocsArray?.length <= 0 && (
                  <View>
                    <Text
                      fontWeight={FontWeight.Bold}
                      testId="headerText"
                      textSize={TextSize.Small}
                      textStyle={[
                        styles.headerText,
                        styles.miniContainerHeaderText,
                        { marginStart: 20 }
                      ]}
                    >
                      No Documents Available
                    </Text>
                  </View>
                )}
              </View>
            </View>
          </View>

          {(deleteType.doc || deleteType.upload) && (
            <View>
              <SocionModal
                modalVisible={deleteType.doc || deleteType.upload}
                setModalVisible={setModalVisible}
                component={
                  <RemoveLinkModal
                    modalVisible={deleteType.doc || deleteType.upload}
                    selectedData={(value: any) => deleteDocHandler(value)}
                    textValue={MESSAGES.INFO.DELETE}
                    deleteButtonText={deleteButtonText}
                    setModalVisible={setModalVisible}
                  />
                }
              />
            </View>
          )}
        </>
      )}
    </>
  );
}

const styles = StyleSheet.create({
  documentsContainer: {
    display: "flex",
    flexDirection: "column",
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: colorPallete.lightGreyThree,
    borderRadius: 10
  },
  labelValueContainer: {
    paddingVertical: 15,
    paddingHorizontal: 20
  },
  header: {
    display: "flex",
    flexDirection: "column",
    marginBottom: 20
    // justifyContent: "space-between"
  },
  alignTextStyles: {
    flex: 70,
    marginTop: 0,
    color: colorPallete.textLight
  },
  uploadContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    padding: 20,
    borderWidth: 1,
    borderColor: colorPallete.lightGrey,
    borderRadius: 10,
    backgroundColor: colorPallete.lightGrey,
    color: colorPallete.textBlack
  },
  headerText: {
    color: colorPallete.textLight,
    fontSize: 12,
    fontFamily: FontFamily.Regular,
    lineHeight: 17
  },
  fontSize12: {
    fontSize: 12
  },
  listContainer: {
    marginBottom: 10
  },
  entityListContainer: {
    height: 310
  },
  subContainer: {},
  miniContainer: {
    padding: 20,
    marginBottom: 10,
    width: "100%",
    borderColor: colorPallete.lightGreen,
    borderStyle: "solid",
    borderWidth: 1,
    borderRadius: 10,
    flexDirection: "row",
    justifyContent: "space-between"
  },
  miniContainerHeaderText: {
    color: colorPallete.textBlack,
    fontSize: 12,
    fontFamily: FontFamily.Medium,
    lineHeight: 20
  },
  miniIconContainer: {
    flexDirection: "row"
  },
  alignContainerButton: {
    fontSize: 16,
    color: colorPallete.cordioTaupe
  },
  alignButtons: {
    marginRight: 5,
    marginLeft: 25
  },
  customLoader: {
    position: "absolute",
    right: 20,
    top: 30,
    justifyContent: "center",
    alignItems: "center"
  },
  alignIconStyles: {
    marginLeft: 30
  },
  iconStyles: {
    color: colorPallete.cordioTaupe,
    fontSize: 15
  }
});

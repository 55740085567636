import React, { ReactElement, useState } from "react";
import { View, StyleSheet } from "react-native";
import { toast, ToastContainer } from "react-toastify";
import AesUtil from "@socion-cordio/common/src/utils/encryptionHelper";
import BarcodeScannerComponent from "react-qr-barcode-scanner";
import SocionModal from "@socion-cordio/common/src/components/atoms/modal";
import Icon, { IconNames } from "@socion-cordio/common/src/components/atoms/icon";
import {
  Text,
  TextSize,
  FontWeight,
  FontFamily
} from "@socion-cordio/common/src/components/atoms/text";
import Loader from "@socion-cordio/common/src/components/atoms/loader";
import { ApiClient } from "@socion-cordio/common/src/network/apiClient";
import { springshedEndPoints } from "@socion-cordio/common/src/repositories/endPoints";
import { HTTP_STATUS_CODES } from "@socion-cordio/common/src/network/constants";

interface IProps {
  onCloseScannerModal: Function;
  modalLoading: Boolean;
  getScannedUserHandler: Function;
}

export function QrCodeScannerModal(props: IProps): ReactElement {
  const { onCloseScannerModal, modalLoading, getScannedUserHandler } = props;
  let aesUtil: AesUtil = new AesUtil();

  const qrScannerHandler = async (userId: any, error: any) => {
    try {
      const decryptedId = aesUtil.decrypt(userId.text);
      const getUserDetailsResponse = await ApiClient.get(
        springshedEndPoints.getUserDataByQR(decryptedId)
      );
      if (getUserDetailsResponse.responseCode === HTTP_STATUS_CODES.notFound) {
        onCloseScannerModal();
        toast.error("Invalid QR code");
      } else if (getUserDetailsResponse) {
        getScannedUserHandler(getUserDetailsResponse.registryUserWithOsId);
        onCloseScannerModal();
      } else {
        onCloseScannerModal();
        toast.error(getUserDetailsResponse.message);
      }
    } catch (error) {
      onCloseScannerModal();
      toast.error("Invalid QR code");
    }
  };

  return (
    <View>
      <SocionModal
        modalVisible={true}
        setModalVisible={() => onCloseScannerModal()}
        component={
          <View style={styles.qrScanContainer}>
            <View style={styles.headerQrContainer}>
              <View>
                <Text
                  fontWeight={FontWeight.Bold}
                  testId="addText"
                  textSize={TextSize.Large}
                  textStyle={styles.headerText}
                >
                  Align window with QR code
                </Text>
              </View>
              <View>
                <Icon
                  testID="close"
                  name={IconNames.crossCircle}
                  customStyle={[styles.crossCircle, styles.alignContent]}
                  onPress={() => onCloseScannerModal()}
                />
              </View>
            </View>
            {modalLoading ? (
              <>
                <BarcodeScannerComponent
                  width={350}
                  height={300}
                  delay={1200}
                  onUpdate={(err, result: any) => {
                    if (result) {
                      qrScannerHandler(result, err);
                    }
                  }}
                />
                <Loader customLoadingContainer={styles.loader} />
              </>
            ) : null}
          </View>
        }
      />
    </View>
  );
}

const styles = StyleSheet.create({
  // QR Scanner
  headerText: {
    fontWeight: "700",
    fontSize: 12,
    fontFamily: FontFamily.Medium,
    lineHeight: 17
  },
  qrScanContainer: {
    width: 655,
    alignItems: "center"
  },
  headerQrContainer: {
    justifyContent: "space-between",
    flexDirection: "row",
    width: "100%"
  },
  crossCircle: {
    fontSize: 15
  },
  alignContent: {},
  loader: {
    marginTop: 10,
    height: 50,
    transform: [{ scale: 0.8 }]
  }
});

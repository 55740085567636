import { deserialize, serialize } from "@socion-cordio/common/src/mappers/apiResMapper";
import { Entity } from "@socion-cordio/common/src/models/registry/entity";
import { RegistryApiClient } from "@socion-cordio/common/src/network/registryApiClient";
import { ApiClient } from "@socion-cordio/common/src/network/apiClient";
import {
  registryEndPoints,
  springshedEndPoints
} from "@socion-cordio/common/src/repositories/endPoints";

class UploadRepository {

  uploadFile = async (reqBody: {}): Promise<any> => {
    let data = await RegistryApiClient.post(registryEndPoints.uploadFile(), reqBody);
    return data?.result || [];
  };
}
const uploadRepository = new UploadRepository();
export { uploadRepository as UploadRepository };

import React, { ReactElement, useState, useEffect } from "react";
import { StyleSheet, View, TouchableOpacity, useWindowDimensions } from "react-native";
import {
  Text,
  TextSize,
  FontWeight,
  FontFamily
} from "@socion-cordio/common/src/components/atoms/text";
import Icon, { IconNames } from "@socion-cordio/common/src/components/atoms/icon";
import UserInputSecondary from "@socion-cordio/common/src/components/molecules/userInputSecondary";
import UserInputTextArea from "@socion-cordio/common/src/components/molecules/userInputTextArea";
import { colorPallete } from "@socion-cordio/common/src/assets/styles/colors";
import { Switch } from "@socion-cordio/common/src/components/atoms/switches";
import { dataHelper } from "@socion-cordio/common/src/utils/dataHelper";
import { ApiClient } from "@socion-cordio/common/src/network/apiClient";
import {
  topicEndPoints,
  programEndPoints,
  applicationAccessEndPoints,
  springshedEndPoints
} from "@socion-cordio/common/src/repositories/endPoints";
import Loader from "@socion-cordio/common/src/components/atoms/loader";
import { useLocation, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toast, ToastContainer } from "react-toastify";
import { HTTP_STATUS_CODES } from "@socion-cordio/common/src/network/constants";
import AddTelemetryService from "@socion-cordio/common/src/services/telemetryService";
import Moment from "moment";
import { LocalStorage } from "@socion-cordio/common/src/services/storage/storageService";
import { GenerateQR } from "@socion-cordio/common/src/components/atoms/generateQR";
import { DownloadQR } from "@socion-cordio/common/src/components/atoms/downloadQR";
import TopicDetailsContent from "@socion-cordio/common/src/components/organisms/topic/topicDetailsContent";
import TopicDetailsTasks from "@socion-cordio/common/src/components/organisms/topic/topicDetailsTasks";
import TopicDetailsAssessments from "@socion-cordio/common/src/components/organisms/topic/topicDetailsAssessments";
import ButtonTab, {
  ButtonTypeSecondary
} from "@socion-cordio/common/src/components/atoms/buttonTab";
import Button, { ButtonType } from "@socion-cordio/common/src/components/atoms/button";
import RemoveLinkModal from "@socion-cordio/common/src/components/organisms/common-modals/removeLinkModal";
import { ScrollContainer } from "@socion-cordio/common/src/components/organisms/scrollWrapper";
import SocionModal from "@socion-cordio/common/src/components/atoms/modal";
import { enableScroll, disableScroll } from "@socion-cordio/common/src/utils/scrollToTop";
import { googleWebAnalytics } from "@socion-cordio/web/src/utils/firebaseAnalytics";
import AWS from "aws-sdk";
import { CONFIG } from "@socion-cordio/common/src/config/envConstant";

interface Props {
  onSubmit?: (data: any) => void;
  topicId?: any;
  userRoles?: any;
  isSuperAdmin?: any;
  selectedTopicDetails?: Object;
}

const commonTelemetryData = {
  timestamp: Moment().toLocaleString(),
  createdAt: Moment().toLocaleString(),
  deleted: "false",
  updatedAt: Moment().toLocaleString()
};

const DELETETOPICMESSAGE = "Are you sure you want to delete this topic?";

export default function TopicDetails(props: Props): ReactElement {
  const { topicId, userRoles, isSuperAdmin, selectedTopicDetails } = props;
  const { width } = useWindowDimensions();
  const [loading, setLoading] = useState<boolean>(true);
  const [failed, setFailed] = useState<boolean>(false);
  const [mainLoader, setMainLoader] = useState<boolean>(true);
  const [topicDetails, setTopicDetails] = useState(null);
  const [status, setStatus] = useState<boolean>(false);
  const [sessionLinked, setSessionLinked] = useState<boolean>(true);
  const [publishForTopicLibrary, setPublishForTopicLibrary] = useState<boolean>(false);
  const [topicName, setTopicName] = useState("");
  const [description, setDescription] = useState("");
  const [isEditable, setIsEditable] = useState<boolean>(false);
  const [topicDocument, setTopicDocument] = useState("");
  const [topicContent, setTopicContents] = useState<boolean>(true);
  const [topicTasks, setTopicTasks] = useState<boolean>(false);
  const [topicAssessments, setTopicAssessment] = useState(false);
  const location: any = useLocation();
  const [programData, setProgramData] = useState(undefined);
  const [isTopicDeleted, setIsTopicDeleted] = useState(false);
  const [topicLibraryEnabled, setTopicLibraryEnabled] = useState(false);
  const [subContentEnabled, setSubContentEnabled] = useState(false);
  const [isTopicEnabled, setIsTopicEnabled] = useState(false);
  const [state, setState] = useState({
    topicId: null,
    selectedTopicDetails: null,
    userRoles: [],
    isSuperAdmin: false
  });
  const [applicationId, setApplicationId] = useState(null);
  const [isTaskAccessible, setIsTaskAccessible] = useState(false);
  const [isAssessmentAccessible, setIsAssessmentAccessible] = useState(false);
  const [superAdmin, setSuperAdmin] = useState(null);
  const programDetails: any = JSON.parse(localStorage.getItem("programDetails"));
  const userDetails: any = JSON.parse(localStorage.getItem("user"));
  const isEntityEnabled = JSON.parse(localStorage.getItem("isEntityDeleted"));
  // let tempObj = { ...state }; //copy and then update
  // tempObj.selectedTopicDetails = state.selectedTopicDetails;
  // setState(tempObj);
  const dispatch = useDispatch();
  const history: any = useHistory();

  let userRolesData: any = useSelector((state: any) => state?.userRolesProgram);
  const AWS_ACCESS_KEY_ID =CONFIG.REACT_APP_AWS_ACCESS_KEY_ID;
  const AWS_SECRET_ACCESS_KEY =CONFIG.REACT_APP_AWS_SECRET_ACCESS_KEY;
  const AWS_STORAGE_BUCKET_NAME =CONFIG.REACT_APP_AWS_STORAGE_BUCKET_NAME;
  const AWS_REGION_NAME =CONFIG.REACT_APP_AWS_REGION_NAME;

  AWS.config.update({
    accessKeyId: AWS_ACCESS_KEY_ID,
    secretAccessKey: AWS_SECRET_ACCESS_KEY,
    region: AWS_REGION_NAME
  });

  const myBucket = new AWS.S3({
    params: { Bucket: AWS_STORAGE_BUCKET_NAME }
  });

  useEffect(() => {
    setState({
      topicId: location.state.topicId,
      selectedTopicDetails: location.state.selectedTopicDetails,
      userRoles: userRolesData?.userRolesData?.response,
      isSuperAdmin: location.state.isSuperAdmin
    });
    getTopicDetails(location.state.topicId);
    updateTelemetryTopicHandler(
      location.state.selectedTopicDetails,
      "Access Topic",
      "Access_Topic"
    );
  }, []);

  useEffect(() => {
    console.log("Inside parent topic details useEffect.");
    getProgramDetails();
    checkTabSelectionHandler();
    checkTaskTabAccess();
    checkLocalStorage();
  }, []);

  const checkLocalStorage = async () => {
    const programDetailsNew: any = await LocalStorage.getStorage("programDetails");
    console.log("checkLocalStorage in topic details", programDetailsNew);
    if (!programDetailsNew.hasOwnProperty("entityData")) {
      console.log("present");
      console.log(programDetailsNew.program.entity.id, "entity_id");
      setProgramDataInLocalStorage(programDetailsNew);
    }
  };

  const setProgramDataInLocalStorage = async (programDetailsNew: any) => {
    console.log("Call when entityData not present in ls");
    try {
      const entityId = programDetailsNew?.program?.entity?.id;
      const programId = programDetailsNew?.program?.id;
      let data = await ApiClient.get(springshedEndPoints.getPrograms());
      if (data.responseCode === HTTP_STATUS_CODES.ok) {
        const responseEntityList = data?.response?.filter(
          (element: any) => element.entity.id === entityId
        );
        const responseProgram = responseEntityList[0].programs?.filter(
          (element: any) => element.id === programId
        );
        const payload = { entityData: responseEntityList[0], program: responseProgram[0] };
        LocalStorage.setStorage("programDetails", payload);
        setMainLoader(false);
      } else {
        toast.error(data.message);
        setMainLoader(false);
      }
    } catch (error) {
      console.log(error);
      toast.error("Something went wrong");
      setMainLoader(false);
    }
  };

  const checkTaskTabAccess = async () => {
    let userRoles: any = await LocalStorage.getStorage("userRoles");
    let isSuperAdmin = await LocalStorage.getStorage("isSuperAdmin");
    setSuperAdmin(isSuperAdmin);
    let userRolesData: any = userRoles.userRolesData.response;
    let programDetails: any = await LocalStorage.getStorage("programDetails");
    const rolePrivilegesResponse = await ApiClient.get(
      programEndPoints.getRolePrivileges(programDetails.program.id)
    );
    checkAccessTab();

    // const tasksAccess = checkAccessTab(userRolesData, rolePrivilegesResponse, "Task");
    // setIsTaskAccessible(tasksAccess);

    try {
      const payload = {
        programId: programDetails.program.id,
        applicationId:CONFIG.REACT_APP_TASK_ID
      };
      const taskAccessResponse = await ApiClient.get(
        applicationAccessEndPoints.getApplicationAccess(),
        payload
      );
      if (taskAccessResponse.response.length > 0) {
        setIsTaskAccessible(true);
      } else {
        setIsTaskAccessible(false);
      }
    } catch (error) {
      toast.error("Something went wrong");
    }
  };

  const checkAccessTab = async () => {
    try {
      const payload = {
        programId: programDetails.program.id,
        applicationId: CONFIG.REACT_APP_ASSESSMENT_ID
      };
      console.log(payload);
      const taskAccessResponse = await ApiClient.get(
        applicationAccessEndPoints.getApplicationAccess(),
        payload
      );
      if (taskAccessResponse.response.length > 0) {
        setIsAssessmentAccessible(true);
      } else {
        setIsAssessmentAccessible(false);
      }
    } catch (error) {
      toast.error("Something went wrong");
    }
  };

  const checkTabSelectionHandler = async () => {
    const tabSelected: string = await LocalStorage.getStorage("topicsTabOpen");
    tabSelected !== null ? tabHandler(tabSelected) : null;
  };

  const tabHandler = (tabSelected: string) => {
    switch (tabSelected) {
      case "TASK":
        setTopicContents(false);
        setTopicTasks(true);
        setTopicAssessment(false);
        LocalStorage.setStorage("topicsTabOpen", "TASK");
        break;
      case "CONTENT":
        setTopicContents(true);
        setTopicTasks(false);
        setTopicAssessment(false);
        LocalStorage.setStorage("topicsTabOpen", "CONTENT");
        break;
      case "ASSESSMENTS":
        setTopicContents(false);
        setTopicTasks(false);
        setTopicAssessment(true);
        LocalStorage.setStorage("topicsTabOpen", "ASSESSMENTS");
        break;
    }
  };

  useEffect(() => {
    if (isTopicDeleted || isTopicEnabled) disableScroll();
    else enableScroll();
  }, [isTopicEnabled, isTopicDeleted]);

  const getProgramDetails = async () => {
    const programDetails: any = await LocalStorage.getStorage("programDetails");
    setProgramData(programDetails);
    setTopicLibraryEnabled(programDetails.program.topicLibraryEnabled);
    setSubContentEnabled(programDetails.program.subContentEnabled);
  };

  const getTopicDetails = async (topicId: number) => {
    try {
      const payload = {
        topicIds: [topicId]
      };
      const { response } = await ApiClient.post(topicEndPoints.getTopicDetails(), payload);
      const [detailsResponse] = response;
      let status = detailsResponse.status === "ACTIVE" ? true : false;
      setStatus(status);
      setSessionLinked(detailsResponse.sessionLinked);
      setPublishForTopicLibrary(detailsResponse.publishForTopicLibrary);
      setTopicName(detailsResponse.name);
      setDescription(detailsResponse.description);
      setTopicDetails(detailsResponse);
      setLoading(false);
      setMainLoader(false);
      // setFailed(false);
    } catch (error) {
      setLoading(false);
      setMainLoader(false);
      setFailed(true);
    }
  };
  const editTopicDetialsHandler = () => {
    setIsEditable(true);
  };

  const submitTopicDetailsHandler = (
    publishForTopicLibraryStatus?: boolean,
    isPublishForTopicLibraryClicked?: boolean
  ) => {
    const topicDetailsBody = {
      name: topicName.trim(),
      description: description.trim(),
      programId: topicDetails.programId,
      id: topicDetails.id,
      sessionLinked: topicDetails.sessionLinked,
      createdAt: topicDetails.creationDate,
      entityId: programDetails.program.entity.id,
      userName: userDetails.name,
      // publishedForTopicLibrary: publishForTopicLibrary,
      deleted: false
    };
    if (publishForTopicLibraryStatus === true) {
      const newData = { ...topicDetailsBody, publishedForTopicLibrary: true };
      submitTopicDetailsApiCallHandler(newData, isPublishForTopicLibraryClicked);
    } else {
      const newData = { ...topicDetailsBody, publishedForTopicLibrary: false };
      submitTopicDetailsApiCallHandler(newData, isPublishForTopicLibraryClicked);
    }
  };

  const publishForTopicLibraryHandler = (status: boolean) => {
    const isPublishForTopicLibraryClicked = true;
    submitTopicDetailsHandler(!status, isPublishForTopicLibraryClicked);
  };

  const statusTopicHandler = async (
    eventType: string,
    isUpdateTelemetry: boolean = true,
    eventAnalytics: string
  ) => {
    try {
      const programDetails: any = await LocalStorage.getStorage("programDetails");
      const topicId = topicDetails.id;
      const entityId = programDetails.program.entity.id;
      const programId = topicDetails.programId;
      const response = await ApiClient.delete(
        topicEndPoints.deleteTopic(topicId, entityId, programId)
      );
      toast.success(status ? "Topic deactivated successfully" : "Topic reactivated successfully");
      getTopicDetails(topicDetails.id);
      isUpdateTelemetry
        ? [
            updateTelemetryTopicHandler(topicDetails, eventType, ""),
            eventType.includes("Deleted") ? history.push("/app/topics") : null
          ]
        : updateTelemetryTopicHandler(topicDetails, eventType, eventAnalytics);
    } catch (error) {
      toast.error(`Something went wrong. ${error}`);
      setIsTopicDeleted(false);
    }
  };

  const deleteTopicHandler = async (value: boolean) => {
    try {
      if (value === true) {
        setMainLoader(true);
        const programDetails: any = await LocalStorage.getStorage("programDetails");
        const topicId = topicDetails.id;
        const entityId = programDetails.program.entity.id;
        const programId = topicDetails.programId;
        const response = await ApiClient.delete(
          topicEndPoints.hardDeleteTopic(topicId, entityId, programId)
        );
        const eventType = "Topic Deleted";
        setMainLoader(false);
        updateTelemetryTopicHandler(topicDetails, eventType, "Topic_Deleted");
        toast.success("Topic deleted successfully");
        setIsTopicDeleted(false);
        history.push("/new/app/topics");
      }
    } catch (error) {
      toast.error(`Something went wrong. ${error}`);
      setIsTopicDeleted(false);
      setIsEditable(false);
      setMainLoader(false);
    }
  };

  const topicActivateDeactivateHandler = (status: boolean) => {
    // Only send telemetry on Inactive topic status.
    setIsTopicEnabled(true);
  };

  const handleTopicActivateDeactivate = () => {
    status === true
      ? statusTopicHandler("Topic Deactivated", true, "Topic_Deactivated")
      : statusTopicHandler("Topic Reactivated", false, "Topic_Reactivated");
    setStatus((status) => !status);
    setIsTopicEnabled(false);
  };

  const submitTopicDetailsApiCallHandler = async (
    detailsData: any,
    isPublishForTopicLibraryClicked?: boolean
  ) => {
    try {
      setMainLoader(true);
      const addTopicResponse = await ApiClient.put(
        topicEndPoints.updateTopicDetails(state.topicId),
        detailsData
      );
      if (addTopicResponse !== undefined || addTopicResponse !== null) {
        // setModalVisible();
        // getTopics();
        setMainLoader(false);
        getTopicDetails(state.topicId);
        setIsEditable(false);
        !isPublishForTopicLibraryClicked
          ? [
              updateTelemetryTopicHandler(addTopicResponse, "Topic edited", "Topic_Edited"),
              toast.success("Topic details updated successfully.")
            ]
          : null;
      } else {
        setMainLoader(false);
        setIsEditable(false);
      }
    } catch (error) {
      toast.error(`Something went wrong. ${error}`);
      setIsEditable(false);
      setMainLoader(false);
    }
  };

  const updateTelemetryTopicHandler = async (
    data: any,
    eventType: string,
    eventAnalytics: string
  ) => {
    const body = {
      ...commonTelemetryData,
      eventType: eventType,
      topicName: data.name,
      topicId: data.id,
      timestamp: Moment().toLocaleString()
    };
    AddTelemetryService(body, programDetails, userDetails);
    googleWebAnalytics(eventAnalytics, "Telemetry_Event", "Topics");
  };

  const getTopics = () => {
    // const payload = {
    //   programId: location?.state?.programId
    // };
    // dispatch(TopicsActions.getTopics(payload));
  };

  function formatToBytes(bytes: any, decimals = 2) {
    if (bytes === null) {
      return bytes;
    }

    if (bytes === 0) return "0 Bytes";

    // if (bytes.indexOf(" ") >= 0) {
    //   return bytes;
    // }

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }

  const handleCustomChange = async (e: any) => {
    const validTypes = ["mp4", "mpeg", "flv", "png", "jpg", "jpeg", "pdf", "mov"];
    const validTypesImage = ["png", "jpg", "jpeg"];
    const validTypesDocs = ["pdf"];
    const validTypesVideo = ["mp4", "mpeg", "flv", "mov"];

    const splitFileName = e?.currentTarget?.files[0]?.name.split(".");
    const extension = splitFileName[splitFileName.length - 1].toLowerCase();

    if (e.target.validity.valid && validTypes.includes(extension)) {
      setLoading(true);
      let formData: FormData = new FormData();
      formData.append("appType", "pda");
      formData.append("file", e?.currentTarget?.files[0]);
      formData.append("fileName", e?.currentTarget?.files[0]?.name);
      let fileParts = e.currentTarget.files[0].name.split(".");
      let fileName = fileParts[0];
      let fileType = fileParts[1];
      const file_size = formatToBytes(e?.currentTarget?.files[0]?.size);
      let fileKey = "";
      let mimetype = e?.currentTarget?.files[0]?.type;

      if (validTypesImage.includes(extension)) {
        formData.append("fileType", "image");
        fileKey = `image/${e?.currentTarget?.files[0].name}`;
      }
      if (validTypesDocs.includes(extension)) {
        formData.append("fileType", "document");
        fileKey = `document/${e?.currentTarget?.files[0].name}`;
      }
      if (validTypesVideo.includes(extension)) {
        formData.append("fileType", "video");
        fileKey = `video/${e?.currentTarget?.files[0].name}`;
      }
      const uploadData = {
        contentType: extension,
        name: e?.currentTarget?.files[0].name,
        topicId: topicDetails.id,
        programId: programDetails.program.id,
        programName: programDetails.program.name,
        userName: userDetails.name
      };
      if (validTypesVideo.includes(extension)) {
        // videoUploadDbHandler(uploadData, formData);
        try {
          console.log("video upload");
          let uploadParams = {
            // ACL: "public-read",
            Bucket: AWS_STORAGE_BUCKET_NAME,
            Key: fileKey,
            Body: e?.currentTarget?.files[0],
            ContentType: mimetype
          };

          const s3 = new AWS.S3();

          s3.upload(uploadParams, (err: any, data: any) => {
            if (err) {
              console.log(err);
              toast.error("Something went wrong");
            } else {
              const response = { url: data.Location, size: file_size };
              const fileUploadData = {
                ...uploadData,
                url: data.Location,
                size: response.size,
                contentUrl: response
              };
              console.log("after upload", response, fileUploadData);
              setTopicDocument(JSON.stringify(response));
              videoUpload(fileUploadData, formData);
            }
          });
        } catch (error) {
          toast.error("Something went wrong");
        }
      } else {
        try {
          console.log("img/pdf upload");
          let uploadParams = {
            // ACL: "public-read",
            Bucket: AWS_STORAGE_BUCKET_NAME,
            Key: fileKey,
            Body: e?.currentTarget?.files[0],
            ContentType: mimetype
          };

          const s3 = new AWS.S3();
          console.log(uploadParams);

          s3.upload(uploadParams, (err: any, data: any) => {
            if (err) {
              console.log(err);
              toast.error("Something went wrong");
            } else {
              const response = { url: data.Location, size: file_size };
              const fileUploadData = {
                ...uploadData,
                url: data.Location,
                size: response.size
              };
              setTopicDocument(JSON.stringify(response));
              apiCall(fileUploadData);
            }
          });
        } catch (error) {
          toast.error("Something went wrong");
        }
      }
    } else {
      toast.error(
        "Invalid File Type! Only Files with format of mp4, mpeg, mov, flv, png, jpg, jpeg, pdf will be uploaded!!"
      );
    }
  };

  const videoUploadDbHandler = async (uploadData: any, formData: any) => {
    try {
      let response = await ApiClient.put(topicEndPoints.fileUpload(), formData);
      if (response !== null || response !== undefined) {
        const obj = {
          ...uploadData,
          contentUrl: response.response
        };
        videoUpload(obj, formData);
      }
    } catch (error) {
      toast.error("Something went wrong.");
    }
  };

  const videoUpload = async (uploadData: any, formData: any) => {
    let response = await ApiClient.post(topicEndPoints.fileUploadVideo(), formData);
    if (response.responseCode === HTTP_STATUS_CODES.ok) {
      const fileUploadData = {
        ...uploadData,
        vimeo_url: response?.response,
        url: uploadData?.contentUrl?.url,
        size: uploadData?.contentUrl?.size
      };
      console.log("fileUploadData videooo", fileUploadData);
      // setTopicDocument(response?.response);
      apiCall(fileUploadData);
    } else {
      toast.error(response.message);
    }
  };

  const apiCall = async (uploadData: any) => {
    try {
      let response = await ApiClient.post(topicEndPoints.fileUploadResponse(), uploadData);
      if (response !== null || response !== undefined) {
        toast.success("File uploaded successfully");
        setLoading(false);
        getTopicDetails(state.topicId);
        let eventType = "Content added";
        updateTelemetryContentAdd(response, eventType);
      }
    } catch (error) {
      toast.error("Something went wrong.");
    }
  };

  const updateTelemetryContentAdd = async (contentData: any, eventType: string) => {
    const programDetails: any = await LocalStorage.getStorage("programDetails");
    const userDetails: any = await LocalStorage.getStorage("user");
    let content_size_mb: any = null;
    const contentSize = contentData.size || contentData.fileSize;
    const content_size_split = contentSize.split(" ");
    const content_size_val = content_size_split[0];
    if (content_size_split[1] == "KB") {
      const content_size_bytes: any = content_size_val * 1024;
      content_size_mb = (parseFloat(content_size_bytes) / (1024 * 1024)).toFixed(4);
    } else {
      content_size_mb = content_size_val;
    }
    const SOCION_VIDEO_FORMAT = ["mp4", "mpeg", "flv", "mov", "MP4", "MPEG", "FLV", "MOV"];
    const SOCION_IMAGE_FORMAT = ["jpeg", "png", "jpg", "JPEG", "PNG", "JPG"];
    const SOCION_DOC_FORMAT = ["pdf", "PDF"];
    let contentType = null;
    if (SOCION_VIDEO_FORMAT.includes(contentData.contentType || contentData.fileType)) {
      contentType = "Video";
    }

    if (SOCION_DOC_FORMAT.includes(contentData.contentType || contentData.fileType)) {
      contentType = "Document";
    }

    if (SOCION_IMAGE_FORMAT.includes(contentData.contentType || contentData.fileType)) {
      contentType = "Image";
    }

    const body: any = {
      ...commonTelemetryData,
      topicName: topicDetails.name,
      topicId: topicDetails.id,
      contentFileName: contentData.name || contentData.fileName,
      contentType: contentType,
      contentUrl: contentData.contentUrl,
      contentId: contentData.id || contentData.contentId,
      sizeOfContent: content_size_mb,
      eventType: eventType,
      entityId: null,
      entityName: null
    };
    AddTelemetryService(body, programDetails, userDetails);
    googleWebAnalytics("Content_Added", "Telemetry_Event", "Topics");
  };

  const cancelHandler = () => {
    setIsEditable(false);
    setDescription(topicDetails.description);
    setTopicName(topicDetails.name);
  };

  const statusToggleContainerHandler = () => (
    <View style={[styles.toggleButtonContainer]}>
      {/* {sessionLinked && ( */}
      {!programData.program.deleted && (
        <View style={[styles.radioButtonContainer, styles.labelHeaderContainer]}>
          <View style={styles.labelTextContainer}>
            <Text
              fontWeight={FontWeight.Light}
              testId="addRoleText"
              textSize={TextSize.Small}
              textStyle={[styles.headerText, styles.headerAlignment, styles.fontSize16]}
            >
              Status
            </Text>
          </View>
          <View style={styles.labelTextValueContainer}>
            <View style={styles.radioButtonSubContainer}>
              <Switch
                isOn={status}
                onColor="#B7504A"
                offColor="#F1DCDB"
                labelStyle={{}}
                size="small"
                onToggle={() => [topicActivateDeactivateHandler(!status)]}
                customStyleContainer={styles.switchContainer}
                thumbOnStyle={styles.thumbOnStyleSmall}
                thumbOffStyle={styles.thumbOffStyleSmall}
                trackOnStyle={styles.trackStyleSmall}
                trackOffStyle={styles.trackStyleSmall}
              />
              <Text
                fontWeight={FontWeight.Light}
                testId="addRoleText"
                textSize={TextSize.Small}
                textStyle={[
                  styles.headerText,
                  styles.toggleButtonText,
                  styles.headerAlignment,
                  styles.fontSize12
                ]}
              >
                {status ? "Active" : "Inactive"}
              </Text>
            </View>
          </View>
        </View>
      )}
      {/* )} */}
    </View>
  );

  return (
    <View>
      {mainLoader ? (
        <View style={styles.mainContainer}>
          <View style={styles.subContainer}>
            <Loader />
          </View>
        </View>
      ) : (
        <View style={styles.mainContainer}>
          <View
            style={
              width > 1024
                ? [styles.subContainer, styles.subContainerAlignRow]
                : [styles.subContainer, styles.subContainerAlignColumn]
            }
          >
            <View style={styles.leftContainer}>
              <View style={styles.headerContainer}>
                <View style={styles.iconContainerHeader}>
                  <TouchableOpacity>
                    <Icon
                      testID="down"
                      name={IconNames.down}
                      customStyle={styles.backArrowTitleIcon}
                      onPress={() => {
                        history.goBack();
                        LocalStorage.removeStorage("topicsTabOpen");
                      }}
                    />
                  </TouchableOpacity>
                </View>
                <View>
                  <Text
                    fontWeight={FontWeight.Light}
                    testId="text"
                    textSize={TextSize.Small}
                    textStyle={[
                      styles.headerText,
                      styles.headerTextTitleSupport,
                      styles.fontSize14
                    ]}
                  >
                    {"Topics"}
                  </Text>
                </View>
                <View>
                  <Text
                    onPress={() => {}}
                    fontWeight={FontWeight.Regular}
                    testId="text"
                    textSize={TextSize.Small}
                    textStyle={[styles.headerText, styles.headerTextSupport, styles.alignIcon]}
                  >
                    {">"}
                  </Text>
                </View>
                <View style={styles.alignTopicNameHeader}>
                  <Text
                    onPress={() => {}}
                    fontWeight={FontWeight.Regular}
                    testId="text"
                    textSize={TextSize.Small}
                    textStyle={[styles.headerText, styles.headerTextSupport, styles.fontSize14]}
                  >
                    {topicName}
                  </Text>
                </View>
              </View>
              <View
                style={
                  !isEditable
                    ? styles.topicDetailsContainer
                    : [styles.topicDetailsContainer, styles.greenBorderColor]
                }
              >
                <View style={styles.qrCodeContainer}>
                  <View style={styles.qrCodeAlign}>
                    <View style={styles.qrCode}>
                      <View style={styles.qrCode} nativeID="generateQR">
                        <View style={styles.displayQR} nativeID="displayQR">
                          Entity: {programDetails.program.entity.name}
                          <br />
                          Program: {programDetails.program.name}
                          <br />
                          Topic: {topicDetails?.name}
                        </View>
                        <View nativeID="divider"> </View>
                        <View nativeID="qrCodeContainer">
                          <GenerateQR
                            id="qrCode"
                            size={125}
                            value={`{"id":"${topicDetails?.id}","QRtype":"TOPIC"}`}
                          />
                        </View>
                      </View>
                    </View>
                  </View>
                  <TouchableOpacity
                    style={styles.footerQrCode}
                    onPress={() =>
                      DownloadQR({
                        id: "generateQR",
                        qrTitle: `QRCode${topicDetails?.id}`,
                        appendExtension: false
                      })
                    }
                  >
                    <Icon
                      onPress={() => {}}
                      testID="download"
                      name={IconNames.downloadList}
                      customStyle={styles.titleIcon}
                    />
                    <View style={styles.qrDownloadText}>
                      <Text
                        fontWeight={FontWeight.Light}
                        testId="addRoleText"
                        textSize={TextSize.Small}
                        textStyle={[
                          styles.headerText,
                          styles.headerAlignment,
                          styles.headerTextQrSupport,
                          styles.fontSize9
                        ]}
                      >
                        Download QR
                      </Text>
                    </View>
                  </TouchableOpacity>
                </View>

                <View style={styles.topicDetials}>
                  {!isEditable && (
                    <View>
                      <View style={styles.labelHeaderContainer}>
                        <View style={[styles.topicHeaderAlignmnet, styles.labelTextContainer]}>
                          <Text
                            fontWeight={FontWeight.Light}
                            testId="addRoleText"
                            textSize={TextSize.Small}
                            textStyle={[
                              styles.headerText,
                              styles.headerAlignment,
                              styles.fontSize16
                            ]}
                          >
                            Topic name
                          </Text>
                        </View>

                        <View
                          style={[
                            styles.topicNameHeaderAlignment,
                            styles.labelTextValueContainer,
                            { flexDirection: "row", justifyContent: "space-between" }
                          ]}
                        >
                          <Text
                            fontWeight={FontWeight.Regular}
                            testId="addRoleText"
                            textSize={TextSize.Small}
                            textStyle={[
                              styles.headerText,
                              styles.fontSize14,
                              { fontFamily: FontFamily.Bold }
                            ]}
                          >
                            {topicName}
                          </Text>

                          <View style={{ flexDirection: "row" }}>
                            {/* DELETE TOPIC BUTTON  */}
                            {((!state.isSuperAdmin && state.userRoles?.includes("PROGRAM_ADMIN")) ||
                              state.userRoles?.includes("ENTITY_ADMIN") ||
                              state.userRoles?.includes("CONTENT_ADMIN")) &&
                              !sessionLinked &&
                              !programData.program.deleted &&
                              status && (
                                <View style={styles.deleteTopicContainerMain}>
                                  <TouchableOpacity onPress={() => setIsTopicDeleted(true)}>
                                    <View style={styles.deleteTopicContainer}>
                                      <Icon
                                        testID="edit"
                                        name={IconNames.deleteFile}
                                        customStyle={[styles.alignButton]}
                                      />
                                    </View>
                                  </TouchableOpacity>
                                </View>
                              )}

                            {/* if sessionLinked not editable */}
                            {((!state.isSuperAdmin && state.userRoles?.includes("PROGRAM_ADMIN")) ||
                              state.userRoles?.includes("ENTITY_ADMIN") ||
                              state.userRoles?.includes("CONTENT_ADMIN")) &&
                              !sessionLinked && (
                                <View style={[styles.alignEditButtonContainer]}>
                                  {!isEditable && (
                                    <>
                                      {status && (
                                        <TouchableOpacity>
                                          <Icon
                                            testID="close"
                                            name={IconNames.penPaper}
                                            customStyle={styles.penPaperIcon}
                                            onPress={() => editTopicDetialsHandler()}
                                          />
                                        </TouchableOpacity>
                                      )}
                                    </>
                                  )}
                                </View>
                              )}
                          </View>
                        </View>
                      </View>

                      {((!state.isSuperAdmin && state.userRoles?.includes("PROGRAM_ADMIN")) ||
                        state.userRoles?.includes("ENTITY_ADMIN") ||
                        state.userRoles?.includes("CONTENT_ADMIN")) && (
                        <>{statusToggleContainerHandler()}</>
                      )}

                      <View style={styles.labelHeaderContainer}>
                        <View style={[styles.topicHeaderAlignmnet, styles.labelTextContainer]}>
                          <Text
                            fontWeight={FontWeight.Light}
                            testId="addRoleText"
                            textSize={TextSize.Small}
                            textStyle={[
                              styles.headerText,
                              styles.headerAlignment,
                              styles.fontSize16
                            ]}
                          >
                            Description
                          </Text>
                        </View>
                        <View
                          style={[styles.topicNameHeaderAlignment, styles.labelTextValueContainer]}
                        >
                          <ScrollContainer
                            height="50px"
                            scrollable={description?.length > 120 ? true : false}
                          >
                            <Text
                              fontWeight={FontWeight.Light}
                              testId="addRoleText"
                              textSize={TextSize.Small}
                              textStyle={[styles.headerText, styles.fontSize14]}
                            >
                              {description}
                            </Text>
                          </ScrollContainer>
                        </View>
                      </View>
                    </View>
                  )}
                  {isEditable && (
                    <View style={[styles.topicDetials]}>
                      <View style={[styles.userInputContainer, { marginTop: -10 }]}>
                        <UserInputSecondary
                          textValue="Topic name"
                          handleChange={(name: string) => {
                            setTopicName(name);
                          }}
                          handleBlur={() => {}}
                          value={topicName}
                          placeholder="Enter name"
                          name="enterTopicName"
                          id="enterTopicName"
                          noFormik
                          userStyle={[styles.inputStylingAlignment]}
                          textInputContainerStyles={styles.titleInputContainerStyles}
                          userStyleText={styles.textHeaderField}
                        />
                      </View>

                      {((!state.isSuperAdmin && state.userRoles?.includes("PROGRAM_ADMIN")) ||
                        state.userRoles?.includes("ENTITY_ADMIN") ||
                        state.userRoles?.includes("CONTENT_ADMIN")) && (
                        <>{statusToggleContainerHandler()}</>
                      )}

                      <View style={styles.userInputContainer}>
                        <UserInputTextArea
                          textValue="Description"
                          label="Description"
                          placeholder="Description"
                          name="Description"
                          id="description"
                          handleChange={(description: string) => {
                            setDescription(description);
                          }}
                          handleBlur={() => {}}
                          value={description}
                          numberOfLines={5}
                          multiline={true}
                          noFormik
                          userStyle={[styles.inputStylingAlignment]}
                          userStyleText={styles.textHeaderField}
                          titleInputContainerStyles={[styles.titleInputContainerStyles]}
                          textFieldContianerAlign={styles.textFieldContianerAlign}
                        />
                      </View>
                    </View>
                  )}
                  <View>
                    <View style={styles.labelHeaderContainer}>
                      {isEditable && <View style={{ flex: 23 }}> </View>}
                      <View
                        style={
                          !isEditable
                            ? [styles.labelHeaderContainer, { flex: 100 }]
                            : [styles.labelHeaderContainer, { flex: 77 }]
                        }
                      >
                        <View
                          style={[
                            styles.topicHeaderAlignmnet,
                            [!isEditable ? styles.labelTextContainer : { flex: 15 }]
                          ]}
                        >
                          <Text
                            fontWeight={FontWeight.Light}
                            testId="addRoleText"
                            textSize={TextSize.Small}
                            textStyle={[
                              styles.headerText,
                              styles.headerAlignment,
                              [!isEditable ? styles.fontSize16 : styles.fontSize12]
                            ]}
                          >
                            Created on
                          </Text>
                        </View>
                        <View
                          style={[styles.topicNameHeaderAlignment, styles.labelTextValueContainer]}
                        >
                          <Text
                            fontWeight={FontWeight.Light}
                            testId="addRoleText"
                            textSize={TextSize.Small}
                            textStyle={[
                              styles.headerText,
                              styles.headerAlignment,
                              styles.fontSize14,
                              styles.textColorBlack,
                              [
                                !isEditable
                                  ? styles.fontSize14
                                  : [styles.fontSize12, styles.headerText]
                              ]
                            ]}
                          >
                            {!isEditable
                              ? dataHelper.formatDate(topicDetails?.creationDate, "DD MMM YYYY")
                              : `: ${dataHelper.formatDate(
                                  topicDetails?.creationDate,
                                  "DD MMM YYYY"
                                )}`}
                          </Text>
                        </View>
                      </View>
                    </View>
                  </View>

                  <View>
                    <View style={styles.labelHeaderContainer}>
                      {isEditable && <View style={{ flex: 23 }}> </View>}
                      <View
                        style={
                          !isEditable
                            ? [styles.labelHeaderContainer, { flex: 100 }]
                            : [styles.labelHeaderContainer, { flex: 77 }]
                        }
                      >
                        <View
                          style={[
                            styles.topicHeaderAlignmnet,
                            [!isEditable ? styles.labelTextContainer : { flex: 15 }]
                          ]}
                        >
                          <Text
                            fontWeight={FontWeight.Light}
                            testId="addRoleText"
                            textSize={TextSize.Small}
                            textStyle={[
                              styles.headerText,
                              styles.headerAlignment,
                              [!isEditable ? styles.fontSize16 : styles.fontSize12]
                            ]}
                          >
                            Created by
                          </Text>
                        </View>
                        <View
                          style={[styles.topicNameHeaderAlignment, styles.labelTextValueContainer]}
                        >
                          <Text
                            fontWeight={FontWeight.Light}
                            testId="addRoleText"
                            textSize={TextSize.Small}
                            textStyle={[
                              styles.headerText,
                              styles.headerAlignment,
                              styles.fontSize14,
                              styles.textColorBlack,
                              [
                                !isEditable
                                  ? styles.fontSize14
                                  : [styles.fontSize12, styles.headerText]
                              ]
                            ]}
                          >
                            {topicDetails?.topicCreatorUserName === null
                              ? "-"
                              : topicDetails?.topicCreatorUserName}
                          </Text>
                        </View>
                      </View>
                    </View>
                  </View>

                  <View
                    style={
                      !isEditable
                        ? [styles.footerButtonsTopicDetailsContainer, { alignSelf: "flex-end" }]
                        : [
                            styles.footerButtonsTopicDetailsContainer,
                            { justifyContent: "space-between", alignItems: "center" }
                          ]
                    }
                  >
                    <View style={styles.addToPublicLibraryContainer}>
                      {topicLibraryEnabled &&
                        ((!state.isSuperAdmin && state.userRoles?.includes("PROGRAM_ADMIN")) ||
                          state.userRoles?.includes("ENTITY_ADMIN") ||
                          state.userRoles?.includes("CONTENT_ADMIN")) &&
                        status &&
                        !dataHelper.isEmptyArray(topicDetails?.contentFiles) && (
                          <View style={[styles.alignContainer, styles.alignContent]}>
                            <View style={styles.alignInnerContent}>
                              <Text
                                fontWeight={FontWeight.Light}
                                testId="addRoleText"
                                textSize={TextSize.Small}
                                textStyle={[
                                  styles.headerText,
                                  styles.headerAlignment,
                                  styles.fontSize14
                                ]}
                              >
                                Add to topic library
                              </Text>
                              <Icon
                                testID="edit"
                                name={IconNames.about}
                                customStyle={[styles.alignButton]}
                              />
                            </View>
                            <View style={[]}>
                              <View style={[styles.radioButtonContainer, styles.alignSwitch]}>
                                <Switch
                                  isOn={publishForTopicLibrary}
                                  onColor="#B7504A"
                                  offColor="#F1DCDB"
                                  labelStyle={{}}
                                  size="small"
                                  onToggle={() => {
                                    setPublishForTopicLibrary(
                                      (publishForTopicLibrary) => !publishForTopicLibrary
                                    );
                                    publishForTopicLibraryHandler(publishForTopicLibrary);
                                  }}
                                  thumbOnStyle={styles.thumbOnStyleSmall}
                                  thumbOffStyle={styles.thumbOffStyleSmall}
                                  trackOnStyle={styles.trackStyleSmall}
                                  trackOffStyle={styles.trackStyleSmall}
                                  customStyleContainer={[
                                    styles.switchContainer,
                                    styles.switchContainerAlign
                                  ]}
                                />
                              </View>
                            </View>
                          </View>
                        )}
                    </View>
                    <View style={styles.editButtonsContainer}>
                      {isEditable && (
                        <View style={styles.editIconContainer}>
                          <Button
                            type={ButtonType.Secondary}
                            buttonStyles={[styles.submitbutton, styles.alignEditButtons]}
                            textStyles={{ fontSize: 12 }}
                            title="Cancel"
                            onPress={() => [cancelHandler()]}
                          />
                          <Button
                            type={ButtonType.Primary}
                            buttonStyles={[styles.submitbutton, styles.alignEditButtons]}
                            textStyles={{ fontSize: 12 }}
                            title="Save"
                            onPress={submitTopicDetailsHandler}
                            disabled={topicName === "" || description === ""}
                          />
                        </View>
                      )}
                    </View>
                  </View>
                </View>
              </View>

              <View style={[styles.buttonContainer, styles.alignButtonContainer]}>
                <ButtonTab
                  type={topicContent ? ButtonTypeSecondary.Secondary : ButtonTypeSecondary.Primary}
                  buttonStyles={[
                    styles.toggleButton,
                    styles.toggleButtonLeftRadiusAlign,
                    !isTaskAccessible &&
                      !isAssessmentAccessible &&
                      !superAdmin &&
                      styles.toggleButtonRightRadiusAlign
                  ]}
                  textStyles={styles.textStyle}
                  title={`Content (${
                    topicDetails?.contentFiles.length === undefined
                      ? 0
                      : topicDetails?.contentFiles.length
                  })`}
                  onPress={() => {
                    setTopicContents(true);
                    setTopicTasks(false);
                    setTopicAssessment(false);
                    LocalStorage.setStorage("topicsTabOpen", "CONTENT");
                  }}
                />
                {((isTaskAccessible && superAdmin && topicDetails?.taskDTO !== null) ||
                  (isTaskAccessible && !superAdmin)) && (
                  <ButtonTab
                    type={topicTasks ? ButtonTypeSecondary.Secondary : ButtonTypeSecondary.Primary}
                    buttonStyles={[
                      styles.toggleButton,
                      // for assessment Tab UI
                      // !isAssessmentAccessible &&
                      //   !superAdmin && [styles.toggleButtonRightRadiusAlign],
                      styles.toggleButtonRightRadiusAlign
                    ]}
                    textStyles={styles.textStyle}
                    title={`Tasks (${topicDetails?.taskDTO === null ? 0 : 1})`}
                    onPress={() => {
                      setTopicContents(false);
                      setTopicTasks(true);
                      setTopicAssessment(false);
                      LocalStorage.setStorage("topicsTabOpen", "TASK");
                    }}
                  />
                )}
                {((isAssessmentAccessible && superAdmin && topicDetails?.assessmentDTO !== null) ||
                  (!superAdmin && isAssessmentAccessible)) && (
                  <ButtonTab
                    type={
                      topicAssessments ? ButtonTypeSecondary.Secondary : ButtonTypeSecondary.Primary
                    }
                    buttonStyles={[styles.toggleButton, styles.toggleButtonRightRadiusAlign]}
                    textStyles={styles.textStyle}
                    title={`Assessments (${topicDetails?.assessmentDTO === null ? 0 : 1})`}
                    onPress={() => {
                      setTopicContents(false);
                      setTopicTasks(false);
                      setTopicAssessment(true);
                      LocalStorage.setStorage("topicsTabOpen", "ASSESSMENTS");
                    }}
                  />
                )}
              </View>
              <View>
                <View style={styles.container}>
                  {topicContent ? (
                    <>
                      <>
                        <TopicDetailsContent
                          loading={loading}
                          handleCustomChange={handleCustomChange}
                          topicDetails={topicDetails}
                          getTopicDetails={getTopicDetails}
                          updateTelemetryContentAdd={updateTelemetryContentAdd}
                          status={status}
                          userRoles={state.userRoles}
                          isSuperAdmin={isSuperAdmin}
                          subContentEnabled={subContentEnabled}
                          programStatus={programData?.program?.deleted}
                          publishForTopicLibraryHandler={publishForTopicLibraryHandler}
                        />
                      </>
                    </>
                  ) : topicTasks ? (
                    <View>
                      <TopicDetailsTasks
                        topicDetails={topicDetails}
                        getTopicDetails={getTopicDetails}
                        userRoles={state.userRoles}
                        isTaskAccessible={isTaskAccessible}
                        applicationId={applicationId}
                        superAdmin={superAdmin}
                      />
                    </View>
                  ) : topicAssessments ? (
                    <View>
                      <TopicDetailsAssessments
                        topicDetails={topicDetails}
                        getTopicDetails={getTopicDetails}
                        userRoles={state.userRoles}
                        isTaskAccessible={isTaskAccessible}
                        applicationId={applicationId}
                        isAssessmentAccessible={isAssessmentAccessible}
                        superAdmin={superAdmin}
                      />
                    </View>
                  ) : null}
                </View>
              </View>
            </View>
            <View style={styles.rightContainer}></View>
          </View>
          {isTopicDeleted && (
            <View>
              <SocionModal
                modalVisible={isTopicDeleted}
                setModalVisible={() => setIsTopicDeleted(!isTopicDeleted)}
                component={
                  <RemoveLinkModal
                    modalVisible={isTopicDeleted}
                    selectedData={deleteTopicHandler}
                    textValue={DELETETOPICMESSAGE}
                    setModalVisible={() => setIsTopicDeleted(!isTopicDeleted)}
                  />
                }
              />
            </View>
          )}
          {isTopicEnabled && (
            <View>
              <SocionModal
                modalVisible={isTopicEnabled}
                setModalVisible={() => setIsTopicEnabled(!isTopicEnabled)}
                component={
                  <RemoveLinkModal
                    modalVisible={isTopicEnabled}
                    selectedData={handleTopicActivateDeactivate}
                    textValue={
                      status
                        ? "Are you sure you want to deactivate the Topic?"
                        : "Are you sure you want to reactivate the Topic?"
                    }
                    setModalVisible={() => setIsTopicEnabled(!isTopicEnabled)}
                  />
                }
              />
            </View>
          )}
        </View>
      )}{" "}
    </View>
  );
}

const styles = StyleSheet.create({
  // main container
  mainContainer: {
    // top: 55,
    padding: 20,
    paddingTop: 15,
    backgroundColor: colorPallete.cordioBeigeLight2,
    minHeight: "calc(100vh - 50px)"
  },
  subContainer: {
    backgroundColor: colorPallete.white,
    borderRadius: 10,
    shadowColor: colorPallete.cordioRedDark1,
    shadowOpacity: 0.1,
    shadowOffset: {
      height: 10,
      width: 5
    },
    shadowRadius: 10,
    paddingHorizontal: 35,
    paddingVertical: 20,
    display: "flex",
    minHeight: "calc(100vh - 100px)"
  },
  subContainerAlignRow: {
    flexDirection: "row"
  },
  subContainerAlignColumn: {
    flexDirection: "column"
  },

  leftContainer: {
    flex: 80
  },
  rightContainer: {
    flex: 20
  },

  // Header
  headerContainer: {
    flexDirection: "row",
    marginBottom: 20,
    alignItems: "center"
    // padding: 20,
    // paddingLeft: 25
  },
  iconContainerHeader: {
    marginRight: 20
  },
  backArrowTitleIcon: {
    color: colorPallete.textBlack,
    fontSize: 9,
    transform: [{ rotate: "90deg" }]
  },
  headerTextTitleSupport: {
    fontWeight: "700"
  },
  headerTextSupport: {
    // Due to font size, increasing the size.
    fontFamily: FontFamily.Regular,
    marginLeft: 10
  },
  alignTopicNameHeader: {
    width: "87%"
  },
  alignIcon: {
    marginRight: 0,
    marginLeft: 10,
    textDecorationLine: "none"
  },

  displayQR: {
    display: "none",
    color: colorPallete.textLight
  },
  container: {},
  header: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row",
    marginBottom: 5
  },
  headerText: {
    // fontSize: 12,
    fontFamily: FontFamily.Medium,
    lineHeight: 17
    // marginBottom: 5
  },
  alignHeader: {
    marginBottom: 0,
    marginLeft: 10
  },
  headerAlignment: {
    color: colorPallete.textLight,
    fontFamily: FontFamily.Regular
  },
  fontSizeText: {
    // fontSize: 10
  },
  crossCircle: {
    fontSize: 18,
    opacity: 0.4
  },

  topicDetailsContainer: {
    flexDirection: "row",
    marginBottom: 15,
    borderColor: colorPallete.darkGreyOne,
    borderStyle: "solid",
    borderWidth: 1,
    borderRadius: 5,
    padding: 10,
    paddingRight: 25
  },
  greenBorderColor: {
    borderColor: colorPallete.cordioGreen
  },

  //QR Code scss
  qrCodeContainer: {
    flex: 20,
    marginRight: 10,
    alignItems: "center"
  },
  qrCodeAlign: {},
  qrCode: {
    flex: 100,
    maxWidth: 130,
    minHeight: 130
  },
  qrCodeImage: {
    top: 0,
    width: 160,
    height: 160,
    transform: [{ scale: 1.3 }]
  },
  footerQrCode: {
    flexDirection: "row",
    justifyContent: "space-between"
  },
  titleIcon: {
    fontSize: 15
  },
  qrDownloadText: {},
  headerTextQrSupport: {
    fontWeight: "400",
    textDecorationLine: "underline",
    marginLeft: 10,
    fontSize: 10,
    color: colorPallete.textBlack
  },

  topicDetials: {
    flex: 80
  },
  userInputContainer: {},
  titleInputContainerStyles: {
    marginTop: 0,
    marginBottom: 5
  },
  inputStylingAlignment: {
    width: "100%",
    color: colorPallete.textBlack,
    borderColor: colorPallete.lightGreyThree
  },
  textHeaderField: {
    flex: 23,
    fontSize: 16
  },
  textFieldContianerAlign: {
    marginLeft: 0
  },

  // label and value
  labelHeaderContainer: {
    flexDirection: "row",
    marginVertical: 8
  },
  labelTextContainer: {
    flex: 23
  },
  labelTextValueContainer: {
    flex: 77
  },

  descriptionStyle: {
    maxHeight: 50
  },

  topicHeaderAlignmnet: {
    marginBottom: 0
  },
  topicNameHeaderAlignment: {
    marginBottom: 5
  },
  subHeaderAlignment: {
    flexDirection: "row",
    marginTop: 10
  },

  toggleButtonContainer: {
    marginBottom: 5
  },
  toggleButtonText: {},

  alignContainer: {},
  alignContent: {
    flexDirection: "row",
    alignItems: "center"
  },
  alignInnerContent: {
    flexDirection: "row"
  },

  // Topic Details Edit Buttons container
  footerButtonsTopicDetailsContainer: {
    flexDirection: "row"
  },
  addToPublicLibraryContainer: {},
  editButtonsContainer: {},

  // Font Sizes
  fontSize9: {
    fontSize: 9
  },
  fontSize12: {
    fontSize: 12
  },
  fontSize14: {
    fontSize: 14
  },
  fontSize16: {
    fontSize: 16
  },

  textColorBlack: {
    color: colorPallete.textBlack
  },
  alignEditButtonContainer: {},
  editIconContainer: {
    flexDirection: "row"
  },
  submitbutton: {
    width: "140px",
    height: "50px",
    borderRadius: 10
  },
  alignEditButtons: {
    marginLeft: 10
  },

  penPaperIcon: {
    fontSize: 15,
    opacity: 0.6,
    marginLeft: 25
  },
  alignButton: {
    fontSize: 15,
    color: colorPallete.cordioTaupe,
    marginLeft: 15
  },
  radioButtonContainer: {
    flexDirection: "row",
    alignItems: "center"
  },
  radioButtonSubContainer: {
    flexDirection: "row",
    alignItems: "center"
  },

  // Switch
  switchContainer: {
    marginRight: 15
  },
  switchContainerAlign: {
    marginRight: 0,
    marginLeft: 30
  },

  deleteTopicContainerMain: {
    flexDirection: "row"
  },
  deleteTopicContainer: {
    flexDirection: "row",
    alignItems: "center"
  },
  alignSwitch: {},

  // Buttons
  buttonContainer: {
    flexDirection: "row",
    marginBottom: 20
  },
  alignButtonContainer: {
    borderColor: colorPallete.cordioRedLight3,
    borderRadius: 10,
    borderStyle: "solid",
    borderWidth: 1
  },
  toggleButton: {
    width: 180
  },
  toggleButtonLeftRadiusAlign: {
    borderBottomLeftRadius: 5,
    borderTopLeftRadius: 5
  },
  toggleButtonRightRadiusAlign: {
    borderTopRightRadius: 5,
    borderBottomRightRadius: 5
  },
  textStyle: {
    fontSize: 12
  },

  //upload button
  uploadDocsContainer: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row",
    alignItems: "center",
    backgroundColor: colorPallete.lightGrey,
    borderRadius: 5,
    borderColor: colorPallete.lightGrey,
    height: "64px",
    padding: 15,
    marginTop: 0
  },
  uploadDocIcon: {
    opacity: 0.5
  },

  // custom save icon (tick mark with circle)
  mainTickContainer: {
    justifyContent: "center",
    alignItems: "center",
    marginRight: 16
  },
  tickContainer: {
    width: 18,
    height: 18,
    backgroundColor: colorPallete.textLight,
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 50
  },
  tickContainerColor: {
    backgroundColor: colorPallete.gray
  },
  iconStyleTick: {
    fontSize: 8,
    color: colorPallete.white
  },

  // Swtich custom
  thumbOnStyleSmall: {
    height: 14,
    width: 15,
    marginRight: 1,
    left: -3,
    borderRadius: 50
  },
  thumbOffStyleSmall: {
    height: 14,
    width: 15,
    borderRadius: 50,
    marginLeft: -1
  },
  trackStyleSmall: {
    height: 16,
    width: 31,
    padding: 0,
    borderRadius: 50
  }
});

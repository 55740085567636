export const profileEndPoints = {
  userProfile: () => `/profile`,
  uploadFile: () => `/upload`,
  changePassword: () => `/user/change-password`,
  getUserProfile: () => `/user/get-profile`,
  getUserRole: () => `/user/role`,
  getUserQualification: () => `/user/qualification`,
  updateUserProfile: () => `/user/update-profile`,
  sendOtp: (phoneNumber: number, typeOfOTP: string, countryCode: string) =>
    `/user/send-otp?phoneNumber=${phoneNumber}&typeOfOTP=${typeOfOTP}&countryCode=${countryCode}`, // API updated to new
  sendOtpMobile: () => `/user/v4/send-otp`,
  updateEmailValidateOtp: () => `/user/validate-otp`,
  updateEmail: (emailId: string) => `/user/update-email-id?emailId=${emailId}`,
  deleteUserQualification: (qualificationId: string) => `/user/qualification/${qualificationId}`,
  deleteUserRole: (roleId: string) => `/user/role/${roleId}`,
  getUserEligibleProgramsForRole: () => `/entity/program/qualification-role/user/enabled`,
  getUserEligibleProgramsForQualification: () => `/entity/program/qualification-role/user/enabled`,
  getUserEligibleProgramsForBadges: () =>
    `/entity/program/qualification-role/user/enabled?acceptingType=Badge`,
  getUserEligibleBadgesDetails: (programId: string) => `/badge/class/program/${programId}/badge`,
  updateUserBadgeClass: () => `badge/assign/self/v2`,
  getSelectedBadges: (id: string) => `badge/assign/self/programId/${id}/v2`,
  validateOtp: () => `/user/validate-otp`,
  sendOtpNewPhone: (phoneNumber: number, countryCode: string) =>
    `/user/v3/send-otp-new-phone?phoneNumber=${phoneNumber}&countryCode=${countryCode}`,
  fileUpload: () => `/user/file/upload`,
  pushNotification: (status: boolean) => `user/update-push-notification/status?enable=${status}`,
  addQualification: () => `/user/qualification/add/list`,
  updateQualification: (id: string) => `user/qualification/${id}/list`,
  addRole: () => `/user/role/add`,
  updateBaseLocation: () => `/user/location`,
  revokeLocationConsent: () => `/user/location`,
  updatePersonalInfo: () => `/user/update-pii/status`,
  generateProfileCard: () => `/user/generate/profile-card`,
  updatePhoto: () => `/user/update-photo`,
  placeAutoComplete: () => `/user/map/autocomplete`,
  placeDetails: () => `/user/map/details`,
  getSelectedUserDetails: (userId: number) => `/user/private/details/profile-role/${userId}`,
  getMapMyIndiaToekn: () => `/user/map/map-my-india/auth/token`,
  updateEmailIdOtp: () => `user/v4/update-email-id/otp`,
  updateEmailIdOtpSecondary: () => `user/v3/update-email-id/otp`,
  optValidate: () => `/user/v4/validate-otp`,
  sendOtpToNewPhone: () => `user/v4/send-otp-new-phone`,
  updateUsername: () => `user/v4/update-name`,
  updateUserPhoto: () => `user/v4/update-photo`,
  sendPhoneNumberOtp: () => `user/v4/send-otp`,
  sendOtpDeleteUser: () => `user/v4/delete/otp`,
  deleteUser: (userId: string) => `/user/v3/delete/${userId}`
};

export const loginEndPoints = {
  postCredentials: () => `/user/v4/login`,
  getCountryCode: () => `/user/get-country-codes`,
  loginUser: () => `user/v4/login/otp`,
  validateLoginUserOtp: () => `user/v4/validate-otp/login`
};

export const forgotPasswordEndPoints = {
  requestForgotOtp: () => `user/v4/send-otp`,
  validateForgotPasswordOtp: () => `user/v4/validate-otp`,
  forgotPasswordUpdate: () => `user/v4/forgot-password`
};

export const topNavbarEndPoints = {
  postNotifications: (pageNumber: Number, pageSize: Number) =>
    `/session/notifications/v2/timezone?pageNumber=${pageNumber}&pageSize=${pageSize}`,
  deleteReadNotification: () => `/session/notifications/status`
};

export const signupEndPoints = {
  signupUser: () => `/user/v3/user-create-web`,
  resendOtp: () => "/user/v3/re-send-register-otp",
  validateOtp: () => `/user/v3/validate-otp`,
  setPassword: () => `/user/v3/set-password`,
  getverificationCode: () => `/user/v3/send-otp`,
  updateForgotPassword: () => `user/v3/forgot-password`,
  signupNewUser: () => `user/v4/create-user-mobile`,
  resendNewOtp: () => `/user/v4/re-send-register-otp`,
  validateNewOtp: () => `/user/v4/validate-otp`,
  setNewPassword: () => `/user/v4/set-password`,
  getNewVerificationCode: () => `/user/v4/send-otp`
  // updateForgotPassword: () => `/user/v4/forgot-password`
};

export const applicationAccessEndPoints = {
  getApplicationAccess: () => `/access/role-access/program/user`
};

export const springshedEndPoints = {
  getPrograms: () => `/entity/program`,
  getEntityDetails: (entityId: number) => `/entity/registrySearch/${entityId}`,
  getEntityRoles: (entityId: number) => `/entity/registrySearch/role/${entityId}`,
  getProfileFromPhone: () => `/user/get-profile-from-phoneNumber`,
  getProfileByUserId: (userId: number) => `/user/private/details/${userId}`,
  inviteEntity: () => `/entity/invite`,
  registerEntity: () => `/entity/registerEntity`,
  fileUpload: () => `/user/file/upload`,
  createProgram: () => `/entity/program`,
  encryptData: () => `/user/encrypt/data`,
  addEntityMember: () => `/entity/registerEntityRoles`,
  activateRemoveMember: () => `/entity/registryEntityRolesUpdate`,
  getUserDetails: (entityId: number, userId: string) =>
    `/entity/registryRolesSearch/${entityId}/${userId}`,
  getUserDataByQR: (userId: string) => `/user/private/details/profile-role/${userId}`,
  getAllUserPrograms: (userId: string) => `/entity/program/list/user/${userId}`,
  postProgramDocument: (programId: any) => `/entity/program/${programId}/document`,
  deleteProgramDocument: (programId: string, documentId: string) =>
    `/entity/program/${programId}/document?documentId=${documentId}`,
  updateEntityDetails: () => `/entity/registryEntityUpdate`,
  updateEntityDocuments: () => `/entity/registerEntityDocs`,
  deleteEntityDocuments: (docId: string, isDeleted: boolean) =>
    `entity/registryEntityDocsUpdate/${docId}/${isDeleted}`,
  uploadEntityDocument: (entityId: number) => `entity/${entityId}/documents`,
  getSDGList: () => `/entity/sdg`,
  getProgramsSdgList: (programId: string) => `/entity/sdg/program/${programId}`,
  updateSdgList: (programId: string) => `/entity/sdg/program/${programId}`,
  getuserRoleBadge: () => `badge/private/registry/details?active=true`,
  getProgramRatings: (programId: string) => `entity/program/${programId}/ratings`,
  updateProgramLogo: () => `/entity/program/logo`,
  getTaggedEntity: (programId: string) => `entity/program/${programId}/partner`,
  deletePartner: (programId: string, entityId: string) =>
    `/entity/program/${programId}/partner/${entityId}`,
  getAllRegisteredEntities: () => `entity/all`,
  addPartner: () => `/entity/program/partner`,
  getTaggedPartners: (entityId: string) => `/entity/program/partner/${entityId}`,
  getProfileFromPhoneOrEmail: () => `/user/get-profile/username`
};

export const topicEndPoints = {
  getTopics: () => `/entity/topic-details/metrics/v2`,
  getTopicDetails: () => `/entity/topic-details/metrics`,
  addTopicDetails: () => `/entity/topic`,
  updateTopicDetails: (topicId: number) => `/entity/topic/${topicId}`,
  fileUpload: () => `/user/file/upload`,
  fileUploadResponse: () => `/entity/content`,
  fileUploadVideo: () => `/entity/content/upload/vimeo/new`,
  deleteTopic: (topicId: number, entityId: number, programId: number) =>
    `/entity/topic/${topicId}?entityId=${entityId}&programId=${programId}`,
  addNewSubTopicLink: () => `/entity/topic-sub-content`,
  updateSubTopicLink: (contentId: number) => `/entity/topic-sub-content/${contentId}`,
  deleteSubTopicLink: (contentId: number) => `/entity/topic-sub-content/${contentId}`,
  deleteContentFile: (contentId: number) => `/entity/content/${contentId}`,
  addSurveyLink: () => `entity/survey`,
  addTaskLink: () => `entity/task`,
  getTaskDetails: (topicId: string) => `entity/task/topic/${topicId}`,
  hardDeleteTopic: (topicId: number, entityId: number, programId: number) =>
    `/entity/topic/${topicId}/delete?programId=${programId}&entityId=${entityId}`,
  getParticipantList: () => `telemetry/user-role/details`,
  getTrainersList: () => `telemetry/user-role/details`,
  getParticipantListCSV: () => `telemetry/user-role/details/csv`,
  getTrainersListCSV: () => `telemetry/user-role/details/csv`,
  getAssessmentDetails: (topicId: string) => `entity/assessment/topic/${topicId}`
};

export const telemetryUploadEndPoints = {
  telemetryUpload: () => `/telemetry/telemetry`,
  telemetryBadgeUpload: () => `/entity/list/telemetry`
};

export const programEndPoints = {
  getProgramDetails: (programId: number) => `/entity/program/${programId}`,
  getProgramDocumentsById: (programId: number) => `/entity/program/${programId}/document`,
  getProgramMembers: (programId: number, entityId: number) =>
    `/entity/program/members?programId=${programId}&entityId=${entityId}`,
  getProgramStatusEP: (programId: number) => `/entity/program/${programId}/alert-status`,
  getRolePrivileges: (programId: number) => `/access/role-access/program/${programId}`,
  getSuperAdminPrivileges: (programId: number) => `/access/application-access/program/${programId}`,
  updateSuperAdminPrivileges: () => `/access/application-access/program`,
  updateRolePrivileges: () => `/access/role-access/program`,
  getUserLicensesById: (programId: number) => `/entity/program/${programId}/licence/user`,
  updateDescription: (programId: number) => `/entity/program/${programId}/description`,
  updateSettings: (programId: number, key: string, value: boolean | string) =>
    `/entity/program/${programId}/settings?key=${key}&value=${value}&scanType=""`,
  updateSingleScanSettings: (programId: number, key: string, value: boolean | string) =>
    `/entity/program/${programId}/settings?key=${key}&value=${false}&scanType=${value}`,
  updateProgramDetails: (programId: number) => `/entity/program/${programId}`,
  getUserRolesProgram: () => `/entity/user/program-entity/roles`,
  getUserList: (programId: number) => `/entity/users/program/${programId}`,
  addProgramMember: () => `/entity/program/add-roles`,
  deactivateMember: (programId: string, userId: string, role: string) =>
    `/entity/program/${programId}/roles/${userId}/deactivate/${role}`,
  activateMember: (programId: string, userId: string, role: string) =>
    `/entity/program/${programId}/roles/${userId}/reactivate/${role}`,
  activateDetactivateProgram: (programId: string, deleted: boolean) =>
    `/entity/program/${programId}/status/${deleted}`,
  getPresignedUrl: (type: string, name: string) =>
    `/user/file/download?appType="pda"&fileType=${type}&fileName=${name}`,
  getSelectedMemberRole: (userId: number, programId: number) =>
    `/entity/program/${programId}/roles/user/${userId}`,
  getLicenseAndPricing: (programId: number) => `/entity/license/model/program/${programId}`,
  getLicenseAndPricingAll: (licenseId: number) => `/entity/license/model/${licenseId}/transactions`,
  getLicenseAndPricingCredits: (licenseId: number) => `/entity/license/model/${licenseId}/credits`,
  getLicenseAndPricingDebits: (licenseId: number) => `/entity/license/model/${licenseId}/debits`,
  addLicenseModel: () => `entity/license/model`,
  addLicensePurchase: () => `entity/license/model/credit`,
  updateLicenseModal: (licenseId: number) => `/entity/license/model/${licenseId}`,
  getLicenseDebitCsv: (licenseId: number) => `/entity/license/model/debit/${licenseId}/csv`,
  getExternalReportsAccess: (entityId: number) => `access/client/entity/${entityId}`,
  deleteSelectedLicense: (licenseId: number) => `entity/license/model/${licenseId}`
};

export const verifyEndPoints = {
  getRolesVerification: (programId: number) => `/user/role/list/${programId}`,
  getQualificationVerification: (programId: number) => `/user/qualification/list/${programId}`,
  approveRejectRole: () => `/user/role/approve`,
  approveRejectQualification: () => `/user/qualification/approve`,
  getTaskVerification: () => `taskservice/api/v1/submissions/verification/list`,
  updateTaskVerification: () => `taskservice/api/v1/task/submit/update/status`
};

export const badgesEndPoints = {
  getBadgesTable: (programId: number) => `/badge/program?id=${programId}`,
  getBadges: (badgeClassTypeId: number) => `badge/badge-class/${badgeClassTypeId}`,
  addBadge: () => `/badge/add`,
  addBadgeToType: () => `/badge/add/v2`,
  updateBadge: () => `/badge/update/v2`,
  deleteBadge: (badgeId: number, value: boolean) =>
    `badge/status/${badgeId}?activateBadge=${value}`,
  activateReactivateBadge: (badgeId: number) => `badge/status/${badgeId}`,
  getBadgeUsersList: (badgeId: number) => `badge/es/assign/list?badgeId=${badgeId}`,
  previewBadgesUsers: () => `badge/assign/preview`,
  assignBadgesUsers: () => `badge/assign/add`,
  getAssigedBadgeUser: () => `badge/user/list`,
  unassignBadgeUsers: (programId: number) => `badge/assign/update/programId/${programId}`,
  downloadBadgeCsvList: (id: string) => `/badge/user/list/csv?badgeId=${id}`,
  getBadgeType: () => `badge/type`,
  getAllBadgeTypes: (programId: number) => `badge/class/program/${programId}`,
  addBadgeType: () => `badge/class`,
  publishBadgeType: (badgeTypeId: number) => `badge/class/${badgeTypeId}/publish`,
  deactivateBadgeType: (badgeTypeId: number) => `badge/class/${badgeTypeId}/deactivate`,
  activateBadgeType: (badgeTypeId: number) => `badge/class/${badgeTypeId}/activate`,
  updateBadgeType: (badgeTypeId: number) => `badge/class/${badgeTypeId}`,
  deleteSelectedBadge: (badgeId: number) => `badge/${badgeId}`,
  getFilterLocation: (programId: number) => `telemetry/es/program/${programId}/location-details`
};

export const qualificationPackEndPoints = {
  getQualificationPackTable: (programId: number) => `/bundle/program/${programId}`,
  addQualificationPack: () => `/bundle`,
  getBundleTopicList: () => `/entity/topic-details/metrics/v2`,
  updateQualificationPack: (bundleId: number) => `/bundle/${bundleId}`,
  getQualificationPackDetails: (bundleId: number) => `/bundle/es/${bundleId}/report/v2`,
  getQualificationPackBundleDetails: (bundleId: number) => `/bundle/${bundleId}/topic`,
  getQualificationParticipantMapping: (bundleId: number, selectionNo: number) =>
    `bundle/es/${bundleId}/${selectionNo}/report/v2`,
  getFilterLocations: (programId: number) => `telemetry/es/program/${programId}/location-details`,
  getBadgeRoles: () => `badge/program`,
  getParticipantMappingCSV: (bundleId: number, selectionNo: number) =>
    `/bundle/es/${bundleId}/${selectionNo}/report/csv`,
  getQPdetails: (programId: string, userId: string) =>
    `bundle/es/program/${programId}/user/${userId}`,
  getRoleBadges: (id: string) => `badge/program?id=${id}`,
  publishBadge: (qpId: string) => `bundle/${qpId}/publish`,
  getTags: (qpId: string) => `bundle/${qpId}/tags`,
  getScannerData: (bundleId: string) => `bundle/key/${bundleId}`,
  getQpUserDetails: (bundleId: string, userId: string) =>
    `bundle/es/bundle/${bundleId}/user/${userId}`,
  getQPAccessStatus: (bundleId: string) => `bundle/es/sync?qpId=${bundleId}`,
  getAccomplishmentQPdetails: (programId: string) => `bundle/es/self-learning/program/${programId}`
};

export const dashboardEndPoints = {
  getSessionsInfo: (programId: number) =>
    `/session/program/dashboard/metrics/count?programId=${programId}`,
  getBadgesInfo: (programId: number) => `/badge/private/metrics?programId=${programId}`,
  getRolesAndQualificationsInfo: () => `/entity/verification/metrics/count`,
  getQpInfo: (programId: number) => `/bundle/program/${programId}/matrix`,
  setToken: () => `/setToken`
};

export const sessionPackEndPoints = {
  getSessionDetails: () => `session/session-details/metrics/v3`,
  getCompletedSessionDetails: () => `telemetry/session-details/metrics/v3`,
  updateSessionDetails: () => `session/create`,
  getCompleteSessionDetails: (sessionId: number) =>
    `/session/get-complete-session-information/${sessionId}`,
  addSessionAddtionalLinks: (sessionId: number) => `/session/link/${sessionId}`,
  deleteAdditionalLink: (sessionId: number, linkId: number) =>
    `/session/link/${sessionId}/${linkId}`,
  addMemberToSession: () => `/session/member/add`,
  deleteSession: (sessionId: number) => `/session/delete/${sessionId}`,
  editSessionDetails: () => `/session/update`,
  getSessionParticipationList: () => `/session/user-role/details`,
  deleteSessionMember: (userId: string, sessionId: string) =>
    `session/member/remove?userId=${userId}&sessionId=${sessionId}`,
  updateSessionMember: () => `session/member/update`,
  attestationDetails: (sessionId: string, userId: string, role: string) =>
    `/session/get-attestation-details?role=${role}&sessionId=${sessionId}&userId=${userId}`
};

export const participationDetailsEndPoints = {
  getParticipantList: () => `session/user-role/details`,
  getTrainersList: () => `session/user-role/details`,
  getParticipantTrainerList: () => `telemetry/elastic/attestation/participants`,
  downloadCsv: () => `telemetry/elastic/attestation/participants-csv`,
  getParticipantAttestationList: (programId: string) =>
    `telemetry/telemetry/program/${programId}/attestation-details`,
  getParticipantAttestationList2: () => `telemetry/elastic/attestation/participants/detail`,
  getTrainerAttestationList: (programId: string) =>
    `telemetry/telemetry/program/${programId}/attestation-details`,
  getParticipantTrainerAttestationList: () => `telemetry/elastic/attestation/participants/detail`,
  getTrainerByTopicList: (programId: string) =>
    `/telemetry/trainers-for-topics/program/${programId}`,
  getParticipantListCSV: () => `/session/user-role/details/csv`,
  getTrainersListCSV: () => `/session/user-role/details/csv`,
  getParticipantAttestationListCSV: (programId: string) =>
    `telemetry/program/${programId}/csv/attestation-details`,
  getParticipantTrainerAttestationListCSV: () =>
    `telemetry/elastic/attestation/participants/detail-csv`,
  getTrainerAttestationListCSV: (programId: string) =>
    `/telemetry/program/${programId}/csv/attestation-details`,
  getTrainerByTopicListCSV: (programId: string) =>
    `/telemetry/trainers-for-topics/program/${programId}/csv`,
  getFilterLocation: (programId: number) => `telemetry/es/program/${programId}/location-details`
};

export const accomplishments = {
  getAtendanceList: (programId: string, userId: string) =>
    `/session/es/program/${programId}?userId=${userId}`,
  getDocumentPresignedUrl: (type: string, name: string, bucketname: string, opType: string) =>
    `/user/file/download?fileType=${type}&fileName=${name}&bucketName=${bucketname}&opType=${opType}`,
  getAllProgramsByUsers: () => `session/es/attestation/user/program-list`,
  getArtefacts: () => `api/v1/artefact/list`,
  getArtefactsHistory: (artefact_meta_id: string) => `api/v1/workflowhistory/${artefact_meta_id}`
};

export const registryEndPoints = {
    // Entity 
    getEntities: () => `/registry/api/v1/entity/list`,
    registerEntity: () => `/registry/api/v1/entity/create`,
    updateEntity:(entityId: string) => `/registry/api/v1/entity/update/${entityId}`,
    readEntity:(entityId: string) => `/registry/api/v1/entity/read/${entityId}`,
    deactivateEntity:(entityId: string) => `/registry/api/v1/entity/deactivate/${entityId}`,
    activateEntity:(entityId: string) => `/registry/api/v1/entity/activate/${entityId}`,
    // Docs
    uploadFile: () => `/registry/api/v1/doc/upload/file`,
    listDocs: () => `/registry/api/v1/doc/list`,
    addDoc: () => `/registry/api/v1/doc/add`,
    deleteDoc: () => `/registry/api/v1/doc/delete`,
    // Admin
    entityAdminsList : () => `/registry/api/v1/entity/admin/list`,
    addEntityAdmin: () => `/registry/api/v1/entity/admin/add`,
    activateEntityAdmin: (adminId: string) =>  `/registry/api/v1/entity/admin/activate/${adminId}`,
    deactivateEntityAdmin: (adminId: string) =>  `/registry/api/v1/entity/admin/deactivate/${adminId}`,
    // Programs
    entityProgramsList: () => `/registry/api/v1/entity/program/list`,
    createEntityProgram: () => `/registry/api/v1/entity/program/create`,
    updateEntityProgram: (programId: string) => `/registry/api/v1/entity/program/update/${programId}`,
    activateEntityProgram: (programId: string) => `/registry/api/v1/entity/program/activate/${programId}`,
    deactivateEntityProgram: (programId: string) => `/registry/api/v1/entity/program/deactivate/${programId}`,
    // ProgramPartner
    programPartnersList: (programId: string) => `/registry/api/v1/entity/program/partner/list/${programId}`,
    addProgramPartner: () => `/registry/api/v1/entity/program/partner/add`,
    deactivateProgramPartner:(partnerId: string) => `/registry/api/v1/entity/program/partner/deactivate/${partnerId}`,
    activateProgramPartner:(partnerId: string) => `/registry/api/v1/entity/program/partner/activate/${partnerId}`,
    // EntityPartners
    entityPartnersList: (entityId: string) => `/registry/api/v1/entity/partners/list/${entityId}`,

}
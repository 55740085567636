import { FontFamily } from "@socion-cordio/common/src/components/atoms/text";
import React, { ReactElement } from "react";
import { StyleSheet, View, StyleProp, ViewStyle } from "react-native";
import { useField } from "formik";
import { FontWeight, TextSize, Text } from "@socion-cordio/common/src/components/atoms/text";
import styled from "styled-components";

const Styles = styled.div`
  input:focus {
    outline: 0;
  }
`;
interface IProps {
  // datePickerStyle?: StyleProp<ViewStyle>;
  min?: string;
  max?: string;
  onChange?: (value: any) => void;
  value?: string;
  name: string;
  id?: string;
  handleBlur?: Function;
  noFormik?: boolean;
  isDisabled?: boolean;
  inputStyle?: boolean;
}

export default function DatePicker(props: IProps): ReactElement {
  const {
    // datePickerStyle,
    min,
    max,
    onChange,
    value,
    name,
    id,
    handleBlur,
    noFormik,
    isDisabled = false,
    inputStyle
  } = props;

  // const [field, meta] = useField(props);
  // const hasError = meta.touched && meta.error;
  // const error = meta.error;
  let field, meta, hasError, error;
  if (!noFormik) {
    [field, meta] = useField(props);
    hasError = meta.touched && meta.error;
    error = meta.error;
  }

  return (
    <>
      <Styles>
        <input
          style={inputStyle ? {
            borderRadius: 5,
            padding: 8,
            fontFamily: FontFamily.Medium,
            fontSize: 12,
            borderColor: "#c2bcb9",
            borderStyle: "solid",
            borderWidth: 1,
            height: 40,
            width: "100%"
          } : {
            borderRadius: 5,
            padding: 8,
            fontFamily: FontFamily.Medium,
            fontSize: 12,
            borderColor: "#c2bcb9",
            borderStyle: "solid",
            borderWidth: 1,
            height: 40
          }}
          disabled={isDisabled}
          type="date"
          min={min}
          max={max}
          onChange={onChange}
          value={value}
          name={name}
          id={id}
        />
      </Styles>
      {error ? (
        <Text
          testId={id}
          textSize={TextSize.Small}
          fontWeight={FontWeight.Regular}
          textStyle={[styles.error]}
        >
          {error}
        </Text>
      ) : null}
    </>
  );
}

const styles = StyleSheet.create({
  error: {
    position: "relative",
    color: "red",
    alignSelf: "flex-start",
    fontSize: 10,
    fontWeight: "400",
    zIndex: -1
  },
});

import React, { useEffect, useMemo, useState } from "react";
import { View, StyleSheet, TouchableOpacity } from "react-native";
import Table from "@socion-cordio/web/src/components/molecules/table/table";
import {
  Text,
  TextSize,
  FontWeight,
  FontFamily
} from "@socion-cordio/common/src/components/atoms/text";
import { dataHelper } from "@socion-cordio/common/src/utils/dataHelper";
import { colorPallete } from "@socion-cordio/common/src/assets/styles/colors";
import Loader from "@socion-cordio/common/src/components/atoms/loader";
import { useLocation, useHistory, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Image } from "@socion-cordio/common/src/components/atoms/image";
import SocionModal from "@socion-cordio/common/src/components/atoms/modal";
import SearchFilterSeondary from "@socion-cordio/common/src/components/molecules/searchFilterSecondary";
import { allRoutesNames as routeNames } from "@socion-cordio/web/src/navigation/allRouteNames";
import _ from "lodash";
import { TABS, REGISTRY_TYPE } from "@socion-cordio/common/src/constants/registry";
import {
  dispatchEntityPrograms,
  getSelectedEntityDetails
} from "@socion-cordio/common/src/utils/registryHelpers/entityHelper";
import CreateProgramModal from "@socion-cordio/common/src/components/organisms/registry/entity/createProgramModal";
import { UserHelper } from "@socion-cordio/common/src/utils/userHelper";
import { ProgramsRepository } from "@socion-cordio/common/src/repositories/registry/entity/programs";
import RemoveLinkModal from "@socion-cordio/common/src/components/organisms/common-modals/removeLinkModal";

interface IProps {}

const Programs = (props: IProps) => {
  const [isLoading, setIsLoading] = useState(true);
  const [showCreateProgramModal, setShowCreateProgramModal] = useState(false);
  const [showRemoveProgramModal, setShowRemoveProgramModal] = useState(false);
  const [selectedProgram, setSelectedProgram] = useState(null);
  const [status, setStatus] = useState(null);
  const history = useHistory();
  const [showList, setShowList] = useState(null);
  const [entity, setEntity] = useState(null);
  const dispatch = useDispatch();
  const [programsList, setProgramsList] = useState(null);

  const {
    programs: programs,
    loading: { getPrograms: loading },
    error: { getPrograms: error }
  } = useSelector((state: any) => state.entities);

  useEffect(() => {
    setIsLoading(true);
    dispatchEntityPrograms(dispatch);
    getSelectedEntityData();
  }, []);

  const getSelectedEntityData = async () => {
    const selectedEntity = await getSelectedEntityDetails();
    setEntity(selectedEntity);
  }

  useEffect(() => {
    setIsLoading(loading);
  }, [loading]);

  useEffect(() => {
    if (error) {
      setIsLoading(false);
      setShowList(false);
      setProgramsList([]);
    }
  }, [error]);

  useEffect(() => {
    if (programs !== null) {
      setShowList(programs.length > 0);
      setProgramsList(programs);
      setIsLoading(false);
    }
  }, [programs]);

  const toggleActivate = async (program: any, activate?: boolean) => {
    const payload = {
      parentId: program?.parentId,
      parentType: program?.parentType,
      updatedBy: UserHelper.getUserName(),
      updatedById: UserHelper.getUserId()
    };

    const res = await ProgramsRepository.toggleEntityProgram(
      payload,
      program?.programId,
      !activate
    ).catch((err) => {
      console.log("Error", error);
      setShowRemoveProgramModal(false);
    });
    if (res) {
      setShowRemoveProgramModal(false);
      let data = [...programsList];
      data = data.map((pgm) => {
        if (pgm?.programId === program?.programId) {
          pgm.active = !activate;
        }
        return pgm;
      });
      setProgramsList(data);
    }
  };

  const cellData = (testId: string, style: any, data: string, onPress?: Function) => (
    <Text
      fontWeight={FontWeight.Bold}
      testId={testId}
      textSize={TextSize.Small}
      style={[
        {
          fontSize: 12,
          fontFamily: FontFamily.Regular,
          textSize: TextSize.Small,
          fontWeight: FontFamily.Light
        },
        style
      ]}
      onPress={onPress ? () => onPress() : null}
    >
      {data}
    </Text>
  );

  const columns = useMemo(
    () => [
      {
        Header: "Sr.No",
        accessor: "serialNo",
        width: 40,
        maxWidth: 40,
        disableSortBy: true,
        Cell: ({ row: { original: data } }: { row: { original: any } }) => {
          let style = {};
          if (!data.active) {
            style = {
              ...style,
              opacity: 0.6
            };
          }
            style = {
              ...style,
              fontFamily: FontFamily.Medium,
              fontWeight: FontFamily.Bold
            };
          

          return cellData("serialNo", style, data.serialNo);
        }
      },
      {
        Header: "Name",
        accessor: "name",
        width: 300,
        maxWidth: 300,
        disableSortBy: true,
        Cell: ({ row: { original: data } }: { row: { original: any } }) => {
          let style = { };
          if (!data.active) {
            style = {
              ...style,
              opacity: 0.6,
            };
          }
            style = {
              ...style,
              textDecorationLine: "underline",
              fontFamily: FontFamily.Medium,
              fontWeight: FontFamily.Bold
            };
          
          const onPress = () => {
            const url = routeNames.app + routeNames.PROGRAM_DETAILS + "/" + data?.programId;
            localStorage.setItem("selectedEntityProgram", JSON.stringify(data));
            history.push(url, {
              programData: data
            });
          };
          return cellData("name", style, data?.name, onPress);
        }
      },
      {
        Header: "Start Date",
        accessor: "startDate",
        width: 80,
        maxWidth: 80,
        disableSortBy: true,
        Cell: ({ row: { original: data } }: { row: { original: any } }) => {
          let style = {};
          if (!data.active) {
            style = {
              ...style,
              opacity: 0.6
            };
          } 
            style = {
              ...style,
              fontFamily: FontFamily.Medium,
              fontWeight: FontFamily.Bold
            };
          
          const date = data?.startDate ? dataHelper.formatDate(data?.startDate) : "";
          return cellData("startDate", style, date);
        }
      },
      {
        Header: "End Date",
        accessor: "endDate",
        width: 80,
        maxWidth: 80,
        disableSortBy: true,
        Cell: ({ row: { original: data } }: { row: { original: any } }) => {
          let style = {};
          if (!data.active) {
            style = {
              ...style,
              opacity: 0.6
            };
          } 
            style = {
              ...style,
              fontFamily: FontFamily.Medium,
              fontWeight: FontFamily.Bold
            };
          
          const date = data?.startDate ? dataHelper.formatDate(data?.endDate) : "";
          return cellData("endDate", style, date);
        }
      },
      {
        Header: "Status",
        accessor: "status",
        width: 80,
        maxWidth: 80,
        disableSortBy: true,
        Cell: ({ row: { original: data } }: { row: { original: any } }) => {
          let style = {};
          if (!data.active) {
            style = {
              ...style,
              opacity: 0.6
            };
          } 
            style = {
              ...style,
              fontFamily: FontFamily.Medium,
              fontWeight: FontFamily.Bold
            };
          
          return cellData("status", style, data?.status);
        }
      },
      {
        Header: "Partners",
        accessor: "partnerCount",
        width: 60,
        maxWidth: 60,
        disableSortBy: true,
        Cell: ({ row: { original: data } }: { row: { original: any } }) => {
          let style = {};
          if (!data.active) {
            style = {
              ...style,
              opacity: 0.6
            };
          } 
            style = {
              ...style,
              textDecorationLine: "underline",
              fontFamily: FontFamily.Medium,
              fontWeight: FontFamily.Bold
            };
          
          const onPress = () => {
            const url = routeNames.app + routeNames.PROGRAM + routeNames.PARTNERS;
            localStorage.setItem("selectedEntityProgram", JSON.stringify(data));
            history.push(url, {
              programData: data
            });
          };
          return cellData("partnerCount", style, data?.partnerCount, onPress);
        }
      },
      {
        Header: "Add/Remove",
        accessor: "active",
        width: 100,
        maxWidth: 100,
        disableSortBy: true,
        Cell: ({ row: { original: data } }: { row: { original: any } }) => {

          console.log("ebnrewbnrebrnew", entity);

          return (
            <View>
              {/* {entity?.active && (
                <> */}
                  {entity?.active && !data?.active && (
                    <TouchableOpacity
                      style={{ alignItems: "center", opacity: 0.6 }}
                      onPress={() => {
                        setSelectedProgram(data);
                        setStatus("activate");
                        setShowRemoveProgramModal(!showRemoveProgramModal);
                      }}
                    >
                      <Image
                        testId="secondaryAddImg"
                        source={require("@socion-cordio/common/src/assets/images/secondary_add.svg")}
                        imageStyle={{
                          width: 20,
                          height: 20
                        }}
                      />
                    </TouchableOpacity>
                  )}
                  {entity?.active && data?.active && (
                    <TouchableOpacity
                      style={{ alignItems: "center" }}
                      onPress={() => {
                        setStatus("deactivate");
                        setSelectedProgram(data);
                        setShowRemoveProgramModal(!showRemoveProgramModal);
                      }}
                    >
                      <Image
                        testId="remove"
                        source={require("@socion-cordio/common/src/assets/images/remove.svg")}
                        imageStyle={{
                          width: 20,
                          height: 20
                        }}
                      />
                    </TouchableOpacity>
                  )}
                {/* </> */}
              {/* )}  */}
            </View>
          );
        }
      }
    ],
    [entity]
  );

  const getCreateProgramModal = () => {
    return (
      <View>
        <SocionModal
          modalVisible={showCreateProgramModal}
          setModalVisible={() => setShowCreateProgramModal(!showCreateProgramModal)}
          component={
            <CreateProgramModal
              //   payload={entity ? { entityId: entity?.entityId } : {}}
              parent={entity}
              parentType={REGISTRY_TYPE.entity}
              onClose={() => {
                setShowCreateProgramModal(false);
              }}
              dispatchEvent={(type: string) => {
                if (type.toLowerCase() === REGISTRY_TYPE.entity) {
                  dispatchEntityPrograms(dispatch);
                }
              }}
              //   onSuccess={() => {
              //     setShowCreateProgramModal(false);
              //     dispatchEntityAdmins(dispatch);
              //   }}
            />
          }
        />
      </View>
    );
  };

  const getRemoveProgramModal = () => {
    return (
      <View>
        <SocionModal
          modalVisible={showRemoveProgramModal}
          setModalVisible={() => setShowRemoveProgramModal(!showRemoveProgramModal)}
          component={
            <RemoveLinkModal
              modalVisible={showRemoveProgramModal}
              selectedData={() => {
                toggleActivate(selectedProgram, selectedProgram?.active);
              }}
              textValue={
                selectedProgram?.active
                  ? "Are you sure you want to deactivate this Partner?"
                  : "Are you sure you want to reactivate this Partner?"
              }
              deleteButtonText={selectedProgram?.active ? "Deactivate" : "Reactivate"}
              setModalVisible={() => setShowRemoveProgramModal(!showRemoveProgramModal)}
            />
          }
        />
      </View>
    );
  };

  const getPageTitle = () => {
    return (
      <View>
        <Text
          fontWeight={FontWeight.Bold}
          testId="headerText"
          textSize={TextSize.Small}
          textStyle={[styles.headerText, styles.miniContainerHeaderText]}
        >
          Programs List
        </Text>
      </View>
    );
  };
  return (
    <View style={styles.mainContainer}>
      <View style={styles.subContainer}>
        {isLoading ? (
          <Loader />
        ) : (
          <>
            <View style={styles.dataContainer}>
              {getPageTitle()}
              {showList !== null && !showList && (
                <>
                  <View style={styles.search}>
                    <View style={styles.searchContainer}>
                      <SearchFilterSeondary
                        // customWidthStyle={styles.searchContainer}
                        editable={true}
                        placeholder={"Search Global Entity Registry"}
                      />
                    </View>
                    {entity?.active && (
                      <TouchableOpacity
                        onPress={() => setShowCreateProgramModal(!showCreateProgramModal)}
                      >
                        <Image
                          testId="primaryAddImg"
                          source={require("@socion-cordio/common/src/assets/images/primary_add.svg")}
                          imageStyle={{
                            width: 30,
                            height: 30
                          }}
                        />
                      </TouchableOpacity>
                    )}
                  </View>
                  <View>
                    <Text
                      fontWeight={FontWeight.Bold}
                      testId="headerText"
                      textSize={TextSize.Small}
                      textStyle={[styles.headerText, styles.miniContainerHeaderText]}
                    >
                      No Programs Available
                    </Text>
                  </View>
                </>
              )}
              {showList && (
                <View>
                  <Table
                    columns={columns}
                    data={programsList}
                    showSearchField={true}
                    customSearchBarContainerStyle={{ marginLeft: -20 }}
                    paginationAndSearch={true}
                    addIcon={
                      entity?.active && (
                        <TouchableOpacity
                          onPress={() => setShowCreateProgramModal(!showCreateProgramModal)}
                        >
                          <Image
                            testId="primaryAddImg"
                            source={require("@socion-cordio/common/src/assets/images/primary_add.svg")}
                            imageStyle={{
                              width: 30,
                              height: 30
                            }}
                          />
                        </TouchableOpacity>
                      )
                    }
                  />
                </View>
              )}
            </View>

            {showCreateProgramModal && getCreateProgramModal()}
            {showRemoveProgramModal && getRemoveProgramModal()}
          </>
        )}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  mainContainer: {
    padding: 20,
    paddingTop: 18,
    backgroundColor: colorPallete.cordioBeigeLight2,
    minHeight: "calc(100vh - 50px)"
  },
  subContainer: {
    padding: 18,
    borderRadius: 10,
    backgroundColor: colorPallete.white,
    shadowColor: colorPallete.cordioRedDark1,
    shadowOpacity: 0.1,
    shadowOffset: {
      height: 10,
      width: 5
    },
    shadowRadius: 10
  },

  textStyle: {
    fontSize: 12
  },
  dataContainer: {
    padding: 18,
    marginBottom: 180
  },
  search: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginVertical: 25,
    width: "100%"
  },
  searchContainer: {
    width: 535
  },

  headerText: {
    color: colorPallete.textLight,
    fontSize: 12,
    fontFamily: FontFamily.Regular,
    lineHeight: 17
  },
  miniContainerHeaderText: {
    color: colorPallete.textBlack,
    fontSize: 12,
    fontFamily: FontFamily.Medium,
    lineHeight: 20
  }
});

export default Programs;

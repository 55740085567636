import React, { ReactElement, useEffect, useState } from "react";
import { StyleSheet, View } from "react-native";
import {
  Text,
  TextSize,
  FontWeight,
  FontFamily
} from "@socion-cordio/common/src/components/atoms/text";
import Icon, { IconNames } from "@socion-cordio/common/src/components/atoms/icon";
import Button, { ButtonType } from "@socion-cordio/common/src/components/atoms/button";
import UserInputTextArea from "@socion-cordio/common/src/components/molecules/userInputTextArea";
import { colorPallete } from "@socion-cordio/common/src/assets/styles/colors";
import { toast } from "react-toastify";
import Loader from "@socion-cordio/common/src/components/atoms/loader";
import Select from "react-select";
import { auxiliaryMethods } from "@socion-cordio/common/src/utils/auxiliaryMethods";
import { useDispatch, useSelector } from "react-redux";
import { find } from "lodash";
import { REGISTRY_TYPE } from "@socion-cordio/common/src/constants/registry";
import { UserHelper } from "@socion-cordio/common/src/utils/userHelper";
import { dispatchEntityProgramPartners, EntityHelper } from "@socion-cordio/common/src/utils/registryHelpers/entityHelper";
import DatePicker from "@socion-cordio/common/src/components/molecules/datePicker";
import { PartnersRepository } from "@socion-cordio/common/src/repositories/registry/entity/partners";
import { dataHelper } from "@socion-cordio/common/src/utils/dataHelper";
import { MESSAGES } from "@socion-cordio/common/src/constants/message";

interface Props {
  onClose?: Function;
  extPartnersList?: any;
  type?: string;
  programData?: any;
}

export default function AddPartnerModal(props: Props): ReactElement {
  const dispatch = useDispatch();

  const [openDropdown, setOpenDropdown] = useState(true);
  const [emptyState, setEmptyState] = useState(false);
  const [partnerContribution, setPartnerContribution] = useState("");
  const [partnerSince, setPartnerSince] = useState("");
  const [entititesList, setEntititesList] = useState([]);
  const [showPartnerDetails, setShowPartnerDetails] = useState(false);
  const [partnerDetails, setPartnerDetails] = useState(null);
  const {
    entities: entities,
    loading: { getEntities: loading },
    error: { getEntities: error }
  } = useSelector((state: any) => state.entities);

  useEffect(() => {
    if (entities!==null && props?.type === "entity") {
      entities && getEntitiesList();
    }
  }, [entities]);

  const getEntitiesList = () => {
    const listData = entities.map((list: any, index: number) => ({
      value: list.entityId,
      label: list.name
    }));

    let result: any = [];
    const exPartnerIds: any[] = props.extPartnersList.map((par: any) => par?.partnerId);
    exPartnerIds.push(EntityHelper.getSelectedEntityId());
    result = listData.filter((item: any) => !exPartnerIds.includes(item?.value));
    // have to filter the existing  entities
    setEntititesList(result);
  };

  const handleDropdown = async (item: any, e: any) => {
    // setTemp(true);
    setOpenDropdown(false);
    if (item !== null) {
      const entityId = item.value;
      setShowPartnerDetails(true);
      const entity = find(entities, { entityId: entityId });
      if (entity) {
        setPartnerDetails(entity);
      }
    } else {
    }
    setOpenDropdown(true);
  };

  const submitHandler = async () => {
    try {
      props.onClose();
      const request = {
        partnerId: partnerDetails?.entityId,
        name: partnerDetails?.name,
        role: partnerContribution,
        partnerType: props?.programData?.parentType,
        partnerSince: partnerSince,
        entityId: props?.programData?.parentId,
        programId: props?.programData?.programId,
        createdBy: UserHelper.getUserName(),
        userId: UserHelper.getUserId()
      };

      const partnerResponse = await PartnersRepository.createEntityProgramPartner(request).catch(
        (err) => {
          console.log("partnerResponsepartnerResponse", err);
          props.onClose();
          toast.error(
            dataHelper.replaceText(MESSAGES.ERROR.CREATE, {
              ["{type}"]: "Partner",
              ["{name}"]: request?.name
            })
          );
        }
      );
        
      if (partnerResponse) {
        toast.success(
          dataHelper.replaceText(MESSAGES.SUCCESS.CREATE, {
            ["{type}"]: "Partner",
            ["{name}"]: request?.name
          })
        );
        dispatchEntityProgramPartners(dispatch);
      }
      console.log("partnerResponse", partnerResponse);
    } catch (error) {
      // setLoading(false);
      toast.error("Something went wrong");
    }
  };

  return (
    <View>
      {loading ? (
        <Loader customLoadingContainer={styles.customLoadingContainer} />
      ) : (
        <View style={styles.container}>
          <View style={styles.header}>
            <Text
              fontWeight={FontWeight.Bold}
              testId="addRoleText"
              textSize={TextSize.Small}
              textStyle={styles.headerText}
            >
              Add Partner
            </Text>
            <View>
              <Icon
                testID="close"
                name={IconNames.crossCircle}
                customStyle={styles.crossCircle}
                onPress={() => props.onClose()}
              />
            </View>
          </View>
          <View style={[styles.mt32, styles.flexContainer]}>
            <View style={[styles.mt9, styles.mr14]}>
              <Text
                fontWeight={FontWeight.Regular}
                testId="selectPartner"
                textSize={TextSize.Small}
                textStyle={[styles.subHeader]}
              >
                Select Partner
              </Text>
            </View>
            <View style={styles.dropdownContainer}>
              <Select
                isClearable={true}
                // value={selectedBadges}
                removeSelected={true}
                hideSelectedOptions={true}
                onChange={(item, e) => [handleDropdown(item, e)]}
                placeholder={
                  <View style={{ display: "flex", flexDirection: "row" }}>
                    <Icon name={IconNames.search} customStyle={styles.iconStyle} />
                    <Text
                      fontWeight={FontWeight.Regular}
                      testId="qualificationTitleText"
                      textSize={TextSize.Small}
                      textStyle={styles.placeholderText}
                    >
                      Search
                    </Text>
                  </View>
                }
                options={entititesList}
                openMenuOnClick={false}
                isMulti={false}
                menuPosition={"fixed"}
                // styles={customStyles}
                components={{
                  DropdownIndicator: () => null,
                  IndicatorSeparator: () => null
                }}
                menuIsOpen={emptyState}
                onInputChange={(input: any) =>
                  input.length === 0
                    ? (setEmptyState(false), setShowPartnerDetails(false))
                    : setEmptyState(true)
                }
              />
            </View>
          </View>

          {showPartnerDetails && (
            <View style={styles.partnerDetailsBox}>
              <View style={{ paddingLeft: 10 }}>
                <Text
                  fontWeight={FontWeight.Medium}
                  testId="selectPartner"
                  textSize={TextSize.Small}
                  textStyle={[styles.entityName]}
                >
                  {partnerDetails?.name}
                </Text>
              </View>
              <View style={styles.addressBox}>
                <View style={{ paddingLeft: 10, width: "92%" }}>
                  <Text
                    fontWeight={FontWeight.Regular}
                    testId="selectPartner"
                    textSize={TextSize.Small}
                    textStyle={[styles.subHeader]}
                  >
                    {partnerDetails && partnerDetails.address}
                  </Text>
                  <Text
                    fontWeight={FontWeight.Regular}
                    testId="selectPartner"
                    textSize={TextSize.Small}
                    textStyle={[styles.subHeader]}
                  >
                    {partnerDetails && partnerDetails.address2}
                  </Text>
                  <Text
                    fontWeight={FontWeight.Regular}
                    testId="selectPartner"
                    textSize={TextSize.Small}
                    textStyle={[styles.subHeader]}
                  >
                    {partnerDetails && auxiliaryMethods?.fromatEntityLocation(partnerDetails)}
                  </Text>
                </View>
              </View>
            </View>
          )}

          <View style={styles.alignContainer}>
            <UserInputTextArea
              textValue="Contribution"
              label="Contribution"
              placeholder="Role assigned"
              name="Contribution"
              id="contribution"
              handleChange={(description: string) => {
                setPartnerContribution(description);
              }}
              handleBlur={() => {
              }}
              value={partnerContribution}
              numberOfLines={5}
              multiline={true}
              noFormik
              userStyle={styles.inputStylingAlignment}
              userStyleText={styles.textHeaderField}
            />
          </View>

          <View style={[styles.mt32, styles.flexContainer]}>
            <View style={[styles.mt9, styles.mr14]}>
              <Text
                fontWeight={FontWeight.Regular}
                testId="partnerSince"
                textSize={TextSize.Small}
                textStyle={[styles.subHeader]}
              >
                Partner Since
              </Text>
            </View>
            <View style={styles.dropdownContainer}>
              <DatePicker
                handleBlur={() => {}}
                onChange={(partnerDate: any) => {
                  setPartnerSince(partnerDate?.target?.value);
                }}
                value={partnerSince}
                name="partnerSince"
                id="partnerSince"
                inputStyle={true}
                noFormik
              />
            </View>
          </View>

          <View style={[styles.alignButtonContainer]}>
            <Button
              type={ButtonType.Primary}
              buttonStyles={styles.submitbutton}
              textStyles={styles.submitButtonText}
              title="Add"
              onPress={() => submitHandler()}
              disabled={partnerDetails === null}
            />
          </View>
        </View>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    width: 600
  },
  customLoadingContainer: {
    height: 350,
    width: 655
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row"
  },
  headerText: {
    fontSize: 12,
    fontFamily: FontFamily.Medium,
    lineHeight: 17
  },

  crossCircle: {
    fontSize: 15
  },
  alignContainer: {
    marginTop: 15,
    zIndex: -1
  },

  textHeaderField: {
    flex: 15,
    fontSize: 12
  },
  inputStylingAlignment: {
    width: "100%",
    fontSize: 12,
    padding: 12
  },



  alignButtonContainer: {
    marginTop: 30,
    marginBottom: 30,
    justifyContent: "center",
    alignSelf: "center",
    zIndex: -1
  },
  submitbutton: {
    width: "140px",
    height: "50px",
    borderRadius: 10
  },
  submitButtonText: {
    fontSize: 12
  },


  placeholderText: {
    color: colorPallete.textLight,
    fontSize: 12,
    fontFamily: FontFamily.Medium
  },
  dropdownContainer: {
    width: "82%"
  },
  mt9: {
    marginTop: 9
  },
  mr14: {
    marginRight: 20
  },
  subHeader: {
    color: colorPallete.textLight,
    fontSize: 12,
    fontFamily: FontFamily.Regular
  },

  entityName: {
    color: colorPallete.textBlack,
    fontSize: 14,
    fontFamily: FontFamily.Medium
  },
  mt32: {
    marginTop: 32
  },
  flexContainer: {
    display: "flex",
    flexDirection: "row"
  },
  iconStyle: {
    marginRight: 5,
    opacity: 0.5,
    fontSize: 12,
    marginTop: 6
  },
  partnerDetailsBox: {
    display: "flex",
    flexDirection: "column",
    marginLeft: 108,
    borderColor: colorPallete.cordioTaupeLight1,
    borderStyle: "solid",
    borderWidth: 1,
    borderRadius: 6,
    marginTop: 16
  },
  addressBox: {
    display: "flex",
    flexDirection: "row",
    width: "60%",
    marginBottom: 20
  }
});

import React, { useEffect, useMemo, useState } from "react";
import { View, StyleSheet } from "react-native";
import {
  Text,
  TextSize,
  FontWeight,
  FontFamily
} from "@socion-cordio/common/src/components/atoms/text";
import { colorPallete } from "@socion-cordio/common/src/assets/styles/colors";

import Button, { ButtonType } from "@socion-cordio/common/src/components/atoms/button";
interface IProps {
  isSuperAdmin: Boolean;
  onClick: Function;
}

const CreateEntityBtn = (props: IProps) => {

  return (
    <>
      <View style={styles.container}>
        <View>
          <View style={styles.noQualifications}>
            <Text
              fontWeight={FontWeight.Regular}
              testId="createEntity"
              textSize={TextSize.Small}
              style={styles.noContent}
            >
              {`Complete the registration of your entity`}
            </Text>
          </View>

          {
            <View style={styles.buttonContainer}>
              <Button
                type={ButtonType.Primary}
                buttonStyles={styles.button}
                title="Create Entity"
                onPress={() => props.onClick()}
                textStyles={{ fontSize: 12 }}
              />
            </View>
          }
        </View>
      </View>
    </>
  );
};

export default CreateEntityBtn;
const styles = StyleSheet.create({
  container: {
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: colorPallete.cordioOrange,
    // marginBottom: 100,
    borderRadius: 20,
    height: 330,
    backgroundColor: colorPallete.lightGrey,
    borderBottomLeftRadius: 20,
    borderBottomRightRadius: 20,
    justifyContent: "center"
  },

  noQualifications: {
    alignItems: "center",
    justifyContent: "center"
  },
  noContent: {
    fontSize: 14,
    textAlign: "center",
    fontFamily: FontFamily.Regular
  },
  button: {
    width: "140px",
    height: "50px",
    borderRadius: 10,
    marginLeft: 20,
    marginRight: 20
  },
  buttonContainer: {
    justifyContent: "center",
    alignItems: "center",
    marginTop: 20
  }
});

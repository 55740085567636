import React, { ReactElement, useState, useEffect } from "react";
import { StyleSheet, TouchableOpacity, View } from "react-native";
import { colorPallete } from "@socion-cordio/common/src/assets/styles/colors";
import {
  Text,
  TextSize,
  FontWeight,
  FontFamily
} from "@socion-cordio/common/src/components/atoms/text";
import Icon, { IconNames } from "@socion-cordio/common/src/components/atoms/icon";
import UserInputSecondary from "@socion-cordio/common/src/components/molecules/userInputSecondary";
import { dataHelper } from "@socion-cordio/common/src/utils/dataHelper";
import Loader from "@socion-cordio/common/src/components/atoms/loader";
import { toast } from "react-toastify";
import RemoveLinkModal from "@socion-cordio/common/src/components/organisms/common-modals/removeLinkModal";
import Documents from "@socion-cordio/common/src/components/organisms/entity/documents";
import SocionModal from "@socion-cordio/common/src/components/atoms/modal";
import { Image } from "@socion-cordio/common/src/components/atoms/image";
import FormFieldView from "@socion-cordio/common/src/components/molecules/formFieldView";
import TextArea from "@socion-cordio/common/src/components/atoms/textArea";
import { REGISTRY_TYPE } from "@socion-cordio/common/src/constants/registry";
import { EntityRepository } from "@socion-cordio/common/src/repositories/registry/entity/entity";
import { DocHelper } from "@socion-cordio/common/src/utils/registryHelpers/docHelper";
import { UserHelper } from "@socion-cordio/common/src/utils/userHelper";
import {
  dispatchEntityDetails,
  dispatchEntityList
} from "@socion-cordio/common/src/utils/registryHelpers/entityHelper";
import { useDispatch, useSelector } from "react-redux";
import { LocalStorage } from "@socion-cordio/common/src/services/storage/storageService";
import { MESSAGES } from "@socion-cordio/common/src/constants/message";

const entityState = {
  registrationNumber: "",
  taxRegNumber: "",
  address: "",
  address2: "",
  city: "",
  state: "",
  country: "",
  pinCode: "",
  name: "",
  noOfEmployees: "",
  websiteUrl: "",
  mission: "",
  logo: ""
};

export default function EntityDetails(): ReactElement {
  const dispatch = useDispatch();
  const hiddenFileInput = React.useRef(null);
  const [selectedEntity, setSelectedEntity] = useState(null);
  const [logoPreview, setLogoPreview] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [updateReq, setUpdateReq] = useState({});
  const [showRemoveModal, setShowRemoveModal] = useState(false);
  const [isEntityEnabled, setIsEntityEnabled] = useState<boolean>(false);
  const [updatedType, setUpdatedType] = useState(null);

  const [editable, setEditable] = useState({
    title: false,
    mission: false,
    details: false
  });

  const [isEdited, setIsEdited] = useState({
    title: false,
    mission: false,
    details: false
  });

  const [state, setState] = useState(entityState);

  const {
    entity: entity,
    loading: { getEntity: loading },
    error: { getEntity: error }
  } = useSelector((state: any) => state.entities);

  useEffect(() => {
    dispatchEntityDetails(dispatch);
  }, []);

  useEffect(() => {
    setIsLoading(true);
    if (entity != null) {
      setSelectedEntity(entity);
    }
  }, [entity]);

  useEffect(() => {
    setIsLoading(loading);
  }, [loading]);

  useEffect(() => {
    if (error) {
      setIsLoading(false);
      setSelectedEntity("Error");
    }
  }, [error]);

  useEffect(() => {
    if (selectedEntity !== null) {
      setIsEntityEnabled(selectedEntity?.active);
      setState({
        registrationNumber: selectedEntity?.registrationNumber || null,
        taxRegNumber: selectedEntity?.taxRegNumber || null,
        address: selectedEntity?.address || null,
        address2: selectedEntity?.address2 || null,
        city: selectedEntity?.city || null,
        state: selectedEntity?.state || null,
        country: selectedEntity?.country || null,
        pinCode: selectedEntity?.pinCode || null,
        name: selectedEntity?.name || null,
        noOfEmployees: selectedEntity?.noOfEmployees || null,
        websiteUrl: selectedEntity?.websiteUrl || null,
        mission: selectedEntity?.mission || null,
        logo: selectedEntity?.logo || null
      });
      setIsLoading(false);
    }
  }, [selectedEntity]);

  useEffect(() => {
    if (updatedType !== null) {
      validateDataChangeHandler(updatedType);
    }
  }, [updatedType, updateReq, logoPreview]);

  const handleOnClick = (event: any) => {
    if (editable.title) {
      const { target = {} } = event || {};
      target.value = "";
    }
  };

  const handleCustomChange = async (e: any) => {
    let fileNamesArray: any = [];
    if (fileNamesArray.includes(e.target.files[0].name)) {
      toast.error("Document already added");
      return;
    }
    const validTypes = ["png", "jpg", "jpeg", "svg"];
    const splitFileName = e?.currentTarget?.files[0]?.name.split(".");
    const extension = splitFileName[splitFileName.length - 1].toLowerCase();
    if (e.target.validity.valid && validTypes.includes(extension)) {
      setIsLoading(true);
      const { file, url } = await DocHelper.getCompressedFile(e?.target?.files[0]);
      setLogoPreview(url);
      handleChange(url, "logo", "title");
      setIsLoading(false);
    } else {
      toast.error(
        "Invalid File Type! Only Files with format of mp4, mpeg, mov, flv, png, jpg, jpeg, pdf will be uploaded!!"
      );
    }
  };

  const handleClick = (event: any) => {
    editable.title && hiddenFileInput.current.click();
  };

  const activateDeactivatEntityHandler = async () => {
    setShowRemoveModal(true);
  };

  const validateDataChangeHandler = (type: string) => {
    if (selectedEntity !== null) {
      switch (type) {
        case "title":
          const isSameName =
            state.name === selectedEntity?.name && state?.logo === selectedEntity?.logo;
          setIsEdited({ ...isEdited, title: !isSameName });
          break;
        case "mission":
          const isSameMission = state.mission === selectedEntity?.mission;
          setIsEdited({ ...isEdited, mission: !isSameMission });
          break;
        case "details":
          const isDetailsSame =
            state.registrationNumber === selectedEntity?.registrationNumber &&
            state.taxRegNumber === selectedEntity?.taxRegNumber &&
            state.address === selectedEntity?.address &&
            state.address2 === selectedEntity?.address2 &&
            state.city === selectedEntity?.city &&
            state.state === selectedEntity?.state &&
            state.country === selectedEntity?.country &&
            state.pinCode === selectedEntity?.pinCode &&
            state.websiteUrl === selectedEntity?.websiteUrl &&
            state.noOfEmployees === selectedEntity?.noOfEmployees;
          setIsEdited({ ...isEdited, details: !isDetailsSame });
          break;
      }
    }
  };
  const handleChange = (value: string, field: string, type: string) => {
    setState({
      ...state,
      [field]: value
    });
    setUpdatedType(type);
    setUpdateReq({
      ...updateReq,
      [field]: value
    });
  };

  const handleTick = (type: string) => {
    switch (type) {
      case "title":
        setEditable({ ...editable, title: !editable.title, mission: false, details: false });
        break;
      case "mission":
        setEditable({ ...editable, mission: !editable.mission, title: false, details: false });
        break;
      case "details":
        setEditable({ ...editable, details: !editable.details, title: false, mission: false });
        break;
    }
  };

  const handleClose = (type: string) => {
    switch (type) {
      case "title":
        setEditable({ ...editable, title: false });
        break;
      case "mission":
        setEditable({ ...editable, mission: false });
        break;
      case "details":
        setEditable({ ...editable, details: false });
        break;
    }
  };

  const handleSubmit = async (type: string) => {
    switch (type) {
      case "title":
        isEdited.title;
        if (state.name === "") {
          toast.error("Please Fill the Entity Name.");
          setEditable({ ...editable, title: true });
        } else {
          await updateEntity(updateReq, "title");
        }
        break;
      case "details":
        if (
          state.address === "" ||
          state.registrationNumber === "" ||
          state.city === "" ||
          state.country === "" ||
          state.pinCode === "" ||
          state.state === "" ||
          state.taxRegNumber === ""
        ) {
          toast.error("Please Complete All the Details.");
          setEditable({ ...editable, details: true });
        } else {
          await updateEntity(updateReq, "details");
        }
        break;
      case "mission":
        if (state.mission === "") {
          toast.error("Please fill the Mission");
          setEditable({ ...editable, mission: true });
        } else {
          await updateEntity({ mission: state.mission }, "mission");
        }
        break;
    }
  };

  const updateEntity = async (req: any, type: string) => {
    handleClose(type);
    req = {
      ...req,
      updatedBy: UserHelper.getUserName(),
      updatedById: UserHelper.getUserId()
    };
    const res = await EntityRepository.updateEntity(req, selectedEntity?.entityId).catch((err) => {
      console.log("Update Entity Error:", err);
      toast.error(
        dataHelper.replaceText(MESSAGES.ERROR.UPDATE, {
          ["{type}"]: "Entity",
          ["{name}"]: selectedEntity?.name
        })
      );
    });

    if (res) {
      const updateKeys = Object.keys(req);
      const entityData = selectedEntity;
      updateKeys.forEach((key) => {
        entityData[key] = req[key];
      });
      setSelectedEntity({ ...entityData });
      if (type === "title") {
        dispatchEntityList(dispatch);
      }
      toast.success(
        dataHelper.replaceText(MESSAGES.SUCCESS.UPDATE, {
          ["{type}"]: "Entity",
          ["{name}"]: selectedEntity?.name
        })
      );
    }
  };

  const handleActivateDeactivate = async () => {
    const payload = {
      updatedBy: UserHelper.getUserName(),
      updatedById: UserHelper.getUserId()
    };

    const res = await EntityRepository.toggleEntity(
      payload,
      selectedEntity?.entityId,
      !selectedEntity?.active
    ).catch((err) => {
      const msg = !selectedEntity?.active ? MESSAGES.ERROR.DEACTIVATE : MESSAGES.ERROR.ACTIVATE;
      toast.error(
        dataHelper.replaceText(msg, {
          ["{type}"]: "Entity",
          ["{name}"]: selectedEntity?.name
        })
      );
    });

    if (res) {
      const msg = !selectedEntity?.active ? MESSAGES.SUCCESS.DEACTIVATE : MESSAGES.SUCCESS.ACTIVATE;
      toast.success(
        dataHelper.replaceText(msg, {
          ["{type}"]: "Entity",
          ["{name}"]: selectedEntity?.name
        })
      );
      setShowRemoveModal(false);
      setIsEntityEnabled((isEntityEnabled: boolean) => isEntityEnabled);
      setSelectedEntity({ ...selectedEntity, active: !selectedEntity?.active });
      await LocalStorage.setStorage("selectedEntity", {
        ...selectedEntity,
        active: !selectedEntity?.active
      });
    }
  };

  const getLogo = () => {
    return (
      <TouchableOpacity onPress={handleClick}>
        <View style={styles.one}>
          <View style={styles.imageContainer}>
            <Image
              imageStyle={styles.profileImage}
              testId="socionImg"
              source={
                selectedEntity?.logo ||
                logoPreview ||
                require("@socion-cordio/common/src/assets/images/user_circle.svg")
              }
            />
          </View>
          {editable.title && (
            <View style={styles.uploadProfileIconContainer}>
              <View style={styles.uploadProfileIconSubContainer}>
                <Icon
                  name={IconNames.uploadProfile}
                  customStyle={[styles.iconStyle, styles.uploadIcon]}
                />
              </View>
            </View>
          )}
        </View>
        <input
          style={{
            display: "none",
            zIndex: -1
          }}
          ref={hiddenFileInput}
          type="file"
          onChange={async (e) => {
            await handleCustomChange(e);
          }}
          onClick={handleOnClick}
        />
      </TouchableOpacity>
    );
  };

  const getUserInputSecondary = (
    textVaue: string,
    value: any,
    isEditable: any,
    field: string,
    valueType: string
    // style: any
  ) => {
    const userStyle =
      valueType === "title"
        ? [
            styles.alignFontSize,
            {
              width: "70%",
              color: colorPallete.textBlack
            }
          ]
        : [styles.alignTextStyles, styles.fontSize12, styles.inputStylingAlignment];
    const userStyleText =
      valueType === "title" ? [styles.alignTextWidth] : [styles.alignTextStyles, styles.fontSize12];

    return (
      <UserInputSecondary
        textValue={textVaue}
        handleBlur={() => {}}
        handleChange={(type: string) => handleChange(type, field, valueType)}
        value={value}
        placeholder={`Enter ${textVaue}`}
        name={textVaue}
        id={textVaue}
        userStyle={userStyle}
        userStyleText={userStyleText}
        titleInputContainerStyles={valueType !== "title" ? styles.titleInputContainerStyles : null}
        editable={isEditable}
        noFormik
      />
    );
  };

  const getFormFieldValue = (textValue: string, value: any, userStyle: any, userStyleText: any) => {
    return (
      <FormFieldView
        textValue={textValue}
        handleBlur={() => {}}
        value={value}
        placeholder={textValue}
        name={textValue}
        id={textValue}
        userStyle={userStyle}
        userStyleText={userStyleText}
      />
    );
  };

  const getTickAndCloseIcons = (type: string, isEditedType: any) => {
    return (
      <>
        <TouchableOpacity
          style={[styles.alignIconStyles]}
          onPress={() => {
            handleSubmit(type);
          }}
          disabled={!isEditedType}
        >
          <Icon testID="tick" name={IconNames.tick} customStyle={[styles.iconStyles]} />
        </TouchableOpacity>
        <TouchableOpacity style={styles.alignIconStyles} onPress={() => handleClose(type)}>
          <Icon testID="cancel" name={IconNames.close} customStyle={[styles.iconStyles]} />
        </TouchableOpacity>
      </>
    );
  };

  const canDeleteEntity = (type: string) => {
    return type === "title" && selectedEntity?.userId === UserHelper.getUserId();
  };

  const getEditAndDeleteIcons = (type: string) => {
    return (
      <>
        <TouchableOpacity
          style={[styles.alignIconStyles]}
          onPress={() => {
            handleTick(type);
          }}
        >
          <Icon testID={`tick_` + type} name={IconNames.edit} customStyle={[styles.iconStyles]} />
        </TouchableOpacity>
        {canDeleteEntity(type) && (
          <TouchableOpacity style={styles.alignIconStyles} onPress={activateDeactivatEntityHandler}>
            <Icon testID="cancel" name={IconNames.deleteFile} customStyle={[styles.iconStyles]} />
          </TouchableOpacity>
        )}
      </>
    );
  };

  const getNoDetailsTab = () => {
    return (
      <View>
        <Text
          fontWeight={FontWeight.Bold}
          testId="headerText"
          textSize={TextSize.Small}
          textStyle={[styles.headerText, styles.miniContainerHeaderText]}
        >
          Entity Details not available
        </Text>
      </View>
    );
  };

  return (
    <View style={styles.parentContainer}>
      <View style={styles.parentSubContainer}>
        <View style={styles.container}>
          <View style={styles.subContainer}>
            {isLoading || selectedEntity === null ? (
              <Loader />
            ) : selectedEntity === "Error" ? (
              getNoDetailsTab()
            ) : (
              <>
                <View style={styles.titleContainer}>
                  <View style={styles.logoContainer}>{getLogo()}</View>
                  <View
                    style={{
                      flex: 60,
                      flexDirection: "column",
                      display: "flex",
                      alignSelf: "flex-start",
                      paddingVertical: 20
                    }}
                  >
                    {!editable?.title ? (
                      <Text
                        fontWeight={FontWeight.Light}
                        testId={`entityname__${selectedEntity?.entityId}`}
                        textSize={TextSize.Small}
                        textStyle={[styles.headerText, styles.headerTextTitleSupport]}
                      >
                        {selectedEntity?.name}
                      </Text>
                    ) : (
                      getUserInputSecondary(
                        "Entity Name",
                        state?.name,
                        editable.title,
                        "name",
                        "title"
                      )
                    )}
                    {getFormFieldValue(
                      "Date of Registration",
                      dataHelper.formatDate(selectedEntity?.createdAt),
                      [styles.alignFontSize, { width: "30%" }],
                      [styles.alignTextWidth]
                    )}
                  </View>

                  <View style={styles.editContainer}>
                    {!editable.title
                      ? getEditAndDeleteIcons("title")
                      : getTickAndCloseIcons("title", isEdited.title)}
                  </View>
                </View>

                <View style={styles.detailsContainer}>
                  <View style={styles.detailsContainerTwo}>
                    <View style={styles.labelValueContainer}>
                      <View style={styles.header}>
                        <Text
                          fontWeight={FontWeight.Regular}
                          testId="internal"
                          textSize={TextSize.Small}
                          textStyle={[styles.alignTextStyles, styles.fontSize12]}
                        >
                          {"Details"}
                        </Text>

                        {!editable.details
                          ? getEditAndDeleteIcons("details")
                          : getTickAndCloseIcons("details", isEdited.details)}
                      </View>
                      <View>
                        {!editable.details
                          ? getFormFieldValue(
                              "Business registration number",
                              selectedEntity?.registrationNumber,
                              [styles.alignFontSize, { width: "30%" }],
                              [styles.alignTextWidth, { flex: 60 }]
                            )
                          : getUserInputSecondary(
                              "Business registration number",
                              state?.registrationNumber,
                              editable.details,
                              "registrationNumber",
                              "details"
                            )}
                      </View>
                      <View>
                        {!editable.details
                          ? getFormFieldValue(
                              "Tax registration number",
                              selectedEntity?.taxRegNumber,
                              [styles.alignFontSize, { width: "30%" }],
                              [styles.alignTextWidth, { flex: 60 }]
                            )
                          : getUserInputSecondary(
                              "Tax registration number",
                              state?.taxRegNumber,
                              editable.details,
                              "taxRegNumber",
                              "details"
                            )}
                      </View>
                      <View>
                        {!editable.details
                          ? getFormFieldValue(
                              "Address line 1",
                              selectedEntity?.address,
                              [styles.alignFontSize, { width: "30%" }],
                              [styles.alignTextWidth, { flex: 60 }]
                            )
                          : getUserInputSecondary(
                              "Address line 1",
                              state?.address,
                              editable.details,
                              "address",
                              "details"
                            )}
                      </View>
                      <View>
                        {!editable.details
                          ? getFormFieldValue(
                              "Address line 2",
                              selectedEntity?.address2,
                              [styles.alignFontSize, { width: "30%" }],
                              [styles.alignTextWidth, { flex: 60 }]
                            )
                          : getUserInputSecondary(
                              "Address line 2",
                              state?.address2,
                              editable.details,
                              "address2",
                              "details"
                            )}
                      </View>
                      <View>
                        {!editable.details
                          ? getFormFieldValue(
                              "City",
                              selectedEntity?.city,
                              [styles.alignFontSize, { width: "30%" }],
                              [styles.alignTextWidth, { flex: 60 }]
                            )
                          : getUserInputSecondary(
                              "City",
                              state?.city,
                              editable.details,
                              "city",
                              "details"
                            )}
                      </View>
                      <View>
                        {!editable.details
                          ? getFormFieldValue(
                              "State",
                              selectedEntity?.state,
                              [styles.alignFontSize, { width: "30%" }],
                              [styles.alignTextWidth, { flex: 60 }]
                            )
                          : getUserInputSecondary(
                              "State",
                              state?.state,
                              editable.details,
                              "state",
                              "details"
                            )}
                      </View>
                      <View>
                        {!editable.details
                          ? getFormFieldValue(
                              "Country",
                              selectedEntity?.country,
                              [styles.alignFontSize, { width: "30%" }],
                              [styles.alignTextWidth, { flex: 60 }]
                            )
                          : getUserInputSecondary(
                              "Country",
                              state?.country,
                              editable.details,
                              "country",
                              "details"
                            )}
                      </View>
                      <View>
                        {!editable.details
                          ? getFormFieldValue(
                              "Pin/Zip Code",
                              selectedEntity?.pinCode,
                              [styles.alignFontSize, { width: "30%" }],
                              [styles.alignTextWidth, { flex: 60 }]
                            )
                          : getUserInputSecondary(
                              "Pin/Zip Code",
                              state?.pinCode,
                              editable.details,
                              "pinCode",
                              "details"
                            )}
                      </View>
                      <View>
                        {!editable.details
                          ? getFormFieldValue(
                              "Website",
                              selectedEntity?.websiteUrl,
                              [styles.alignFontSize, { width: "30%" }],
                              [styles.alignTextWidth, { flex: 60 }]
                            )
                          : getUserInputSecondary(
                              "Website",
                              state?.websiteUrl,
                              editable.details,
                              "websiteUrl",
                              "details"
                            )}
                      </View>
                      <View>
                        {!editable.details
                          ? getFormFieldValue(
                              "Number of Employees",
                              selectedEntity?.noOfEmployees,
                              [styles.alignFontSize, { width: "30%" }],
                              [styles.alignTextWidth, { flex: 60 }]
                            )
                          : getUserInputSecondary(
                              "Number of Employees",
                              state?.noOfEmployees,
                              editable.details,
                              "noOfEmployees",
                              "details"
                            )}
                      </View>
                    </View>
                  </View>
                  <View style={styles.detailsSubContainerTwo}>
                    <View
                      style={{
                        borderRadius: 10,
                        borderColor: colorPallete.lightGreyThree,
                        borderWidth: 1,
                        paddingHorizontal: 20,
                        marginBottom: 25
                      }}
                    >
                      <View
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                          alignItems: "center"
                        }}
                      >
                        {getFormFieldValue(
                          "Mission",
                          selectedEntity?.mission,
                          [styles.alignFontSize, { width: "30%" }],
                          [styles.missionContainer]
                        )}

                        {!editable.mission
                          ? getEditAndDeleteIcons("mission")
                          : getTickAndCloseIcons("mission", isEdited.mission)}
                      </View>
                      {editable.mission && (
                        <TextArea
                          label=""
                          handleChange={(mission: string) =>
                            handleChange(mission, "mission", "mission")
                          }
                          handleBlur={() => {}}
                          value={state.mission}
                          inputStyle={[styles.textAreaALign]}
                          placeholder="Enter Mission"
                          name="Mission"
                          id="Mission"
                          numberOfLines={4}
                          multiline={true}
                          noFormik
                        />
                      )}
                    </View>

                    {selectedEntity && selectedEntity?.registrationDocs ? (
                      <Documents
                        ids={selectedEntity?.registrationDocs}
                        selectedItem={selectedEntity}
                        itemType={REGISTRY_TYPE.entity}
                        uploadText={"Upload Entity Documents"}
                      />
                    ) : null}
                  </View>
                </View>

                {showRemoveModal && (
                  <View>
                    <SocionModal
                      modalVisible={showRemoveModal}
                      setModalVisible={() => setShowRemoveModal(!showRemoveModal)}
                      component={
                        <RemoveLinkModal
                          modalVisible={showRemoveModal}
                          selectedData={handleActivateDeactivate}
                          textValue={
                            isEntityEnabled
                              ? dataHelper.replaceText(MESSAGES.INFO.DEACTIVATE, {
                                  ["{type}"]: "Entity",
                                  ["{name}"]: selectedEntity?.name
                                })
                              : dataHelper.replaceText(MESSAGES.INFO.ACTIVATE, {
                                  ["{type}"]: "Entity",
                                  ["{name}"]: selectedEntity?.name
                                })
                          }
                          deleteButtonText={isEntityEnabled ? "Deactivate" : "Reactivate"}
                          setModalVisible={() => setShowRemoveModal(!showRemoveModal)}
                        />
                      }
                    />
                  </View>
                )}
              </>
            )}
          </View>
        </View>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  parentContainer: {
    display: "flex",
    padding: 20,
    backgroundColor: "#F8F5F0",
    minHeight: "calc(100vh - 50px)",
    paddingTop: 15
  },
  parentSubContainer: {
    backgroundColor: colorPallete.white,
    borderRadius: 10,
    shadowColor: colorPallete.cordioRedDark1,
    shadowOpacity: 0.1,
    shadowOffset: {
      height: 10,
      width: 5
    },
    shadowRadius: 10
  },
  container: {
    display: "flex",
    padding: 18
    // paddingLeft: 25
    // backgroundColor: "#F8F5F0"
  },
  subContainer: {
    backgroundColor: colorPallete.white
  },

  titleContainer: {
    flexDirection: "row",
    alignItems: "center",
    flex: 1,
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: colorPallete.lightGreyThree,
    borderRadius: 10,
    marginHorizontal: 5,
    marginLeft: 0,
    marginBottom: 18,
    padding: 25,
    width: "100%",
    height: 800
  },
  logoContainer: {
    // flex: 11,
    marginRight: 20
  },
  one: {
    // flex: 0.8,
    width: 120,
    position: "relative"
  },
  imageContainer: {
    width: 120,
    height: 120,
    alignItems: "center",
    justifyContent: "center",
    borderWidth: 1,
    borderRadius: 20,
    borderColor: colorPallete.cordioTaupe
  },
  profileImage: {
    width: 90,
    height: 90
  },
  uploadProfileIconContainer: {
    position: "absolute",
    borderRadius: 50,
    width: 30,
    height: 30,
    backgroundColor: colorPallete.white,
    justifyContent: "center",
    alignItems: "center",
    bottom: -10,
    right: -10
  },
  uploadProfileIconSubContainer: {
    borderRadius: 50,
    width: 27,
    height: 27,
    backgroundColor: colorPallete.cordioTaupe,
    justifyContent: "center",
    alignItems: "center"
  },
  iconStyle: {
    fontSize: 17,
    color: colorPallete.cordioTaupe
  },
  uploadIcon: {
    color: colorPallete.white
  },
  alignFontSize: {
    width: "85%",
    fontSize: 14
  },
  alignTextWidth: {
    // flex: 15,
    fontSize: 14
  },
  headerText: {
    fontSize: 14,
    fontFamily: FontFamily.Medium,
    lineHeight: 17
  },
  miniContainerHeaderText: {
    color: colorPallete.textBlack,
    fontSize: 12,
    fontFamily: FontFamily.Medium,
    lineHeight: 20
  },
  headerTextTitleSupport: {
    fontWeight: "700",
    fontSize: 12,
    wordBreak: "break-word"
  },
  editContainer: {
    flex: 10,
    display: "flex",
    flexDirection: "row",
    alignSelf: "flex-start",
    justifyContent: "flex-end"
  },
  alignIconStyles: {
    marginLeft: 30
  },
  iconStyles: {
    color: colorPallete.cordioTaupe,
    fontSize: 15
  },
  detailsContainer: {
    flexDirection: "row",
    flex: 1
  },
  detailsContainerTwo: {
    flex: 50,
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: colorPallete.lightGreyThree,
    borderRadius: 10,
    marginHorizontal: 5,
    marginLeft: 0
  },
  labelValueContainer: {
    paddingVertical: 15,
    paddingHorizontal: 20
  },
  header: {
    display: "flex",
    flexDirection: "row",
    marginBottom: 20,
    justifyContent: "space-between"
  },
  alignTextStyles: {
    flex: 70,
    marginTop: 0,
    color: colorPallete.textLight
  },
  fontSize12: {
    fontSize: 14
  },
  inputStylingAlignment: {
    width: "75%",
    color: colorPallete.textBlack
  },
  detailsSubContainerTwo: {
    flex: 50
  },
  titleInputContainerStyles: {
    marginTop: 0,
    alignItems: "center",
    marginBottom: 10
  },
  missionContainer: {
    fontSize: 14
  },
  textAreaALign: {
    borderRadius: 5,
    marginVertical: 20,
    padding: 10,
    borderColor: colorPallete.lightGreyThree
  }
});

import { all } from "redux-saga/effects";
import profileWatcherSaga from "@socion-cordio/common/src/modules/profile/saga/saga";
import signupWatcherSaga from "@socion-cordio/common/src/modules/signup/saga/saga";
import EntityWatcherSaga from "@socion-cordio/common/src/modules/registry/entity/saga/saga";

export default function* rootSaga() {
  yield all([
    profileWatcherSaga(),
    signupWatcherSaga(),
    EntityWatcherSaga(),
  ]);
}

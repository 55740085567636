import React, { ReactElement, useState, useEffect } from "react";
import { StyleSheet, TouchableOpacity, View } from "react-native";
import { colorPallete } from "@socion-cordio/common/src/assets/styles/colors";
import {
  Text,
  TextSize,
  FontWeight,
  FontFamily
} from "@socion-cordio/common/src/components/atoms/text";
import Icon, { IconNames } from "@socion-cordio/common/src/components/atoms/icon";
import UserInputSecondary from "@socion-cordio/common/src/components/molecules/userInputSecondary";
import { dataHelper } from "@socion-cordio/common/src/utils/dataHelper";
import Loader from "@socion-cordio/common/src/components/atoms/loader";
import { toast } from "react-toastify";
import RemoveLinkModal from "@socion-cordio/common/src/components/organisms/common-modals/removeLinkModal";
import Documents from "@socion-cordio/common/src/components/organisms/entity/documents";
import { useHistory, useLocation } from "react-router-dom";
import SocionModal from "@socion-cordio/common/src/components/atoms/modal";
import { Image } from "@socion-cordio/common/src/components/atoms/image";
import FormFieldView from "@socion-cordio/common/src/components/molecules/formFieldView";
import TextArea from "@socion-cordio/common/src/components/atoms/textArea";
import { REGISTRY_TYPE } from "@socion-cordio/common/src/constants/registry";
import { ProgramsRepository } from "@socion-cordio/common/src/repositories/registry/entity/programs";

import { DocHelper } from "@socion-cordio/common/src/utils/registryHelpers/docHelper";
import { UserHelper } from "@socion-cordio/common/src/utils/userHelper";
import { useDispatch, useSelector } from "react-redux";
import DatePicker from "@socion-cordio/common/src/components/molecules/datePicker";
import { allRoutesNames } from "@socion-cordio/web/src/navigation/allRouteNames";
import { MESSAGES } from "@socion-cordio/common/src/constants/message";

const entityState = {
  name: "",
  logo: "",
  description: "",
  startDate: "",
  endDate: ""
};

export default function ProgramDetails(): ReactElement {
  const history = useHistory();
  const hiddenFileInput = React.useRef(null);
  const location: any = useLocation();
  const [selectedProgram, setSelectedProgram] = useState(null);
  const [logoPreview, setLogoPreview] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [updateReq, setUpdateReq] = useState({});
  const [showRemoveModal, setShowRemoveModal] = useState(false);
  const [isProgramEnabled, setIsProgramEnabled] = useState<boolean>(false);
  const [updatedType, setUpdatedType] = useState(null);

  const [editable, setEditable] = useState({
    title: false,
    description: false
  });

  const [isEdited, setIsEdited] = useState({
    title: false,
    description: false
  });

  const [state, setState] = useState(entityState);

  useEffect(() => {
    const program = location?.state?.programData;
    setSelectedProgram(location?.state?.programData);
    setState({
      name: program?.name || null,
      description: program?.description || null,
      logo: program?.logo || null,
      startDate: program?.startDate ? dataHelper.formatDate(program?.startDate, 'YYYY-MM-DD') : null,
      endDate: program?.endDate ? dataHelper.formatDate(program?.endDate, 'YYYY-MM-DD') : null,
    });
  }, []);

  useEffect(() => {

    if (updatedType !== null) {
      validateDataChangeHandler(updatedType);
    }
  }, [updatedType, updateReq, logoPreview]);

  const handleOnClick = (event: any) => {
    if (editable.title) {
      const { target = {} } = event || {};
      target.value = "";
    }
  };

  const handleCustomChange = async (e: any) => {
    let fileNamesArray: any = [];
    if (fileNamesArray.includes(e.target.files[0].name)) {
      toast.error("Document already added");
      return;
    }
    const validTypes = ["png", "jpg", "jpeg", "svg"];
    const splitFileName = e?.currentTarget?.files[0]?.name.split(".");
    const extension = splitFileName[splitFileName.length - 1].toLowerCase();
    if (e.target.validity.valid && validTypes.includes(extension)) {
      setIsLoading(true);
      const { url } = await DocHelper.getCompressedFile(e?.target?.files[0]);
      setLogoPreview(url);
      handleChange(url, 'logo', 'title')
      setIsLoading(false);
    } else {
      toast.error(
        "Invalid File Type! Only Files with format of mp4, mpeg, mov, flv, png, jpg, jpeg, pdf will be uploaded!!"
      );
    }
  };

  const handleClick = (event: any) => {
    editable.title && hiddenFileInput.current.click();
  };

  const activateDeactivatEntityHandler = async () => {
    setShowRemoveModal(true);
  };

  const validateDataChangeHandler = (type: string) => {
    // chech state data with api data
    if (selectedProgram !== null) {
      switch (type) {
        case "title":
          const isSameName = state.name === selectedProgram?.name && state.logo === selectedProgram?.logo;
          setIsEdited({ ...isEdited, title: !isSameName });
          break;
        case "description":
          const isSameDescription = state.description === selectedProgram?.description;
          setIsEdited({ ...isEdited, description: !isSameDescription });
          break;
      }
    }
  };
  const handleChange = (value: string, field: string, type: string) => {
    setState({
      ...state,
      [field]: value
    });
    setUpdatedType(type);
    setUpdateReq({
      ...updateReq,
      [field]: value
    });
  };

  const handleTick = (type: string) => {
    switch (type) {
      case "title":
        setEditable({ ...editable, title: !editable.title, description: false });
        break;
      case "description":
        setEditable({ ...editable, title: false , description: !editable.description });
        break;
    }
  };

  const handleClose = (type: string) => {
    switch (type) {
      case "title":
        setEditable({ ...editable, title: false });
        break;
      case "description":
        setEditable({ ...editable, description: false });
        break;
    }
  };

  const handleSubmit = async (type: string) => {
    switch (type) {
      case "title":
        isEdited.title;
        if (state.name === "") {
          toast.error("Please Fill the Entity Name.");
          setEditable({ ...editable, title: true });
        } else {
          await updateProgram(updateReq, "title");
        }
        break;
      case "description":
        if (state.description === "") {
          toast.error("Please Complete All the Details.");
          setEditable({ ...editable, description: true });
        } else {
          await updateProgram(updateReq, "details");
        }
        break;
    }
  };

  const updateProgram = async (req: any, type: string) => {
    handleClose(type);
    req = {
      ...req,
      parentId: selectedProgram?.parentId,
      parentType: selectedProgram?.parentType,
      updatedBy: UserHelper.getUserName(),
      updatedById: UserHelper.getUserId()
    };
    const res = await ProgramsRepository.updateEntityProgram(req, selectedProgram?.programId).catch(
      (err) => {
        toast.error(
          dataHelper.replaceText(MESSAGES.ERROR.UPDATE, {
            ["{type}"]: "Program",
            ["{name}"]: selectedProgram?.name
          })
        );
        console.log("Update Entity Error:", err);
      }
    );
    if (res) {
      toast.success(
        dataHelper.replaceText(MESSAGES.SUCCESS.UPDATE, {
          ["{type}"]: "Program",
          ["{name}"]: selectedProgram?.name
        })
      );
    }
  };

  const handleActivateDeactivate = async () => {
    const payload = {
      parentId: selectedProgram?.parentId,
      parentType: selectedProgram?.parentType,
      updatedBy: UserHelper.getUserName(),
      updatedById: UserHelper.getUserId()
    };

    const res = await ProgramsRepository.toggleEntityProgram(
      payload,
      selectedProgram?.programId,
      !selectedProgram?.active
    ).catch((err) => {
      const msg = !selectedProgram?.active ? MESSAGES.ERROR.DEACTIVATE : MESSAGES.ERROR.ACTIVATE;
      toast.error(
        dataHelper.replaceText(msg, {
          ["{type}"]: "Program",
          ["{name}"]: selectedProgram?.name
        })
      );
    });

    if (res) {
      const msg = !selectedProgram?.active ? MESSAGES.SUCCESS.DEACTIVATE : MESSAGES.SUCCESS.ACTIVATE;
      toast.success(
        dataHelper.replaceText(msg, {
          ["{type}"]: "Program",
          ["{name}"]: selectedProgram?.name
        })
      );
      setIsProgramEnabled((isProgramEnabled: boolean) => !isProgramEnabled);
      setShowRemoveModal(false);
    }
  };

  const getLogo = () => {
    return (
      <TouchableOpacity onPress={handleClick}>
        <View style={styles.one}>
          <View style={styles.imageContainer}>
            <Image
              imageStyle={styles.profileImage}
              testId="socionImg"
              source={
                selectedProgram?.logo ||
                logoPreview ||
                require("@socion-cordio/common/src/assets/images/user_circle.svg")
              }
            />
          </View>
          {editable.title && (
            <View style={styles.uploadProfileIconContainer}>
              <View style={styles.uploadProfileIconSubContainer}>
                <Icon
                  name={IconNames.uploadProfile}
                  customStyle={[styles.iconStyle, styles.uploadIcon]}
                />
              </View>
            </View>
          )}
        </View>
        <input
          style={{
            display: "none",
            zIndex: -1
          }}
          ref={hiddenFileInput}
          type="file"
          onChange={async (e) => {
            await handleCustomChange(e);
          }}
          onClick={handleOnClick}
        />
      </TouchableOpacity>
    );
  };

  const getUserInputSecondary = (
    textVaue: string,
    value: any,
    isEditable: any,
    field: string,
    valueType: string
    // style: any
  ) => {
    const userStyle =
      valueType === "title"
        ? [
            styles.alignFontSize,
            {
              width: "70%",
              color: colorPallete.textBlack
            }
          ]
        : [styles.alignTextStyles, styles.fontSize12, styles.inputStylingAlignment];
    const userStyleText =
      valueType === "title" ? [styles.alignTextWidth] : [styles.alignTextStyles, styles.fontSize12];

    return (
      <UserInputSecondary
        textValue={textVaue}
        handleBlur={() => {}}
        handleChange={(type: string) => handleChange(type, field, valueType)}
        value={value}
        placeholder={`Enter ${textVaue}`}
        name={textVaue}
        id={textVaue}
        userStyle={userStyle}
        userStyleText={userStyleText}
        titleInputContainerStyles={valueType !== "title" ? styles.titleInputContainerStyles : null}
        editable={isEditable}
        noFormik
      />
    );
  };

  const getFormFieldValue = (textValue: string, value: any, userStyle: any, userStyleText: any) => {
    return (
      <FormFieldView
        textValue={textValue}
        handleBlur={() => {}}
        value={value}
        placeholder={textValue}
        name={textValue}
        id={textValue}
        userStyle={userStyle}
        userStyleText={userStyleText}
      />
    );
  };

  const getTickAndCloseIcons = (type: string, isEditedType: any) => {
    return (
      <>
        <TouchableOpacity
          style={[styles.alignIconStyles]}
          onPress={() => {
            handleSubmit(type);
          }}
          disabled={!isEditedType}
        >
          <Icon testID="tick" name={IconNames.tick} customStyle={[styles.iconStyles]} />
        </TouchableOpacity>
        <TouchableOpacity style={styles.alignIconStyles} onPress={() => handleClose(type)}>
          <Icon testID="cancel" name={IconNames.close} customStyle={[styles.iconStyles]} />
        </TouchableOpacity>
      </>
    );
  };

  const getEditAndDeleteIcons = (type: string) => {
    return (
      <>
        <TouchableOpacity
          style={[styles.alignIconStyles]}
          onPress={() => {
            handleTick(type);
          }}
        >
          <Icon testID={`tick_` + type} name={IconNames.edit} customStyle={[styles.iconStyles]} />
        </TouchableOpacity>
        {type === "title" && (
          <TouchableOpacity style={styles.alignIconStyles} onPress={activateDeactivatEntityHandler}>
            <Icon testID="cancel" name={IconNames.deleteFile} customStyle={[styles.iconStyles]} />
          </TouchableOpacity>
        )}
      </>
    );
  };

  const redirectionHandler = async () => {
    history.push(`${allRoutesNames.app}${allRoutesNames.PROGRAM}`);
  };

  const getPageTitle = () => {
    return (
      <View style={{ display: "flex", flexDirection: "row", alignItems: "center", paddingVertical: 24, paddingTop: 11 }}>
        <TouchableOpacity onPress={() => redirectionHandler()}>
          <Icon testID="close" name={IconNames.leftArrow} customStyle={styles.iconStyle} />
        </TouchableOpacity>

        <Text
          fontWeight={FontWeight.Bold}
          testId="headerText"
          textSize={TextSize.Small}
          textStyle={[styles.headerText, styles.miniContainerHeaderText, {paddingLeft: 10}]}
        >
          {selectedProgram?.name}
        </Text>

        <Text
          fontWeight={FontWeight.Bold}
          testId="headerText"
          textSize={TextSize.Small}
          textStyle={[styles.headerText, styles.miniContainerHeaderText, {opacity: 0.6}]}
        >
          {"   >   Program Details"}
        </Text>
      </View>
    );
  };

  return (
    <View style={styles.parentContainer}>
      <View style={styles.parentSubContainer}>
        <View style={styles.container}>
          <View style={styles.subContainer}>
            {isLoading ? (
              <Loader />
            ) : (
              <>
              {getPageTitle()}
                <View style={styles.titleContainer}>
                  <View style={styles.logoContainer}>{getLogo()}</View>
                  <View
                    style={{
                      flex: 60,
                      flexDirection: "column",
                      display: "flex",
                      alignSelf: "flex-start",
                      paddingVertical: 20
                    }}
                  >
                    {!editable?.title ? (
                      <>
                        <Text
                          fontWeight={FontWeight.Light}
                          testId={`entityname__${selectedProgram?.entityId}`}
                          textSize={TextSize.Small}
                          textStyle={[styles.headerText, styles.headerTextTitleSupport]}
                        >
                          {selectedProgram?.name}
                        </Text>

                        <>
                          {getFormFieldValue(
                            "Start Date",
                            selectedProgram?.startDate
                              ? dataHelper.formatDate(selectedProgram?.startDate)
                              : "",
                            [styles.alignFontSize, { width: "30%" }],
                            [styles.alignTextWidth]
                          )}
                          {getFormFieldValue(
                            "End Date",
                            selectedProgram?.endDate
                              ? dataHelper.formatDate(selectedProgram?.endDate)
                              : "",
                            [styles.alignFontSize, { width: "30%" }],
                            [styles.alignTextWidth]
                          )}
                        </>
                      </>
                    ) : (
                      <>
                        {getUserInputSecondary(
                          "Entity Name",
                          state?.name,
                          editable.title,
                          "name",
                          "title"
                        )}

                        <View style={styles.dateContainer}>
                          <Text
                            fontWeight={FontWeight.Regular}
                            testId="internal"
                            textSize={TextSize.Small}
                            textStyle={[styles.subheaderText]}
                          >
                            {"Start Date"}
                          </Text>
                          <View style={[styles.titleInputContainerStyles, { marginStart: 46 }]}>
                            <DatePicker
                              handleBlur={() => {}}
                              onChange={(date: any) => {
                                setState({ ...state, startDate: date?.target?.value });
                              }}
                              value={state.startDate}
                              name="startDate"
                              id="startDate"
                              inputStyle={true}
                              noFormik
                            />
                          </View>
                        </View>

                        <View style={styles.dateContainer}>
                          <Text
                            fontWeight={FontWeight.Regular}
                            testId="internal"
                            textSize={TextSize.Small}
                            textStyle={[styles.subheaderText]}
                          >
                            {"End Date"}
                          </Text>
                          <View style={[styles.titleInputContainerStyles, { marginStart: 52 }]}>
                            <DatePicker
                              min={state?.startDate}
                              handleBlur={() => {}}
                              onChange={(date: any) => {
                                setState({ ...state, endDate: date?.target?.value });
                              }}
                              value={state.endDate}
                              name="endDate"
                              id="endDate"
                              inputStyle={true}
                              noFormik
                            />
                          </View>
                        </View>
                      </>
                    )}
                  </View>

                  <View style={styles.editContainer}>
                    {!editable.title
                      ? getEditAndDeleteIcons("title")
                      : getTickAndCloseIcons("title", isEdited.title)}
                  </View>
                </View>

                <View style={styles.detailsContainer}>
                  <View style={styles.detailsContainerTwo}>
                    <View style={styles.labelValueContainer}>
                      <View style={styles.header}>
                        <Text
                          fontWeight={FontWeight.Regular}
                          testId="internal"
                          textSize={TextSize.Small}
                          textStyle={[styles.alignTextStyles, styles.fontSize12]}
                        >
                          {"Description"}
                        </Text>

                        {!editable.description
                          ? getEditAndDeleteIcons("description")
                          : getTickAndCloseIcons("description", isEdited.description)}
                      </View>
                      <View>
                        {!editable.description ? (
                          getFormFieldValue(
                            "",
                            selectedProgram?.description,
                            [styles.alignFontSize, { width: "30%" }],
                            [styles.alignTextWidth, { flex: 60 }]
                          )
                        ) : (
                          <TextArea
                            label=""
                            handleChange={(description: string) =>
                              handleChange(description, "description", "description")
                            }
                            handleBlur={() => {}}
                            value={state.description}
                            inputStyle={[styles.textAreaALign]}
                            placeholder="Enter Description"
                            name="description"
                            id="description"
                            numberOfLines={6}
                            multiline={true}
                            noFormik
                          />
                        )}
                      </View>
                    </View>
                  </View>
                  <View style={styles.detailsSubContainerTwo}>
                    {/* {selectedProgram && selectedProgram?.docs ? ( */}
                      <Documents
                        ids={selectedProgram?.docs}
                        selectedItem={selectedProgram}
                        itemType={REGISTRY_TYPE.entity_program}
                        uploadText={"Upload Program Documents"}
                      />
                    {/* ) : null} */}
                  </View>
                </View>

                {showRemoveModal && (
                  <View>
                    <SocionModal
                      modalVisible={showRemoveModal}
                      setModalVisible={() => setShowRemoveModal(!showRemoveModal)}
                      component={
                        <RemoveLinkModal
                          modalVisible={showRemoveModal}
                          selectedData={handleActivateDeactivate}
                          textValue={
                            isProgramEnabled
                            ? dataHelper.replaceText(MESSAGES.INFO.DEACTIVATE, {
                              ["{type}"]: "Program",
                              ["{name}"]: selectedProgram?.name
                            })
                          : dataHelper.replaceText(MESSAGES.INFO.ACTIVATE, {
                              ["{type}"]: "Program",
                              ["{name}"]: selectedProgram?.name
                            })
                          }
                          deleteButtonText={isProgramEnabled ? "Deactivate" : "Reactivate"}
                          setModalVisible={() => setShowRemoveModal(!showRemoveModal)}
                        />
                      }
                    />
                  </View>
                )}
              </>
            )}
          </View>
        </View>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  parentContainer: {
    display: "flex",
    padding: 20,
    backgroundColor: "#F8F5F0",
    minHeight: "calc(100vh - 50px)",
    paddingTop: 15
  },
  parentSubContainer: {
    backgroundColor: colorPallete.white,
    borderRadius: 10,
    shadowColor: colorPallete.cordioRedDark1,
    shadowOpacity: 0.1,
    shadowOffset: {
      height: 10,
      width: 5
    },
    shadowRadius: 10
  },
  container: {
    display: "flex",
    padding: 18
    // paddingLeft: 25
    // backgroundColor: "#F8F5F0"
  },
  subContainer: {
    backgroundColor: colorPallete.white
  },
  miniContainerHeaderText: {
    color: colorPallete.textBlack,
    fontSize: 12,
    fontFamily: FontFamily.Medium,
    lineHeight: 20
  },

  titleContainer: {
    flexDirection: "row",
    alignItems: "center",
    flex: 1,
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: colorPallete.lightGreyThree,
    borderRadius: 10,
    marginHorizontal: 5,
    marginLeft: 0,
    marginBottom: 18,
    padding: 25,
    width: "100%",
    height: 800
  },
  logoContainer: {
    // flex: 11,
    marginRight: 20
  },
  one: {
    // flex: 0.8,
    width: 120,
    position: "relative"
  },
  imageContainer: {
    width: 120,
    height: 120,
    alignItems: "center",
    justifyContent: "center",
    borderWidth: 1,
    borderRadius: 20,
    borderColor: colorPallete.cordioTaupe
  },
  profileImage: {
    width: 90,
    height: 90
  },
  uploadProfileIconContainer: {
    position: "absolute",
    borderRadius: 50,
    width: 30,
    height: 30,
    backgroundColor: colorPallete.white,
    justifyContent: "center",
    alignItems: "center",
    bottom: -10,
    right: -10
  },
  uploadProfileIconSubContainer: {
    borderRadius: 50,
    width: 27,
    height: 27,
    backgroundColor: colorPallete.cordioTaupe,
    justifyContent: "center",
    alignItems: "center"
  },
  iconStyle: {
    fontSize: 17,
    color: colorPallete.cordioTaupe
  },
  uploadIcon: {
    color: colorPallete.white
  },
  alignFontSize: {
    width: "85%",
    fontSize: 14
  },
  alignTextWidth: {
    // flex: 15,
    fontSize: 14
  },
  headerText: {
    fontSize: 14,
    fontFamily: FontFamily.Medium,
    lineHeight: 17
  },
  headerTextTitleSupport: {
    fontWeight: "700",
    fontSize: 12,
    wordBreak: "break-word"
  },
  dateContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center"
    // justifyContent: "space-between",
    // flex: 100
  },
  subheaderText: {
    // flex: 12,
    fontWeight: "400",
    fontSize: 14,
    fontFamily: FontFamily.Regular,
    lineHeight: 17,
    color: colorPallete.textLight,
    marginVertical: 15,
    marginRight: 45
  },
  editContainer: {
    flex: 10,
    display: "flex",
    flexDirection: "row",
    alignSelf: "flex-start",
    justifyContent: "flex-end"
  },
  alignIconStyles: {
    marginLeft: 30
  },
  iconStyles: {
    color: colorPallete.cordioTaupe,
    fontSize: 15
  },
  detailsContainer: {
    flexDirection: "row",
    flex: 1
  },
  detailsContainerTwo: {
    flex: 50,
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: colorPallete.lightGreyThree,
    borderRadius: 10,
    marginHorizontal: 5,
    marginLeft: 0
  },
  labelValueContainer: {
    paddingVertical: 15,
    paddingHorizontal: 20
  },
  header: {
    display: "flex",
    flexDirection: "row",
    marginBottom: 20,
    justifyContent: "space-between"
  },
  alignTextStyles: {
    flex: 70,
    marginTop: 0,
    color: colorPallete.textLight
  },
  fontSize12: {
    fontSize: 14
  },
  inputStylingAlignment: {
    width: "75%",
    color: colorPallete.textBlack
  },
  detailsSubContainerTwo: {
    flex: 50
  },
  titleInputContainerStyles: {
    marginTop: 0,
    alignItems: "center",
    marginBottom: 10
  },

  textAreaALign: {
    borderRadius: 5,
    marginVertical: 20,
    padding: 10,
    borderColor: colorPallete.lightGreyThree
  }
});

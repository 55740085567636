import { CONFIG } from "@socion-cordio/common/src/config/envConstant";
import { Logger } from "@socion-cordio/common/src/services/logger";
import { serverErrors } from "@socion-cordio/common/src/network/constants";
import axios, { AxiosError, AxiosInstance, AxiosRequestConfig } from "axios";
import { ConfigHelper } from "@socion-cordio/common/src/utils/loadEnv.util";
import { IApiClient } from "@socion-cordio/common/src/network/interfaces";
import { v4 as uuidv4 } from "uuid";
import {
  bearerTokenReqInterceptor,
  refreshTokenInterceptor
} from "@socion-cordio/common/src/network/interceptors";
import { successHandler } from "@socion-cordio/common/src/network/responseHandlers";

const getAxiosInstance = (baseUrl: string, client: IApiClient): AxiosInstance => {
  let axiosInstance = axios.create({
    baseURL: baseUrl,
    timeout: 30000,
    headers: {
      "Trace-Id": uuidv4(),
      "Content-Type": "application/json",
      offset: -330
    }
  });
  // Add Request Interceptor to add Authorization header
  axiosInstance = bearerTokenReqInterceptor(axiosInstance);
  console.log("axiosInstanceaxiosInstanceaxiosInstance", axiosInstance);
  // Add Response Interceptor for Authorization token expiry
  // axiosInstance = refreshTokenInterceptor(axiosInstance, baseURL);
  return axiosInstance;
};

export const handleError = (error: AxiosError) => {
  Logger.error(`API error:  ${JSON.stringify(error)}`);
  let errorMessage = serverErrors.SERVER_ERROR;
  const stringifyError: any = JSON.stringify(error);
   console.log("cdsvdfdns", stringifyError); 
  if (error?.message && error?.message === "Network Error") {
    Logger.error(`Network Error. Error: ${JSON.stringify(error)}`);
    //TODO Deal no network error
  }
  if (error?.response?.status >= 500) {
    errorMessage = serverErrors.SERVER_ERROR;
    // return errorMessage;
  }
  if (error?.response?.status >= 400 && error?.response?.status < 500) {
    errorMessage = error?.response?.data?.error;
    // return errorMessage;
  }
  if (error?.response) {
    const { data } = error?.response?.data?.error;
    if (data) {
      errorMessage = data.errorMessage;
    }
  }
  if (error?.message) {
    errorMessage = error?.message;
  } else {
    Logger.error(`Don't know the error. Error: ${JSON.stringify(error)}`);
  }

  console.log("cdsvdfdns errorMessage", error, errorMessage); 
  return Promise.resolve(errorMessage);
};

class RegistryApiClient implements IApiClient {
  private client: AxiosInstance;
  constructor(baseUrl: string) {
    this.client = getAxiosInstance(baseUrl, this);
  }
  private async request(config: AxiosRequestConfig) {
    try {
      // telemetry function here.
      // every api call here.

      // config check put or post call here.
      // and from here call telemetry
      // console.log("CONFIGG:::",config);
      console.log("clientclient rquest", this.client, config);
      let response = await this.client.request(config); //main api call.

      let handledResponse = await successHandler(response);
      // console.log("APICLIENT::::",response);
      // console.log("HANDLE RESPONSE::::",handledResponse); Promise.resolve
      return Promise.resolve(handledResponse);
    } catch (error) {
      let handledError = await handleError(error);
      return Promise.reject(handledError);
    }
  }
  public async get(url: string, params?: Object) {
    return this.request({ method: "GET", url, params });
  }
  public async put(url: string, data: Object, params?: Object) {
    return this.request({ method: "PUT", url, data, params });
  }
  public async post(url: string, data: Object, params?: Object) {
    return this.request({ method: "POST", url, data, params });
  }
  public async delete(url: string, data?: Object, params?: Object) {
    return this.request({ method: "DELETE", url, data, params });
  }
  public async patch(url: string, data: Object, params?: Object) {
    return this.request({ method: "PATCH", url, data, params });
  }
}
console.log("ENV", CONFIG);

// const baseURL = ConfigHelper.getNodeEnv() === "development" ? "/api/v1/" : `${process.env.REACT_APP_SCHEME}://${process.env.REACT_APP_BASE_URL}/${process.env.REACT_APP_PATH}/${process.env.REACT_APP_VERSION}`;
// const baseURL = `${config.REACT_APP_SCHEME}://${config.REACT_APP_BASE_URL}/${config.REACT_APP_PATH}/${config.REACT_APP_VERSION}`;
// const baseURL = `${config.REACT_APP_SCHEME}://${config.REACT_APP_BASE_URL}`;
const baseURL = CONFIG.REACT_APP_ENTITY_REGISTRATION_API_BASE_URL;
const registryApiClient = new RegistryApiClient(baseURL);
export { registryApiClient as RegistryApiClient };

import { UploadRepository } from "@socion-cordio/common/src/repositories/registry/docs/upload";
import { REGISTRY_TYPE } from "@socion-cordio/common/src/constants/registry";
import { DocsRepository } from "@socion-cordio/common/src/repositories/registry/docs/docs";
import { UserHelper } from "@socion-cordio/common/src/utils/userHelper";

const upload = async (
  filePath: string,
  docsArray: any,
  taggedId: string,
  type: string,
  parentId?: string
) => {
  try {
    const formData: FormData = new FormData();
    if (docsArray.length > 0) {
      for (let i = 0; i < docsArray.length; i++) {
        let file = docsArray[i];
        formData.append("files[]", file);
        formData.append("filePath", filePath);
      }

      let response = await UploadRepository.uploadFile(formData).catch((err) => {
        console.log("Error while Uploading Docs", err);
        return;
      });

      if (response) {
        switch (type) {
          case REGISTRY_TYPE.entity:
            const docResponse = await addDocsToEntity(taggedId, response);
            return docResponse;
          case REGISTRY_TYPE.entity_program:
            const docProgramResponse = await addDocsToEntityProgram(taggedId, parentId, response);
            return docProgramResponse;
        }
      }
      return;
    }
    return;
  } catch (err) {
    console.log("Error while Uploading Docs", err);
  }
};

const addDocsToEntity = async (entityId: string, uploadRes: any) => {
  if (uploadRes) {
    const docsrequest = {
      taggedId: entityId,
      type: REGISTRY_TYPE.entity,
      parentId: "",
      createdBy: UserHelper.getUserName(),
      userId: UserHelper.getUserId(),
      docs: uploadRes
    };
    const docsResponse = await DocsRepository.addDocs(docsrequest);
    return docsResponse;
  }
};

const addDocsToEntityProgram = async (programId: string,entityId: string,  uploadRes: any) => {
  if (uploadRes) {
    const docsrequest = {
      taggedId: programId,
      type: REGISTRY_TYPE.entity_program,
      parentId: entityId,
      createdBy: UserHelper.getUserName(),
      userId: UserHelper.getUserId(),
      docs: uploadRes
    };
    const docsResponse = await DocsRepository.addDocs(docsrequest);
    return docsResponse;
  }
};

export const UploadFileHelper = {
  upload
};

import React, { ReactElement, useState, useEffect } from "react";
import { StyleSheet, TouchableOpacity, View } from "react-native";
import { toast, ToastContainer } from "react-toastify";
import { colorPallete } from "@socion-cordio/common/src/assets/styles/colors";
import {
  Text,
  TextSize,
  FontWeight,
  FontFamily
} from "@socion-cordio/common/src/components/atoms/text";
import AesUtil from "@socion-cordio/common/src/utils/encryptionHelper";
import { ApiClient } from "@socion-cordio/common/src/network/apiClient";
import { HTTP_STATUS_CODES } from "@socion-cordio/common/src/network/constants";
import { springshedEndPoints } from "@socion-cordio/common/src/repositories/endPoints";
import Icon, { IconNames } from "@socion-cordio/common/src/components/atoms/icon";
import Button, { ButtonType } from "@socion-cordio/common/src/components/atoms/button";
import { Checkbox, CheckboxType } from "@socion-cordio/common/src/components/atoms/checkBox";
import { auxiliaryMethods } from "@socion-cordio/common/src/utils/auxiliaryMethods";
import MobileInput from "@socion-cordio/common/src/components/molecules/mobileInput";
import { Image } from "@socion-cordio/common/src/components/atoms/image";
import { LocalStorage } from "@socion-cordio/common/src/services/storage/storageService";
import { QrCodeScannerModal } from "@socion-cordio/common/src/components/molecules/qrCodeScannerModal";
import Loader from "@socion-cordio/common/src/components/atoms/loader";
import Select, { components, SingleValueProps } from "react-select";
import TextField from "@socion-cordio/common/src/components/atoms/textField";
import { AdminsRepository } from "@socion-cordio/common/src/repositories/registry/entity/admins";
import { UserHelper } from "@socion-cordio/common/src/utils/userHelper";
import { dataHelper } from "@socion-cordio/common/src/utils/dataHelper";
import { MESSAGES } from "@socion-cordio/common/src/constants/message";

interface Props {
  getRolesDetails?: Function;
  onClose?: Function;
  getUserRoles?: Function;
  payload?: any;
  onSuccess?: Function;
}

const OPTIONS_MODE = [
  {
    value: "Mobile number",
    label: "Mobile number",
    icon: IconNames.mobile
  },
  {
    value: "Email ID",
    label: "Email ID",
    icon: IconNames.email
  }
];

export default function AddAdminModal(props: Props): ReactElement {
  let aesUtil: AesUtil = new AesUtil();
  const [mobileNumber, setMobileNumber] = useState("");
  const [countryCode, setCountryCode] = useState("");
  const [selectedUser, setSelectedUser] = useState(undefined);
  const [programData, setProgramData] = useState(undefined);
  const [userData, setuserData] = useState(undefined);
  const [qrCodeUserDetails, setQrCodeUserDetails] = useState(false);
  const [modalLoading, setModalLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [validate, setValidate] = useState([
    {
      label: "+91",
      phoneNumberLength: 10,
      phoneNumberSizeMax: 10,
      phoneNumberSizeMin: 10,
      value: 91
    }
  ]);
  const [minLength, setMinLength] = useState(10);
  const [maxLength, setMaxLength] = useState(10);
  const [loginModeList, setLoginModeList] = useState(null);
  const [isMobileInputSelected, setIsMobileInputSelected] = useState(true);
  const [loginOptionsList, setLoginOptionsList] = useState(null);
  const [userName, setUserName] = useState("");
  const [email, setEmail] = useState("");
  const [isValidEmail, setIsValidEmail] = useState(false);
  const { Option } = components;

  const IconOption = (props: any) => (
    <Option {...props}>
      <View style={{ flexDirection: "row", alignItems: "center" }}>
        <Icon testID="home" name={props.data.icon} customStyle={{}} />
        <View style={{ marginLeft: 5 }}>{props.data.label}</View>
      </View>
    </Option>
  );

  const SingleValue = ({ children, ...props }: SingleValueProps<any>) => (
    <components.SingleValue {...props}>
      <View style={{ flexDirection: "row", alignItems: "center" }}>
        <Icon testID="home" name={props.data.icon} customStyle={{}} />
        <View style={{ marginLeft: 10 }}> {children}</View>
      </View>
    </components.SingleValue>
  );

  useEffect(() => {
    setLoginModeList(OPTIONS_MODE[0]);
    setLoginOptionsList([OPTIONS_MODE[1]]);
    if (validate[0]?.phoneNumberSizeMax != validate[0]?.phoneNumberSizeMin) {
      setMinLength(validate[0]?.phoneNumberSizeMin);
      setMaxLength(validate[0]?.phoneNumberSizeMax);
    } else {
      setMinLength(10);
      setMaxLength(10);
    }
  }, [validate]);

  useEffect(() => {
    getProgramDetails();
  }, []);

  const getProgramDetails = async () => {
    const programDetails: any = await LocalStorage.getStorage("programDetails");
    const userDetails: any = await LocalStorage.getStorage("user");
    const countryCode: any = await LocalStorage.getStorage("countryCode");
    setCountryCode(countryCode);
    setProgramData(programDetails);
    setuserData(userDetails);
  };

  const ValidateEmail = (email: string) => {
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
      setIsValidEmail(true);
    } else {
      setIsValidEmail(false);
    }
  };

  const handleMobileChange = (mobileNumber: string) => {
    setMobileNumber(mobileNumber);
    setUserName(mobileNumber);
  };

  const handleUsernameChange = (name: string) => {
    ValidateEmail(name);
    setUserName(name);
  };

  const handleCountryCodeChange = (countryCode: string) => {
    setCountryCode(countryCode);
    // googleWebAnalytics("Entity_Roles_Member_CountryCode", "Button", "Entity");
  };

  const getUserDetails = async () => {
    const error = auxiliaryMethods.handleValidatePhoneNumber(mobileNumber, minLength, maxLength);
    if (error.length !== 0) {
      setErrorMessage(error);
      return;
    }
    setErrorMessage("");

    const payload = {
      userList: [
        {
          countryCode: `+${countryCode}`,
          userName: userName,
          userType: isMobileInputSelected ? "PHONE_NUMBER" : "EMAIL_ID"
        }
      ]
    };

    const getUserDetailsResponse = await ApiClient.post(
      springshedEndPoints.getProfileFromPhoneOrEmail(),
      payload
    );

    if (getUserDetailsResponse.responseCode === HTTP_STATUS_CODES.ok) {
      if (getUserDetailsResponse.response.length > 0) {
        const keys = Object.keys(getUserDetailsResponse.response[0]);
        if (getUserDetailsResponse.response[0][keys] !== null) {
          setSelectedUser(getUserDetailsResponse.response[0][keys[0]]);
          // googleWebAnalytics("Entity_Roles_Tick_Mobile_Number", "Button", "Entity");
        } else {
          toast.error("User not registered");
        }
      } else {
        toast.error(getUserDetailsResponse?.message);
      }
    } else {
      toast.error(getUserDetailsResponse?.message);
    }
  };


  const handleAddAdmin = async () => {
    setLoading(true);
    try {
      const request = {
        ...props.payload,
        adminId: selectedUser.userId,
        userId: UserHelper.getUserId(),
        createdBy: UserHelper.getUserName()
      };

      console.log("requestststst", request);
      const MemberStatusResponse = await AdminsRepository.addEntityAdmin(request).catch(err => {
        toast.error(
          dataHelper.replaceText(MESSAGES.ERROR.CREATE, {
            ["{type}"]: "Entity Admin",
            ["{name}"]: selectedUser?.name
          })
        );
      });
      if (MemberStatusResponse) {
        toast.success(
          dataHelper.replaceText(MESSAGES.SUCCESS.CREATE, {
          ["{type}"]: "Entity Admin",
          ["{name}"]: selectedUser?.name
        }));
        setLoading(false);
        props.onSuccess();
      }
    } catch (error) {
      setLoading(false);
      toast.error(
        dataHelper.replaceText(MESSAGES.ERROR.CREATE, {
          ["{type}"]: "Entity Admin",
          ["{name}"]: selectedUser?.name
        })
      );
    }
  };

  const getScannedUserHandler = (userData: object) => {
    if (userData?.deletedByUser === true) {
      toast.error("User not registered");
      return;
    }
    setModalLoading(false);
    setQrCodeUserDetails(false);
    setSelectedUser(userData);
  };

  const handleDropdown = (item: any, e: any) => {
    setUserName("");
    setMobileNumber("");
    setEmail("");
    if (item.value === "Mobile number") {
      setIsMobileInputSelected(true);
      setLoginOptionsList([OPTIONS_MODE[1]]);
    } else {
      setIsMobileInputSelected(false);
      setLoginOptionsList([OPTIONS_MODE[0]]);
    }
    setLoginModeList([item]);
  };

  const renderDropdownHandler = () => (
    <View style={{ width: 305, marginLeft: 10 }}>
      <Text
        fontWeight={FontWeight.Regular}
        testId="qualificationTitleText"
        textSize={TextSize.Small}
        textStyle={styles.searchText}
      >
        Search by Mobile number or Email ID
      </Text>
      <Select
        isClearable={false}
        value={loginModeList}
        defaultValue={loginModeList}
        onChange={(item, e) => handleDropdown(item, e)}
        placeholder={"Select Mode"}
        options={loginOptionsList}
        isSearchable={false}
        menuPortalTarget={document.body}
        styles={customStyles}
        components={{
          IndicatorSeparator: () => null,
          Option: IconOption,
          SingleValue
        }}
      />
    </View>
  );

  return (
    <>
      <ToastContainer />
      {!selectedUser && (
        <View>
          <View style={styles.container}>
            <View style={styles.header}>
              <Text
                fontWeight={FontWeight.Bold}
                testId="addRoleText"
                textSize={TextSize.Small}
                textStyle={styles.headerText}
              >
                Add Administrator
              </Text>
              <View>
                <Icon
                  testID="close"
                  name={IconNames.crossCircle}
                  customStyle={styles.crossCircle}
                  onPress={() => props.onClose()}
                />
              </View>
            </View>
            <View
              style={[
                styles.dropdownContainer,
                { marginBottom: 10, marginLeft: -10, alignItems: "center" }
              ]}
            >
              {renderDropdownHandler()}
            </View>
            <View
              style={
                mobileNumber.length > 2 || isValidEmail
                  ? [styles.alignSearchTextField, { marginLeft: 59 }]
                  : [styles.alignSearchTextField]
              }
            >
              <View style={{ flexDirection: "row" }}>
                {isMobileInputSelected ? (
                  <MobileInput
                    dropdownPlaceholder="+91"
                    label=""
                    handleChange={(mobile: string) => {
                      handleMobileChange(mobile);
                      setErrorMessage("");
                      handleUsernameChange(mobile);
                    }}
                    onChangeValue={(countryCode: string) => handleCountryCodeChange(countryCode)}
                    handleBlur={() => {}}
                    name="mobileNumber"
                    placeholder="Enter mobile number"
                    dropdownValue={countryCode}
                    value={mobileNumber}
                    id="mobileNumber"
                    containerStyle={styles.containerStyle}
                    noFormik
                    setValidate={setValidate}
                    dropDownContainerStyle={styles.dropDownContainerStyle}
                  />
                ) : (
                  <View style={{ height: 55 }}>
                    <TextField
                      inputStyle={[
                        styles.input,
                        {
                          borderTopLeftRadius: 5,
                          borderBottomLeftRadius: 5,
                          width: 304
                        }
                      ]}
                      name={email}
                      placeholder={"Enter Email ID"}
                      label={""}
                      value={email}
                      id={"email"}
                      handleChange={(val: string) => {
                        // handleMobileChange(val);
                        setErrorMessage("");
                        handleUsernameChange(val);
                        setEmail(val);
                      }}
                      handleKeyPress={(e: any) => {
                        if (e.key === "Enter") {
                          getUserDetails();
                        }
                      }}
                      handleBlur={() => {}}
                      noFormik
                    ></TextField>
                  </View>
                )}
                <View style={styles.errorContainer}>
                  <Text
                    fontWeight={FontWeight.Regular}
                    testId="internal"
                    textSize={TextSize.Small}
                    textStyle={[styles.subheaderText, styles.errorText]}
                  >
                    {errorMessage}
                  </Text>
 
                </View>
                {isValidEmail && (
                  <View style={styles.emailIconContainer}>
                    <TouchableOpacity>
                      <Icon
                        name={IconNames.close}
                        customStyle={styles.iconStyle}
                        onPress={() => {
                          setEmail("");
                          setUserName("");
                        }}
                      />
                    </TouchableOpacity>
                    <TouchableOpacity>
                      <Icon
                        name={IconNames.tick}
                        customStyle={styles.iconStyle}
                        onPress={() => getUserDetails()}
                      />
                    </TouchableOpacity>
                  </View>
                )}
                {mobileNumber && mobileNumber.length > 2 && (
                  <View style={styles.iconContainer}>
                    <TouchableOpacity>
                      <Icon
                        name={IconNames.close}
                        customStyle={styles.iconStyle}
                        onPress={() => setMobileNumber("")}
                      />
                    </TouchableOpacity>
                    <TouchableOpacity>
                      <Icon
                        name={IconNames.tick}
                        customStyle={styles.iconStyle}
                        onPress={() => getUserDetails()}
                      />
                    </TouchableOpacity>
                  </View>
                )}
              </View>
            </View>
            <View style={[styles.textFieldContainer]}>
              <Text
                fontWeight={FontWeight.Regular}
                testId="addRoleText"
                textSize={TextSize.Small}
                textStyle={styles.orText}
              >
                -OR-
              </Text>
            </View>
            <View style={[styles.alignTextField, styles.alignScanButton]}>
              <TouchableOpacity
                onPress={() => {
                  setQrCodeUserDetails(true), setModalLoading(true);
                }}
              >
                <Text
                  fontWeight={FontWeight.Regular}
                  testId="addRoleText"
                  textSize={TextSize.Small}
                  textStyle={styles.scanText}
                >
                  Scan QR code
                </Text>
              </TouchableOpacity>
            </View>
            {qrCodeUserDetails && (
              <View>
                <QrCodeScannerModal
                  onCloseScannerModal={() => setQrCodeUserDetails(!qrCodeUserDetails)}
                  modalLoading={modalLoading}
                  getScannedUserHandler={getScannedUserHandler}
                />
              </View>
            )}
          </View>
        </View>
      )}

      {selectedUser && (
        <>
          {loading ? (
            <Loader customLoadingContainer={styles.customLoadingContainer} />
          ) : (
            <>
              <View style={styles.header2}>
                <Icon
                  testID="close"
                  name={IconNames.crossCircle}
                  customStyle={styles.crossIcon}
                  onPress={() => props.onClose()}
                />
              </View>
              <View style={styles.subContainer}>
                <View style={styles.profilePictureContainer}>
                  <View style={styles.profilePicture}>
                    <Image
                      imageStyle={styles.profilePicture}
                      testId="socionImg"
                      source={
                        selectedUser?.photo === ""
                          ? require("@socion-cordio/common/src/assets/images/user_circle.svg")
                          : selectedUser?.photo
                      }
                    />
                  </View>
                </View>
                <View style={styles.memberInfoContainer}>
                  <Text
                    fontWeight={FontWeight.Bold}
                    testId="addRoleText"
                    textSize={TextSize.Small}
                    textStyle={styles.headerText}
                  >
                    {selectedUser.name}
                  </Text>
                  <View style={styles.userDetailsContainer}>
                    <Text
                      fontWeight={FontWeight.Regular}
                      testId="addRoleText"
                      textSize={TextSize.Small}
                      textStyle={[styles.alignText]}
                    >
                      {"Contact Number : "}
                    </Text>
                    <Text
                      fontWeight={FontWeight.Regular}
                      testId="addRoleText"
                      textSize={TextSize.Small}
                      textStyle={[styles.alignText]}
                    >
                      {selectedUser.piiInfo
                        ? `${selectedUser.countryCode} ${selectedUser.phoneNumber}`
                        : `NA`}
                    </Text>
                  </View>
                  <View style={styles.userDetailsContainer}>
                    <Text
                      fontWeight={FontWeight.Regular}
                      testId="addRoleText"
                      textSize={TextSize.Small}
                      textStyle={[styles.alignText]}
                    >
                      {"Contact Email : "}
                    </Text>
                    <Text
                      fontWeight={FontWeight.Regular}
                      testId="addRoleText"
                      textSize={TextSize.Small}
                      textStyle={[styles.alignText]}
                    >
                      {selectedUser.piiInfo
                        ? selectedUser.emailId !== ""
                          ? selectedUser.emailId
                          : ""
                        : `NA`}
                    </Text>
                  </View>
                  <Text
                    fontWeight={FontWeight.Regular}
                    testId="addRoleText"
                    textSize={TextSize.Small}
                    textStyle={[styles.alignText]}
                  >
                    Base location :
                  </Text>
                  <Text
                    fontWeight={FontWeight.Regular}
                    testId="addRoleText"
                    textSize={TextSize.Small}
                    textStyle={[styles.alignText, styles.textColor]}
                  >
                    {auxiliaryMethods.fromatBaseLocation(selectedUser) === ""
                      ? "None"
                      : auxiliaryMethods.fromatBaseLocation(selectedUser)}
                  </Text>
                  <View style={styles.checkboxContainer}>
                    <Checkbox
                      testId=""
                      selected={true}
                      type={CheckboxType.Square}
                      text="Entity administrator"
                      textSize={TextSize.Large}
                      textFontWeight={FontWeight.Regular}
                      textStyle={styles.checkboxText}
                    />
                  </View>
                </View>
              </View>
              <View style={styles.buttonContainer}>
                <Button
                  type={ButtonType.Primary}
                  buttonStyles={styles.button}
                  textStyles={styles.buttonFont}
                  title="Add Administrator"
                  onPress={handleAddAdmin}
                />
              </View>
            </>
          )}
        </>
      )}
    </>
  );
}

const styles = StyleSheet.create({
  container: {
    width: 655
  },
  customLoadingContainer: {
    width: 655,
    height: 350
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row",
    borderBottomWidth: 1,
    borderColor: colorPallete.cordioTaupeLight3,
    paddingBottom: 10
  },
  header2: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row"
  },
  scanText: {
    fontSize: 12,
    fontFamily: FontFamily.Regular,
    lineHeight: 15,
    fontWeight: "600",
    textDecorationLine: "underline",
    color: colorPallete.textLight
  },
  orText: {
    fontSize: 11,
    fontFamily: FontFamily.Regular,
    lineHeight: 13
  },
  headerText: {
    fontSize: 14,
    fontFamily: FontFamily.Medium,
    lineHeight: 17
  },
  crossCircle: {
    fontSize: 15
  },

  memberInfoContainer: {
    flex: 80
  },
  iconStyle: {
    color: colorPallete.cordioTaupe,
    marginLeft: 5,
    marginRight: 5,
    fontSize: 15
  },
  iconContainer: {
    flexDirection: "row",
    marginTop: 13,
    justifyContent: "center"
  },
  emailIconContainer: {
    flexDirection: "row",
    marginTop: 12,
    justifyContent: "center"
  },
  searchText: {
    fontWeight: "400",
    fontSize: 11,
    fontFamily: FontFamily.Regular,
    lineHeight: 13,
    color: colorPallete.textBlack,
    marginTop: 30,
    marginLeft: 52,
    marginBottom: 10
  },
  subheaderText: {
    fontWeight: "400",
    fontSize: 14,
    fontFamily: FontFamily.Regular,
    lineHeight: 17,
    color: colorPallete.textLight,
    marginTop: 13,
    marginRight: 35
  },
  alignTextField: {
    alignItems: "center",
    marginVertical: 25
  },
  alignSearchTextField: {
    alignItems: "center",
    flexDirection: "initial",
    justifyContent: "center"
    // marginLeft: 59,
    // marginVertical: 25
  },
  textFieldContainer: {
    alignItems: "center",
    marginTop: 20,
    zIndex: -1
  },
  subContainer: {
    flexDirection: "row",
    marginBottom: 25,
    width: 655
  },
  profilePictureContainer: {
    flex: 20,
    alignItems: "center"
  },
  profilePicture: {
    borderRadius: 54,
    width: " 80px",
    height: "80px"
  },
  alignText: {
    fontSize: 14,
    color: colorPallete.textLight,
    marginTop: 10,
    FontFamily: FontFamily.Medium,
    fontWeight: "400"
  },
  textColor: {
    color: colorPallete.textBlack,
    fontFamily: FontFamily.Regular,
    fontWeight: "700"
  },
  button: {
    width: "140px",
    height: "50px",
    borderRadius: 10
  },
  buttonFont: {
    fontSize: 12
  },
  buttonContainer: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "1.5rem"
  },
  checkboxContainer: {
    marginTop: 40
  },
  checkboxText: {
    paddingLeft: 10,
    fontSize: 14
  },
  crossIcon: {
    fontSize: 15,
    marginLeft: 700,
    marginBottom: 49
  },


  containerStyle: {
    borderColor: colorPallete.cordioTaupe,
    borderWidth: 1,
    borderTopLeftRadius: 5,
    borderBottomLeftRadius: 5,
    height: 40,
    marginBottom: 0
  },
  errorText: {
    marginTop: 2,
    justifyContent: "center",
    color: colorPallete.cordioRed,
    fontSize: 12,
    marginRight: 0
  },
  errorContainer: {
    position: "absolute",
    top: 41,
    marginLeft: 2,
    zIndex: -1
  },
  userDetailsContainer: {
    flexDirection: "row"
  },
  alignScanButton: {
    zIndex: -1,
    marginTop: 25,
    marginBottom: 10
  },
  dropDownContainerStyle: {
    marginTop: 10,
    width: 303,
    zIndex: 10
  },
  dropdownContainer: {
    position: "relative",
    zIndex: 2
    // width: 200
  },

  input: {
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    borderTopRightRadius: 5,
    borderBottomRightRadius: 5,
    width: 233,
    height: 40,
    // outline: "none",
    borderColor: colorPallete.lightGreyTwo
    // opacity: 0.4
  }
});

const customStyles = {
  control: (styles: any, state: any) => ({
    ...styles,
    backgroundColor: "white",
    borderColor: colorPallete.cordioTaupeLight3,
    boxShadow: "none",
    fontFamily: FontFamily.Bold,
    color: colorPallete.textBlack,
    fontSize: 14,
    cursor: "pointer",
    minHeight: 40,
    // marginLeft: -10,  // to adjust the dropdown
    ":hover": {
      borderColor: colorPallete.cordioTaupeLight3
    }
  }),
  menuList: (base: any) => ({
    ...base,
    fontFamily: FontFamily.Bold,
    backgroundColor: colorPallete.lightGrey,
    marginTop: -10
  }),
  option: (styles: any, { data, isDisabled, isFocused, isSelected }: any) => ({
    ...styles,
    padding: 8,
    paddingLeft: 10,
    paddingRight: 10,
    backgroundColor: isSelected ? colorPallete.lightGreyThree : colorPallete.lightGrey,
    color: colorPallete.textBlack,
    cursor: "pointer",
    ":hover": {
      backgroundColor: colorPallete.lightGreyTwo
    }
  }),
  singleValue: (styles: any, { data }: any) => ({
    ...styles,
    fontSize: 14
  }),
  menuPortal: (base: any) => ({ ...base, zIndex: 9999 })
};

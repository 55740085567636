import React, { ReactElement, useEffect, useState } from "react";
import {
  ScrollView,
  StyleSheet,
  TouchableOpacity,
  View,
  TextInput,
  FlatList,
  RefreshControl,
  TouchableWithoutFeedback
} from "react-native";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import {
  Text,
  TextSize,
  FontWeight,
  FontFamily
} from "@socion-cordio/common/src/components/atoms/text";
import { colorPallete } from "@socion-cordio/common/src/assets/styles/colors";
import Icon, { IconNames } from "@socion-cordio/common/src/components/atoms/icon";
import { HTTP_STATUS_CODES } from "@socion-cordio/common/src/network/constants";
import Loader from "@socion-cordio/common/src/components/atoms/loader";
import { LocalStorage } from "@socion-cordio/common/src/services/storage/storageService";
import { ApiClient } from "@socion-cordio/common/src/network/apiClient";
import {
  profileEndPoints,
  springshedEndPoints
} from "@socion-cordio/common/src/repositories/endPoints";
import ProgramDropdown from "@socion-cordio/common/src/components/organisms/accomplishment/components/programDropdown";
import Button, { ButtonType } from "@socion-cordio/common/src/components/atoms/button";
import DropdownPicker from "@socion-cordio/common/src/components/molecules/dropdownPicker";

export default function AdditionalProfileInfo(): ReactElement {
  const urlParams = window.location.pathname.split("/").reverse();
  const programName = urlParams[3];
  const programId = urlParams[2];
  const userId: any = urlParams[1];
  const accessToken: any = urlParams[0];
  const [loading, setLoading] = useState(false);
  const [mainLoading, setMainLoading] = useState(true);
  const [userData, setUserData] = useState({ userToken: null, userId: null });
  const [selectedValues, setSelectedValues] = useState([]);
  const [eligiblePrograms, setEligiblePrograms] = useState([]);
  const [refreshing, setRefreshing] = React.useState(false);
  const [dropDownSelectedProgram, setDropDownSelectedProgram] = useState({
    label: "", //programName?.replaceAll("%20", " "),
    value: "" //programId
  });
  const [selectedProgram, setSelectedProgram] = useState({
    label: "", //programName?.replaceAll("%20", " "),
    value: "" //programId
  });
  const [options, setOptions] = useState([]);
  const [badgeClassData, setBadgeClassData] = useState([]);
  const [dropdownPress, setDropdownPress] = useState(false);
  const [clickedBadgeType, setClickedBadgeType] = useState(null);
  const [previousSelectedBadges, setPreviousSelectedBadges] = useState([]);
  const [selectedValuesCopy, setSelectedValuesCopy] = useState([]);
  let showSubmitButton: boolean =
    previousSelectedBadges?.length > 0 || selectedValuesCopy?.length > 0;

  const headers = {
    "access-token": accessToken,
    accept: "application/json"
  };

  useEffect(() => {
    // LocalStorage.clearStorage();
    setUserData({ ...userData, userToken: accessToken });
    setUserData({ ...userData, userId: userId });
  }, [userId, accessToken]);

  useEffect(() => {
    setAccessToken().then(() => {
      getEligibleProgramsData();
    });
  }, [userId]);

  useEffect(() => {
    // setAccessToken().then(() => {
    getBadgesDetails();
    getSelectedBadgeData();
    // });
    setDropdownPress(false);
    setSelectedValues([]);
    setSelectedValuesCopy([]);
  }, [dropDownSelectedProgram, userId]);

  const setAccessToken = async () => {
    setLoading(true);
    let localAccessToken: any = await LocalStorage.getStorage("accessToken");
    if (localAccessToken !== null) {
      return;
    }
    try {
      const getUserDetailsResponse: any = await axios.get(
        springshedEndPoints.getProfileByUserId(userId),
        { headers: headers }
      );
      await LocalStorage.setStorage("user", getUserDetailsResponse?.data);
      setLoading(false);
    } catch (error) {
      toast.error(error);
      setLoading(false);
    }

    await LocalStorage.setStorage("accessToken", accessToken);
    setLoading(false);
  };

  const getSelectedBadgeData = async () => {
    setLoading(true);
    let selectedBadgeData = await ApiClient.get(
      profileEndPoints.getSelectedBadges(selectedProgram?.value)
    );
    if (selectedBadgeData.responseCode === HTTP_STATUS_CODES.ok) {
      let arr: any = [];
      selectedBadgeData?.response.map((item: any) => {
        const data = {
          badgeClassId: item?.badge?.badgeClass?.badgeClassId,
          badgeId: item?.badge?.badgeId,
          badgeName: item?.badge?.badgeName,
          programId: item?.programId,
          userId: item?.userId,
          badgeAssignedId: item?.badgeAssignedId,
          status: item?.badgeAssignedStatus,
          mandatory: item?.badge?.badgeClass?.mandatory,
          badgeStatus: item?.badge?.badgeStatus
        };
        arr.push(data);
      });
      setSelectedValues(arr);
      setSelectedValuesCopy(arr);
      setLoading(false);
    } else {
      toast.error(selectedBadgeData.message);
      setLoading(false);
    }
  };

  const getEligibleProgramsData = async () => {
    setMainLoading(true);
    let eligibleProgramsData = await ApiClient.get(
      profileEndPoints.getUserEligibleProgramsForBadges()
    );
    if (eligibleProgramsData?.responseCode === HTTP_STATUS_CODES.ok) {
      setEligiblePrograms(eligibleProgramsData?.response);
      let optionsData = eligibleProgramsData?.response.map((item: any) => {
        return { label: item.name, value: item.id };
      });
      let selectedProgramId = optionsData?.filter((k: any) => k.value == programId);
      setOptions(optionsData);
      // setDropDownSelectedProgram(selectedProgramId[0]);
      setSelectedProgram(selectedProgramId[0]);
      setMainLoading(false);
    } else {
      setMainLoading(false);
    }
  };
  const getBadgesDetails = async () => {
    setLoading(true);
    let badgesClass = await ApiClient.get(
      profileEndPoints.getUserEligibleBadgesDetails(selectedProgram?.value)
    );
    if (badgesClass.responseCode === HTTP_STATUS_CODES.ok) {
      setBadgeClassData(badgesClass?.response);
      setLoading(false);
    } else {
      toast.error(badgesClass.message);
      setLoading(false);
    }
  };

  const renderBadgeList = (
    item: any,
    badgeClassId: number,
    valueType: string,
    badgeStatus: string
  ) => {
    return (
      <View>
        {selectedValues?.find((val) => val.badgeId == item?.badgeId) ? null : (
          <TouchableOpacity
            onPress={() => handleValuePress(item, badgeClassId, valueType, badgeStatus)}
          >
            <Text
              fontWeight={FontWeight.Regular}
              testId="forgot"
              textSize={TextSize.Small}
              textStyle={styles.placeHolderStyle}
            >
              {item.badgeName}
            </Text>
          </TouchableOpacity>
        )}
      </View>
    );
  };

  const renderEmptyList = (messageValue: string) => {
    return (
      <TouchableOpacity
        onPress={() => {
          setDropdownPress(!dropdownPress);
          setClickedBadgeType(null);
        }}
        style={{ margin: 5 }}
      >
        <Text
          fontWeight={FontWeight.Regular}
          testId="forgot"
          textSize={TextSize.Small}
          textStyle={[styles.placeHolderStyle, { margin: 0, fontSize: 12 }]}
        >
          {messageValue}
        </Text>
      </TouchableOpacity>
    );
  };

  const handleValuePress = (
    item: any,
    badgeClassId: number,
    valueType: string,
    badgeStatus: string
  ) => {
    const badgeData: any = {
      badgeClassId: badgeClassId,
      badgeId: item?.badgeId,
      badgeName: item?.badgeName,
      programId: selectedProgram?.value,
      userId: userData?.userId,
      badgeAssignedId: null,
      mandatory: item?.badgeClass?.mandatory,
      status: null,
      badgeStatus: item?.badgeStatus
    };
    if (badgeStatus === "Deactivated") {
      setDropdownPress(!dropdownPress);
      setClickedBadgeType(null);
      return;
    }
    if (valueType === "Single Value") {
      if (selectedValues?.length !== 0) {
        selectedValues?.find((val, i) => {
          if (val.badgeClassId === badgeClassId) {
            let arr = [...selectedValues];
            let selectedValueArray = selectedValuesCopy?.filter(
              (val) => val?.badgeId == arr[i]?.badgeId
            );
            if (previousSelectedBadges?.length !== 0) {
              previousSelectedBadges?.find((val) => {
                if (item?.badgeId == val?.badgeId) {
                  let newArray = previousSelectedBadges?.filter(
                    (obj) => obj.badgeId !== val?.badgeId
                  );
                  setPreviousSelectedBadges(newArray);
                  return true;
                } else if (badgeClassId == val?.badgeClassId) {
                  return;
                } else {
                  let previousBadges: any = [...previousSelectedBadges];
                  let badgeObject = arr[i];
                  badgeObject.badgeAssignedId = selectedValueArray[0]?.badgeAssignedId;
                  badgeObject.status = "EXPIRED";
                  previousBadges.push(badgeObject);
                  setPreviousSelectedBadges(previousBadges);
                }
              });
            } else {
              if (selectedValuesCopy?.length > 0 && selectedValueArray[0]?.badgeAssignedId) {
                let previousBadges: any = [...previousSelectedBadges];
                let badgeObject = arr[i];
                badgeObject.badgeAssignedId = selectedValueArray[0]?.badgeAssignedId;
                badgeObject.status = "EXPIRED";
                previousBadges.push(badgeObject);
                setPreviousSelectedBadges(previousBadges);
              }
            }
            arr[i] = {
              ...arr[i],
              badgeId: item?.badgeId,
              badgeName: item?.badgeName,
              badgeAssignedId: null,
              status: null,
              badgeStatus: item?.badgeStatus
            };
            setSelectedValues(arr);
            return true;
          } else {
            let arr = [...selectedValues];
            arr.push(badgeData);
            setSelectedValues(arr);
          }
        });
      } else {
        let allValuesArray = [...selectedValues];
        allValuesArray.push(badgeData);
        setSelectedValues(allValuesArray);
      }
    } else if (valueType === "Multiple Values") {
      let allValuesArray = [...selectedValues];
      allValuesArray.push(badgeData);
      setSelectedValues(allValuesArray);
      previousSelectedBadges?.find((val) => {
        if (val.badgeId == item?.badgeId) {
          let newArr = previousSelectedBadges.filter((obj) => obj.badgeId !== item.badgeId);
          setPreviousSelectedBadges(newArr);
          return true;
        }
      });
    } else {
      toast.error("Something Went Wrong. Please Try Again");
    }
    setDropdownPress(!dropdownPress);
    setClickedBadgeType(null);
  };

  const handleRolePress = (item: any, status: string) => {
    if (status === "Deactivated") {
      return;
    }
    let selectedValuesArray = selectedValuesCopy?.find((val) => val?.badgeId == item?.badgeId);
    let previousBadgeArray = previousSelectedBadges?.find((val) => val.badgeId == item.badgeId);
    if (selectedValuesArray) {
      if (previousBadgeArray) {
        let updatedArray = previousSelectedBadges.filter((val) => val.badgeId !== item.badgeId);
        setPreviousSelectedBadges(updatedArray);
        return;
      } else {
        let previousBadge: any = [...previousSelectedBadges];
        let selectedValue = selectedValuesCopy?.filter((val) => val?.badgeId == item?.badgeId)[0];
        let badgeObject = item;
        badgeObject.badgeAssignedId = selectedValue?.badgeAssignedId;
        badgeObject.status = "EXPIRED";
        previousBadge.push(badgeObject);
        setPreviousSelectedBadges(previousBadge);
      }
    }
    let newArray = [...selectedValues];
    newArray = selectedValues?.filter((val) => val.badgeId !== item?.badgeId);
    setSelectedValues(newArray);
  };

  const getDifference = () => {
    return selectedValues.filter((val) => {
      return !selectedValuesCopy.some((obj) => {
        return val.badgeId === obj.badgeId;
      });
    });
  };

  const handleValidation = () => {
    let mandatoryArray = badgeClassData?.filter((val) => val?.badgeList.length > 0);
    let result: any = selectedValues.filter((val) =>
      mandatoryArray.some((obj) => val.badgeClassId === obj.badgeClass.badgeClassId)
    );
    result = result.filter(
      (value: any, index: any, self: any) =>
        index === self.findIndex((val: any) => val.badgeClassId === value.badgeClassId)
    );
    if (mandatoryArray?.length !== result?.length) {
      return false;
    } else {
      return true;
    }
  };

  const handleSubmit = async () => {
    const mandatoryMessage: string = "Please select all mandatory fields";
    let mandatoryCondition: boolean = handleValidation();
    if (!mandatoryCondition) {
      // isPopup ? setBadgeError(mandatoryMessage) : showErrorMessage(mandatoryMessage);
      toast.error(mandatoryMessage);
      return;
    }
    let payloadObject: Array<Object> = getDifference();
    let updateBadges: any;
    const payload = payloadObject.concat(previousSelectedBadges);
    setLoading(true);
    {
      showSubmitButton
        ? (updateBadges = await ApiClient.put(profileEndPoints.updateUserBadgeClass(), payload))
        : (updateBadges = await ApiClient.post(
            profileEndPoints.updateUserBadgeClass(),
            selectedValues
          ));
    }
    if (updateBadges.responseCode === HTTP_STATUS_CODES.ok) {
      toast.success(updateBadges.message);
      setPreviousSelectedBadges([]);
      setSelectedValues([]);
      setSelectedValuesCopy([]);
      getBadgesDetails();
      getSelectedBadgeData();
      setLoading(false);
    } else {
      toast.error(updateBadges.message);
      setLoading(false);
    }
  };

  const onProgramPressHandler = (selectedItem: any) => {
    setSelectedProgram(selectedItem);
    setDropDownSelectedProgram(selectedItem);

    //close save cancel button
    setPreviousSelectedBadges([]);
    setDropdownPress(false);
  };

  const renderMainComponent = () => (
    <View style={styles.mainContainer}>
      <ToastContainer />
      <View style={styles.container}>
        {mainLoading ? (
          // loading
          <Loader customLoadingContainer={styles.customLoadingContainer} />
        ) : (
          <>
            <View style={styles.leftContainer}>
              <View style={styles.subLeftContainer}>
                <View style={{ marginBottom: 15 }}>
                  <Text
                    onPress={() => {}}
                    fontWeight={FontWeight.Regular}
                    testId="text"
                    textSize={TextSize.Small}
                    textStyle={[styles.headerText]}
                  >
                    {"Additional Profile Info"}
                  </Text>
                </View>
                {/* <ProgramDropdown
              selectedProgram={selectedProgram}
              setSelectedProgram={setSelectedProgram}
              setDropDownSelectedProgram={setDropDownSelectedProgram}
              options={options}
            /> */}
                {options.length === 0 ? (
                  <View style={{ marginLeft: -5 }}>{renderEmptyList("No available programs")}</View>
                ) : (
                  options?.map((item: any, index: number) => (
                    <View style={{ marginBottom: 10 }}>
                      <TouchableOpacity
                        style={{ marginBottom: 10 }}
                        onPress={() => {
                          onProgramPressHandler(item);
                        }}
                      >
                        <View
                          style={[
                            styles.programContainer,
                            {
                              backgroundColor:
                                selectedProgram?.value === item.value
                                  ? colorPallete.cordioBeige
                                  : colorPallete.white
                            }
                          ]}
                        >
                          <Text
                            fontWeight={FontWeight.Regular}
                            testId="text"
                            textSize={TextSize.Small}
                            textStyle={[styles.programHeaderText]}
                          >
                            {item.label}
                          </Text>
                          <Icon
                            name={IconNames.rightArrow}
                            color={colorPallete.cordioRed}
                            customStyle={{}}
                            size={17}
                          />
                        </View>
                      </TouchableOpacity>
                      {options.length - 1 !== index && (
                        <View
                          style={[
                            styles.borderContainer,
                            { borderBottomWidth: 1, borderBottomColor: colorPallete.cordioTaupe }
                          ]}
                        ></View>
                      )}
                    </View>
                  ))
                )}
              </View>
            </View>

            {options.length !== 0 && (
              <View
                style={[
                  styles.borderContainer,
                  { borderRightWidth: 1, borderRightColor: colorPallete.cordioTaupe }
                ]}
              ></View>
            )}
            <View style={styles.cardsContainer}>
              {loading && selectedProgram && selectedProgram.value !== "" ? (
                <Loader customLoadingContainer={styles.customLoadingContainer} />
              ) : (
                <ScrollView
                  style={[
                    styles.innerContainer,
                    { marginTop: 12, paddingHorizontal: 18, zIndex: -1 }
                  ]}
                  contentContainerStyle={{
                    flexGrow: 1,
                    paddingBottom: 20
                    // justifyContent: "space-between"
                  }}
                  showsHorizontalScrollIndicator={false}
                  bounces={false}
                  // refreshControl={<RefreshControl refreshing={refreshing} onRefresh={() => {toast.success("worked")}} />}
                >
                  <View>
                    {selectedProgram && selectedProgram.value !== "" && (
                      <View style={{ marginBottom: 15 }}>
                        <Text
                          fontWeight={FontWeight.Regular}
                          testId="text"
                          textSize={TextSize.Small}
                          textStyle={[styles.programHeaderText]}
                        >
                          {
                            "This below information is mandatory to receive attestations under this program"
                          }
                        </Text>
                      </View>
                    )}
                    {badgeClassData?.map((item: any) => {
                      let value = selectedValues?.filter((val) => {
                        return item?.badgeClass?.badgeClassId === val?.badgeClassId;
                      });
                      if (
                        (item?.badgeClass?.status === "Deactivated" && value?.length === 0) ||
                        item?.badgeClass?.status === "Draft" ||
                        (item?.badgeList.length == 0 && value?.length === 0)
                      ) {
                        return;
                      }
                      return (
                        <View
                          style={{
                            // backgroundColor:
                            //   item?.badgeClass?.status === "Deactivated" &&
                            //   colorPallete.cordioLightGreySix,
                            // backgroundColor: "lightblue",
                            paddingBottom: 8,
                            borderRadius: 4,
                            flexDirection: "row",
                            marginBottom: 5
                          }}
                        >
                          <View style={{ flex: 0.3 }}>
                            <Text
                              testId="accomplishment"
                              fontWeight={FontWeight.Regular}
                              textSize={TextSize.Small}
                              style={[styles.heading, { fontSize: 14 }]}
                            >
                              {item?.badgeClass?.badgeClassName}
                            </Text>
                          </View>

                          <View style={{ flex: 0.7 }}>
                            <View>
                              {item?.badgeClass?.valueType === "Multiple Values" &&
                                value?.map((val) => {
                                  return (
                                    <View style={styles.rolesSelected}>
                                      <Text
                                        fontWeight={FontWeight.Medium}
                                        testId="forgot"
                                        textSize={TextSize.Small}
                                        textStyle={styles.rolesText}
                                      >
                                        {val?.badgeName}
                                      </Text>
                                      <View style={{ alignSelf: "center" }}>
                                        <Icon
                                          testID="home"
                                          name={IconNames.crossCircle}
                                          size={16}
                                          onPress={() =>
                                            handleRolePress(val, item?.badgeClass?.status)
                                          }
                                        />
                                      </View>
                                    </View>
                                  );
                                })}
                            </View>

                            <View>
                              <TouchableOpacity
                                onPress={() => {
                                  setDropdownPress(!dropdownPress);
                                  // setDropdownPress(false);
                                  setClickedBadgeType(item?.badgeClass?.badgeClassId);
                                }}
                                disabled={item?.badgeClass?.status === "Deactivated"}
                                activeOpacity={1}
                                style={{}}
                              >
                                <View>
                                  <TextInput
                                    placeholder={"Select"}
                                    editable={false}
                                    style={[
                                      styles.dropdownStyle,
                                      {
                                        borderBottomLeftRadius: dropdownPress ? 0 : 3,
                                        borderBottomRightRadius: dropdownPress ? 0 : 3,
                                        borderColor:
                                          !dropdownPress && value.length === 0
                                            ? "red"
                                            : colorPallete.cordioLightGreyFive,

                                        minHeight:
                                          value[0]?.badgeName?.length > 100 &&
                                          item?.badgeClass?.valueType !== "Multiple Values"
                                            ? 120
                                            : 40
                                      }
                                    ]}
                                    multiline
                                    value={
                                      item?.badgeClass?.valueType !== "Multiple Values"
                                        ? value[0]?.badgeName
                                        : ""
                                    }
                                    placeholderTextColor={colorPallete.textLight}
                                  />
                                  {item?.badgeClass?.status !== "Deactivated" && (
                                    <View style={{ position: "absolute", right: 12, top: 13 }}>
                                      {/* <Icon
                                    name={
                                      dropdownPress &&
                                      item?.badgeClass?.badgeClassId == clickedBadgeType
                                        ? IconNames.up
                                        : IconNames.down
                                    }
                                    size={8}
                                  /> */}
                                      <Icon
                                        name={
                                          dropdownPress &&
                                          item?.badgeClass?.badgeClassId == clickedBadgeType
                                            ? IconNames.up
                                            : !!value[0]?.badgeName
                                            ? IconNames.editSecondary
                                            : IconNames.down
                                        }
                                        size={
                                          dropdownPress &&
                                          item?.badgeClass?.badgeClassId == clickedBadgeType
                                            ? 8
                                            : !!value[0]?.badgeName
                                            ? 15
                                            : 8
                                        }
                                        // color={colorPallete.black}
                                        // onPress={() =>
                                        //   handleIconPress(
                                        //     item?.badgeClass?.badgeClassId,
                                        //     item?.badgeClass?.status === "Deactivated"
                                        //   )
                                        // }
                                      />
                                    </View>
                                  )}
                                </View>
                              </TouchableOpacity>
                              {dropdownPress && item?.badgeClass?.badgeClassId == clickedBadgeType && (
                                <View style={{}}>
                                  <FlatList
                                    data={item?.badgeList}
                                    keyExtractor={() => item?.badgeList?.badgeId}
                                    renderItem={(listItem) =>
                                      value?.length !== item?.badgeList?.length
                                        ? renderBadgeList(
                                            listItem.item,
                                            item?.badgeClass?.badgeClassId,
                                            item?.badgeClass?.valueType,
                                            item?.badgeClass?.status
                                          )
                                        : value[0]?.badgeStatus === "DEACTIVATED"
                                        ? renderBadgeList(
                                            listItem.item,
                                            item?.badgeClass?.badgeClassId,
                                            item?.badgeClass?.valueType,
                                            item?.badgeClass?.status
                                          )
                                        : listItem.index == 0 &&
                                          renderEmptyList("No available badge")
                                    }
                                    style={styles.dropdownContentStyle}
                                    ItemSeparatorComponent={() => (
                                      <View style={styles.separatorStyle} />
                                    )}
                                    ListEmptyComponent={renderEmptyList("No available badge")}
                                    persistentScrollbar={true}
                                    nativeID="customScrollBarId"
                                  />
                                </View>
                              )}
                            </View>
                          </View>
                        </View>
                      );
                    })}
                  </View>
                  {previousSelectedBadges?.length > 0 ||
                  selectedValues?.length !== selectedValuesCopy?.length ? (
                    <View style={styles.buttonContainer}>
                      <Button
                        type={ButtonType.Secondary}
                        buttonStyles={{
                          height: 50,
                          width: 120,
                          backgroundColor: colorPallete.white,
                          marginRight: 25
                        }}
                        title="Cancel"
                        onPress={() => {
                          getSelectedBadgeData();
                          setPreviousSelectedBadges([]);
                          setDropdownPress(false);
                        }}
                      />
                      <Button
                        type={ButtonType.Primary}
                        buttonStyles={{ height: 50, width: 120 }}
                        title="Save"
                        onPress={handleSubmit}
                        // isButtonLoading={loading}
                        disabled={loading}
                      />
                    </View>
                  ) : null}
                </ScrollView>
              )}
            </View>
          </>
        )}
      </View>
    </View>
  );

  return (
    <TouchableWithoutFeedback
      onPress={() => {
        setDropdownPress(false);
      }}
    >
      {renderMainComponent()}
    </TouchableWithoutFeedback>
  );
}

const styles = StyleSheet.create({
  mainContainer: {
    backgroundColor: "#F8F5F0",
    padding: 20,
    paddingTop: 5,
    minHeight: "calc(100vh - 50px)"
  },
  container: {
    flexDirection: "row",
    justifyContent: "center",
    // flex:1,
    backgroundColor: colorPallete.white,
    borderRadius: 20,
    height: "100%",
    //shadow
    shadowColor: colorPallete.cordioRedDark1,
    shadowOpacity: 0.1,
    shadowOffset: {
      height: 10,
      width: 5
    },
    shadowRadius: 10
  },
  leftContainer: {
    flex: 1
  },
  subLeftContainer: {
    marginLeft: 40,
    marginRight: 30,
    marginTop: 40,
    marginBottom: 20
  },
  borderContainer: {
    borderStyle: "solid",
    opacity: 0.1
  },
  customLoadingContainer: {
    alignSelf: "center",
    justifyContent: "center"
  },
  cardsContainer: {
    marginTop: 12,
    zIndex: -1,
    flex: 0.8,
    width: "100%",
    paddingVertical: 12,
    paddingHorizontal: 15,
    marginBottom: 20,
    backgroundColor: colorPallete.white
  },
  heading: {
    fontSize: 12,
    paddingRight: 5,
    color: colorPallete.textBlack,
    paddingVertical: 10,
    fontFamily: FontFamily.Medium
  },
  mandatory: {
    fontSize: 12,
    fontFamily: "Montserrat-Bold",
    paddingRight: 5,
    color: colorPallete.cordioRed,
    paddingVertical: 10
  },
  dropdownStyle: {
    borderRadius: 3,
    borderColor: colorPallete.cordioLightGreyFive,
    width: "100%",
    minHeight: 35,
    borderWidth: 0.9,
    paddingLeft: 5,
    paddingRight: 35,
    fontFamily: FontFamily.Medium,
    fontSize: 12,
    alignItems: "center",
    justifyContent: "center",
    paddingTop: 12,
    flex: 2
  },
  dropdownContentStyle: {
    borderColor: colorPallete.cordioLightGreyFive,
    borderBottomWidth: 0.9,
    borderLeftWidth: 0.9,
    borderRightWidth: 0.9,
    borderRadius: 3,
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    borderTopWidth: 0,
    maxHeight: 120,
    fontFamily: FontFamily.Medium,
    fontSize: 12
  },
  placeHolderStyle: {
    fontSize: 12,
    fontFamily: FontFamily.Medium,
    color: colorPallete.cordioTaupe,
    margin: 5
  },
  separatorStyle: {
    height: 1,
    backgroundColor: "rgba(0, 0, 0, 0.1)"
  },
  rolesText: {
    fontSize: 12,
    fontFamily: FontFamily.Medium,
    color: colorPallete.textBlack,
    paddingVertical: 3,
    paddingLeft: 7
  },
  rolesSelected: {
    borderWidth: 1,
    width: "70%",
    borderRadius: 12,
    borderColor: colorPallete.gray,
    marginBottom: 5,
    flexDirection: "row",
    justifyContent: "space-between",
    paddingHorizontal: 3
  },
  buttonContainer: {
    flexDirection: "row",
    marginTop: 40
    // justifyContent: "space-evenly"
  },
  innerContainer: {
    borderWidth: 0,
    backgroundColor: colorPallete.white,
    borderRadius: 10,
    paddingVertical: 14
  },

  // program
  programContainer: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: colorPallete.cordioBeige,
    padding: 15,
    borderRadius: 10
  },

  // font
  headerText: {
    fontSize: 12,
    fontFamily: FontFamily.Bold,
    lineHeight: 17,
    fontWeight: "700"
  },
  programHeaderText: {
    fontSize: 12,
    fontFamily: FontFamily.Medium,
    lineHeight: 17
    // fontWeight: "400",
  }
});

export const MESSAGES = {
    SUCCESS: {
      CREATE: "Successfully created {type}: {name}",
      UPDATE: "Successfully updated {type}: {name}",
      DELETE: "Successfully deleted {type}: {name}",
      CLOSED: "Successfully closed {type}: {name}",
      DEACTIVATE: "Successfully deactivated {type}: {name}",
      ACTIVATE: "Successfully reactivated {type}: {name}",
      UPLOAD: "Successfully uploaded doc {type}",
      DOC_ADD: "Successfully added doc to {type}: {name}",
      DOC_DELETE: "Successfully delete doc from {type}: {name}"
    },
    ERROR: {
      CREATE: "Failed creating {type}: {name}. Please try again later...",
      UPDATE: "Failed updating {type}: {name}. Please try again later...",
      DELETE: "Failed deleting {type}: {name}. Please try again later...",
      DEACTIVATE: "Failed deactivating {type}: {name}. Please try again later...",
      ACTIVATE: "Failed activating {type}: {name}. Please try again later...",
      UPLOAD: "Failed uploading doc {type}. Please try again later...",
      DOC_ADD: "Failed adding doc to {type}: {name}. Please try again later...",
      DOC_DELETE: "Failed deleting doc from {type}: {name}. Please try again later..."
    },
    INFO: {
      DEACTIVATE: "Are you sure you want to deactivate this {type}: {name} ?",
      ACTIVATE: "Are you sure you want to reactivate this {type}: {name}?"
    }
  };
  
import React, { ReactElement, useState, useEffect, useRef } from "react";
import { StyleSheet, View, ScrollView, TouchableOpacity } from "react-native";
import Geolocation from "react-native-geolocation-service";
import * as RNLocalize from "react-native-localize";
import { colorPallete } from "@socion-cordio/common/src/assets/styles/colors";
import {
  Text,
  TextSize,
  FontWeight,
  FontFamily
} from "@socion-cordio/common/src/components/atoms/text";
import { profileEndPoints } from "@socion-cordio/common/src/repositories/endPoints";
import { ApiClient } from "@socion-cordio/common/src/network/apiClient";
import { HTTP_STATUS_CODES } from "@socion-cordio/common/src/network/constants";
import EmailInput from "@socion-cordio/common/src/components/molecules/emailInput";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import { ProfileActions } from "@socion-cordio/common/src/modules/profile/actions/actions";
import { auxiliaryMethods } from "@socion-cordio/common/src/utils/auxiliaryMethods";
import Moment from "moment";
import AddTelemetryService from "@socion-cordio/common/src/services/telemetryService";
import { LocalStorage } from "@socion-cordio/common/src/services/storage/storageService";
import Loader from "@socion-cordio/common/src/components/atoms/loader";
import ReactDOM from "react-dom";
import { googleWebAnalytics } from "@socion-cordio/web/src/utils/firebaseAnalytics";
// import Mapmyindia from "mapmyindia-restapi-react-native-beta";

// Mapmyindia.setRestApiKey("nsgnhfs11mx7qvg7nvs1zc68fwhm4dqr");
// Mapmyindia.setClientId(
//   "33OkryzDZsLgqMoIblYc703weXdYMO73w7wPq96wmDzcylNlblcNZ03zCi8dQQUZ79IoNlyCsSa24nwxVeD7HkHjYL-A1-sXtJl-11w3yV9M2Otw-RGbxQ=="
// );
// Mapmyindia.setClientSecret(
//   "lrFxI-iSEg8ugz_-DhsZ-0eM-seVJKDa-ND_t_s2qp8BTZircx1RAkz2N38uLtNNtvrNtVV5GvoEFoOmpCFxkAsVI7md7BR3nMpWkuRXwA0ttm8VPh8VUCzoUMpq7I-r"
// );

// const callback_method = (data) => {
//   console.log("placePicker data:", data);
// }

// const obj = new MapmyIndia.placePicker({ location: { lat: 12.974002, lng: 77.613458 }, callback: callback_method });

interface Props {
  onClose: Function;
  modalTitle?: string;
  currentBaseLocation: string;
  setShowRightPanel?: Function;
  setIsLocationEnabled?: Function;
  isLocationEnabled?: boolean;
  locationConsentOnToggle?: boolean;
}

export default function AddBaseLocation(props: Props): ReactElement {
  const {
    currentBaseLocation,
    setShowRightPanel,
    setIsLocationEnabled,
    isLocationEnabled,
    locationConsentOnToggle
  } = props;
  const [baseLocation, setBaseLocation] = useState(currentBaseLocation || "");
  const [lat, setLat] = useState(undefined);
  const [long, setLong] = useState(undefined);
  const [city, setCity] = useState("");
  const [district, setDistrict] = useState("");
  const [state, setState] = useState("");
  const [country, setCountry] = useState("");
  const [subDistrict, setSubDistrict] = useState("");
  const [detectedLanguageCode, setDetectedLanguageCode] = useState(null);
  const [baseLocationError, setBaseLocationError] = useState("");
  const [placeId, setPlaceId] = useState(undefined);
  const [establishmentName, setEstablishmentName] = useState(undefined);
  const [locationArray, setLocationArray] = useState([]);
  const [disableState, setDisableState] = useState(true);
  const [loading, setLoading] = useState(false);
  const [languageCode, setLanguageCode] = useState("en");
  const dispatch = useDispatch();
  const data: any = useSelector((state) => state);

  useEffect(() => {
    console.log("currentBaseLocation:::", currentBaseLocation);
  }, []);

  // useEffect(() => {
  //   console.log("loccccc", baseLocation);
  //   const optional_config = {
  //     width: 330,
  //     height: 150,
  //     tokenizeAddress: true
  //   };
  //   const searched_place = new MapmyIndia.search(
  //     document.getElementById("addBaseLocation"),
  //     optional_config,
  //     callback
  //   );
  //   function callback(data: any) {
  //     if (data) {
  //       console.log("Place Search - for suggestion of places as user type");
  //       console.log(data);
  //     }
  //   }
  // }, []);

  useEffect(() => {
    let countryCode = data?.profile?.userProfileData?.response?.countryCode;
    if (countryCode === "+91") {
      const optional_config = {
        width: 655,
        height: 110,
        tokenizeAddress: true
      };
      document.getElementById("addBaseLocation");
      const searched_place = new MapmyIndia.search(
        document.getElementById("addBaseLocation"),
        optional_config,
        suggestionsCallback
      );
    }
  }, []);

  const updateBaseLocation = async () => {
    setLoading(true);
    const payload = {
      city: city,
      country: country,
      district: district,
      latitude: lat,
      longitude: long,
      state: state,
      subDistrict: subDistrict,
      establishmentName: establishmentName
    };
    const updateBaseLocationResponse = await ApiClient.post(
      profileEndPoints.updateBaseLocation(),
      payload
    );
    if (updateBaseLocationResponse.responseCode === HTTP_STATUS_CODES.ok) {
      setLoading(false);
      toast.success(updateBaseLocationResponse.message);
      setShowRightPanel(true);
      setIsLocationEnabled(!isLocationEnabled);
      dispatch(ProfileActions.getUserProfile());
      const user: any = await LocalStorage.getStorage("user");
      user.city = city;
      user.country = country;
      user.district = district;
      user.state = state;
      user.subDistrict = subDistrict;
      user.establishmentName = establishmentName;
      user.baseLocationLat = lat;
      user.baseLocationLong = long;
      LocalStorage.setStorage("user", user);
      props.onClose();
      updateTelemetryBaseLocationChange();
      // locationArray = [];
      setLocationArray([]);
    } else {
      toast.error(updateBaseLocationResponse.message);
      setLoading(false);
    }
    googleWebAnalytics("Profile_Base_Location_Consent", "Telemetry_Event", "Profile");
    googleWebAnalytics("Profile_Base_Location_Added", "Telemetry_Event", "Profile");
  };

  const updateTelemetryBaseLocationChange = async () => {
    console.log(locationConsentOnToggle);
    const user: any = await LocalStorage.getStorage("user");
    const commonBody = {
      createdAt: Moment(),
      deleted: "false",
      updatedAt: Moment()
    };
    if (locationConsentOnToggle === true) {
      const body = {
        ...commonBody,
        eventType: "Consent Location",
        userId: user?.userId,
        baseLocationCity: user.city,
        baseLocationDistrict: user.district,
        baseLocationSubDistrict: user.subDistrict,
        baseLocationState: user.state,
        baseLocationCountry: user.country,
        baseLocationLatitude: user.baseLocationLat,
        baseLocationLongitude: user.baseLocationLong,
        establishmentName: user.establishmentName
      };
      AddTelemetryService(body, undefined, undefined);
      const locationBody = {
        ...commonBody,
        eventType: "Base Location Change"
      };
      AddTelemetryService(locationBody, undefined, user);
      googleWebAnalytics("Profile_Consent_BaseLocation_Change", "Telemetry_Event", "Profile");
    } else {
      const body = {
        ...commonBody,
        eventType: "Base Location Change"
      };
      AddTelemetryService(body, undefined, user);
      googleWebAnalytics("Profile_No_Consent_BaseLocation_Change", "Telemetry_Event", "Profile");
    }
  };
  useEffect(() => {
    onPressForLocation();
  }, [currentBaseLocation]);

  // const callback_method = (data: any) => {
  //   console.log("placePicker data:", data);
  // };

  // const loadScript = () => {
  //   const script = document.createElement("script");
  //   script.src = "https://apis.mapmyindia.com/advancedmaps/api/44d7e738-5800-4a8a-aa40-6131b6966271/map_sdk_plugins";
  //   script.async = true;
  //   document.body.appendChild(script);
  // }

  const getBaseLocationLatLongApi = async (params: any, callback: Function) => {
    // console.log('checkLanguage', params.lang);
    const language = params.lang === "" || params.lang === null ? "en" : params.lang;
    try {
      // const response = await newClient.get(`/maps/api/geocode/json?key=AIzaSyDVf-To9hehz4ORteAxgsP-yzHxsahVcDo&latlng=${params.lat},${params.long}&language=${language}`);
      // await loadScript();
      // const obj = new MapmyIndia.placePicker({
      //   location: { lat: 12.974002, lng: 77.613458 },
      //   callback: callback_method
      // });
      // const response: any = await axios.get(77.613458
      // );
      // if (response.status === 200) {
      //   console.log(
      //     "============response on get here api call=======",
      //     response,
      //     response.data?.results[0]
      //   );
      //   const locationArray = response.data?.results[0];
      //   // console.log('locationarray', locationArray);
      //   callback(true, locationArray);
      // }
    } catch (error) {
      console.log("error");
      callback(false, error);
    }
    // };
  };

  const getLocationBasedOnLatLong = async (params: any, toggle: boolean) => {
    const { lat, long } = params;
    // toggle === true ? '' : showLoadingMessage(stringsConvertor('alertMessage.loading'));
    // let countryCode = _.has(userData,mapmyindia-restapi-react-native-beta 'countryCode') ? userData.countryCode : '';
    let countryCode = data?.profile?.userProfileData?.response?.countryCode;
    setLat(lat);
    setLong(long);
    // if (countryCode === '+91') {
    //   let placeOptions = {
    //     location: [lat, long]
    //   };

    // new MapmyIndia.search(document.getElementById("auto"), placeOptions);

    // Mapmyindia.setRestApiKey('nsgnhfs11mx7qvg7nvs1zc68fwhm4dqr');
    // Mapmyindia.setClientId('33OkryzDZsLgqMoIblYc703weXdYMO73w7wPq96wmDzcylNlblcNZ03zCi8dQQUZ79IoNlyCsSa24nwxVeD7HkHjYL-A1-sXtJl-11w3yV9M2Otw-RGbxQ==');
    // Mapmyindia.setClientSecret('lrFxI-iSEg8ugz_-DhsZ-0eM-seVJKDa-ND_t_s2qp8BTZircx1RAkz2N38uLtNNtvrNtVV5GvoEFoOmpCFxkAsVI7md7BR3nMpWkuRXwA0ttm8VPh8VUCzoUMpq7I-r');

    // Mapmyindia.rev_geocode({ lat: lat, lng: long }, (response: any) => {
    //   console.log('print the current response', response, response.results[0]);
    //   const currentLocation = response.results[0];
    //   if (response.responseCode === 200) {
    //     // this.setState({ lat: currentLocation.lat, long: currentLocation.lng, searchLocationModalVisible: true, isLoading: false });
    //     setLat(currentLocation.lat);
    //     setLong(currentLocation.lng);
    //     setCity(currentLocation.city === '' ? currentLocation.village : currentLocation.city);
    //     setDistrict(currentLocation.district);
    //     setState(currentLocation.state);
    //     setCountry(currentLocation.area);
    //     setSubDistrict(currentLocation.subDistrict)
    //     // hideMessage();
    //     // this.setState({
    //     //   city: currentLocation.city === '' ? currentLocation.village : currentLocation.city,
    //     //   district: currentLocation.district,
    //     //   state: currentLocation.state,
    //     //   country: currentLocation.area,
    //     //   subDistrict: currentLocation.subDistrict
    //     // });
    //   }
    // });
    // getAccessToken();
    // } else {

    // Google API call

    getBaseLocationLatLongApi(params, (status: any, response: any) => {
      if (status) {
        const { location } = response.geometry;
        setLat(location.lat);
        setLong(location.lng);
        response.address_components.map((item: any) => {
          if (item.types[0] === "locality") {
            setCity(item.long_name);
          }
          if (item.types[0] === "administrative_area_level_2") {
            setDistrict(item.long_name);
          }
          if (item.types[0] === "administrative_area_level_1") {
            setState(item.long_name);
          }
          if (item.types[0] === "country") {
            setCountry(item.long_name);
          }
        });
      }
    });
    // .catch(() => { });
    // }
  };

  const onPressForLocation = (toggle = false) => {
    const local = RNLocalize.getLocales();
    const language = local[0];

    // permissionHelper.permissionChecker(permission, title, message, (status: any) => {
    navigator.permissions.query({ name: "geolocation" }).then(function (result) {
      if (result.state == "granted") {
        Geolocation.getCurrentPosition(
          (position) => {
            const params = {
              lat: position.coords.latitude,
              long: position.coords.longitude,
              lang: language.languageCode
            };
            {
              getLocationBasedOnLatLong(params, toggle);
            }
            setLat(position.coords.latitude);
            setLong(position.coords.longitude);
            setLanguageCode(language.languageCode);
            if (currentBaseLocation === "") {
              getUserLocation(position.coords.latitude, position.coords.longitude);
            }
          },
          (error) => {
            console.log("============== error on press loc =========", error);
          },
          { enableHighAccuracy: true, timeout: 15000, maximumAge: 10000, showLocationDialog: false }
        );
        // .catch((err: any) => {
        //   this.setState({ isLoading: false });
        //   console.log('==============error on press loc catch=========', err);
        // });
      } else if (result.state == "prompt") {
        navigator.geolocation.getCurrentPosition(revealPosition, positionDenied);
        console.log("location access to be prompted");
      } else if (result.state == "denied") {
        console.log("location access denied");
      } else {
        console.log("==============error on press loc====in else part on press loc =====");
      }
    });
  };

  const revealPosition = () => {
    console.log("Revel position");
    onPressForLocation();
  };

  const positionDenied = () => {
    console.log("Position denied!");
  };

  const handleBaseLocation = (baseLocation: string) => {
    setBaseLocation(baseLocation);
    onSearchPress(baseLocation);
  };
  // console.log('baseLocation', baseLocation)

  let UNICODE = {
    ar: /[\u0600-\u06FF]/,
    iw: /[\u0590-\u05FF]/,
    bn: /[\u0980-\u09FF]/,
    de: /[\u0370-\u03FF]/,
    ka: /[\u10A0-\u10FF]/,
    th: /[\u0E00-\u0E7F]/,
    kn: /[\u0C80-\u0CFF]/,
    hi: /[\u0900-\u097F]/,
    en: /^[a-zA-Z]+$/,
    el: /[\u0900-\u097F]/,
    ta: /[\u0B80-\u0BFF]/,
    ml: /[\u0D00-\u0DFF]/,
    te: /[\u0C00-\u0C7F]/,
    lo: /[\u0E80-\u0EFF]/,
    hy: /[\u0530-\u058F]/,
    mr: /[\u0900-\u097F]/
    // add other languages here
  };

  function validateText(text: string) {
    let textExp = /\S/;
    return textExp.test(text);
  }

  const getUserLocation = async (lat: number, long: number) => {
    if (data?.profile?.userProfileData?.response?.countryCode === "+91") {
      const objResponse = await getPlacePicker(lat, long);
      console.log("default location", objResponse);
      let baseLocation = fromatBaseLocation(objResponse);
      setBaseLocation(baseLocation);
      setResponseData(objResponse);
    } else {
      const params = {
        lat: lat,
        long: long,
        lang: languageCode
      };
      getDefaultGoogleBaseLocation(params);
    }
  };

  const getDefaultGoogleBaseLocation = async (params: any) => {
    const language = params.lang === "" || params.lang === null ? "en" : params.lang;
    try {
      const response: any = await axios.get(
        `https://maps.googleapis.com/maps/api/geocode/json?key=AIzaSyDVf-To9hehz4ORteAxgsP-yzHxsahVcDo&latlng=${params.lat},${params.long}&language=${language}`
      );
      if (response.status === 200) {
        let defaultSelectedPlaceObj: any = {};
        defaultSelectedPlaceObj["city"] = "";
        defaultSelectedPlaceObj["district"] = "";
        defaultSelectedPlaceObj["state"] = "";
        defaultSelectedPlaceObj["country"] = "";
        response.data?.results[0].address_components.map((item: any) => {
          if (item.types[0] === "locality") {
            setCity(item.long_name);
            defaultSelectedPlaceObj["city"] = item.long_name;
          }
          if (item.types[0] === "administrative_area_level_2") {
            setDistrict(item.long_name);
            defaultSelectedPlaceObj["district"] = item.long_name;
          }
          if (item.types[0] === "administrative_area_level_1") {
            setState(item.long_name);
            defaultSelectedPlaceObj["state"] = item.long_name;
          }
          if (item.types[0] === "country") {
            setCountry(item.long_name);
            defaultSelectedPlaceObj["country"] = item.long_name;
          }
        });
        defaultSelectedPlaceObj["subDistrict"] = "";
        let type = response.data?.results[0].types;
        let checkType = type.includes("establishment");
        if (checkType === true) {
          setEstablishmentName(response.data?.results[0].name);
        }
        const { location } = response.data?.results[0].geometry;
        setLat(location.lat);
        setLong(location.lng);
        setLocationArray([]);
        console.log("defaultSelectedPlaceObj", defaultSelectedPlaceObj);
        let baseLocation = auxiliaryMethods.fromatBaseLocation(defaultSelectedPlaceObj);
        setBaseLocation(baseLocation);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getElocData = (location: any) => {
    try {
      return new MapmyIndia.getEloc({ eloc: location?.eLoc });
    } catch (error) {
      toast.error("Something went wrong.");
    }
  };

  const getPlacePicker = (latitude: number, longitude: number) => {
    try {
      return new MapmyIndia.placePicker({
        location: { lat: latitude, lng: longitude }
      });
    } catch (error) {
      toast.error("Something went wrong.");
    }
  };

  // when current location selected
  const currentLocationSelected = async (location: any) => {
    console.log("Current location lat,long", location);
    const objectData = await getPlacePicker(location.latitude, location.longitude);
    console.log("current location placePicker", objectData);
    let baseLocation = fromatBaseLocation(objectData);
    setBaseLocation(baseLocation);
    setResponseData(objectData);
  };

  async function suggestionsCallback(data: any) {
    try {
      if (data) {
        setDisableState(true);
        console.log("Place Search - for suggestion of places as user type", data);
        const location = data[0];
        let establishmentName = undefined;

        if (location.placeName.includes("Current Location")) {
          currentLocationSelected(location);
          return;
        }

        const elocSelectedObj = await getElocData(location);
        console.log(
          "Place Details Plugin - to get the Coarse Lat-Long of Address (eLoc) selected in Place Search",
          elocSelectedObj
        );

        if (location?.type === "POI") {
          establishmentName = location?.placeName;
          setEstablishmentName(location?.placeName);
        }

        if (location.type === "VILLAGE") {
          location.addressTokens["area"] = "India";
          let baseLocation = fromatBaseLocation(location.addressTokens);
          setBaseLocation(baseLocation);
          location.addressTokens["latitude"] = elocSelectedObj?.data.latitude;
          location.addressTokens["longitude"] = elocSelectedObj?.data.longitude;
          location.addressTokens["longitude"] = elocSelectedObj?.data.longitude;
          setResponseData(location.addressTokens, establishmentName);
        } else {
          const { latitude, longitude } = elocSelectedObj?.data;
          const objectData = getPlacePicker(latitude, longitude);
          console.log("placePicker", objectData);
          let baseLocation = fromatBaseLocation(objectData);
          setBaseLocation(baseLocation);
          setResponseData(objectData, establishmentName);
        }
      }
    } catch (err) {
      // toast.error(data.error);
    }
  }

  const setResponseData = (objectData: any, establishmentName?: string) => {
    setCountry(objectData?.area);
    setState(objectData?.state);
    if (objectData?.village != "") {
      setCity(objectData?.village);
    } else {
      setCity(objectData?.city);
    }
    setDistrict(objectData?.district);
    setLat(objectData?.lat);
    setLong(objectData?.lng);
    setEstablishmentName(establishmentName);
    setSubDistrict(objectData?.subDistrict);
  };

  const fromatBaseLocation = (data: any) => {
    let formattedBaseLocation: string = "";

    if (data?.village !== "") {
      formattedBaseLocation += `${data?.village}, `;
    } else {
      if (data?.city !== "") {
        formattedBaseLocation += `${data?.city}, `;
      }
    }
    if (data?.subDistrict !== "") {
      formattedBaseLocation += `${data?.subDistrict}, `;
    }
    if (data?.district !== "") {
      formattedBaseLocation += `${data?.district}, `;
    }
    if (data?.state !== "") {
      formattedBaseLocation += `${data?.state}, `;
    }
    if (data?.area !== "") {
      formattedBaseLocation += `${data?.area} `;
    }
    return formattedBaseLocation;
  };

  const elocSelectedData = (data: any) => {
    console.log(
      "Place Details Plugin - to get the Coarse Lat-Long of Address (eLoc) selected in Place Search"
    );
    console.log(data);
  };

  const getSearchedLocation = async (data: any, callback: any) => {
    try {
      // const response = await ApiClient.get(profileEndPoints.placeAutoComplete(), { key: data });
      // if (response) {
      //   callback(response?.response?.status, response?.response?.predictions);
      // }
      // const optional_config = {
      //   width: 330,
      //   height: 150,
      //   tokenizeAddress: true
      // };
      // document.getElementById("addBaseLocation");
      // const searched_place = new MapmyIndia.search(
      //   document.getElementById("addBaseLocation"),
      //   optional_config,
      //   suggestionsCallback
      // );
      // var elocSelectedObj = MapmyIndia.getEloc({ eloc: "2UQY8X", callback: elocSelectedData });
      /**
       * --------------------------------------------------------------------------------
       * --------------------------------------------------------------------------------
       * --------------------------------------------------------------------------------
       *
       * With react native package
       * - setKeys is throwing error
       *
       *
       * With web plugin
       * - Make id accepted in the TextInput [This is RN component, so we cant overried it as it
       *   does not accept id by default. Need to find an alternative]
       *
       *
       * --------------------------------------------------------------------------------
       * --------------------------------------------------------------------------------
       * --------------------------------------------------------------------------------
       *
       */
    } catch (error) {
      callback(false, error);
    }
    try {
      const response = await ApiClient.get(profileEndPoints.placeAutoComplete(), { key: data });
      if (response) {
        callback(response?.response?.status, response?.response?.predictions);
      }
    } catch (error) {
      callback(false, error);
    }
  };

  const onSearchPress = (baseLocation: string) => {
    // const { searchApi, countryCode } = this.props;
    Object.entries(UNICODE).forEach(([key, value]) => {
      if (value.test(baseLocation) == true) {
        // this.setState({detectedLanguageCode: key});
        setDetectedLanguageCode(key);
      }
    });
    if (baseLocation === "" || !validateText(baseLocation)) {
      // locationArray = [];
      setLocationArray([]);
      // this.setState({validationError:true,validationErrorMessage:stringsConvertor('validationMessage.searchLocHint'),baseLocation:''});
      setBaseLocationError("Make sure you have searched the location");
    } else {
      let countryCode = data?.profile?.userProfileData?.response?.countryCode;
      if (countryCode !== "+91") {
        // this.setState({ isLoading: true, placeId: '', noResult: false, validationError: false }, () => {
        getSearchedLocation(baseLocation, (status: string, response: any) => {
          if (status === "OK") {
            console.log("response from the callback api", response);
            // locationArray = response;
            setLocationArray(response);
            // this.setState({ isLoading: false, itemSelected: false });
          } else if (status === "ZERO_RESULTS") {
            // locationArray = [];
            setLocationArray([]);
            // this.setState({ isLoading: false, noResult: true, noResultMessage: stringsConvertor('validationMessage.noResultFound') });
            setBaseLocationError("No result found for this search");
          } else {
            // locationArray = [];
            setLocationArray([]);
            // this.setState({ isLoading: false, noResult: true, noResultMessage: 'Some error' });
            setBaseLocationError("Some error");
          }
        }).catch((err: any) => {
          // this.setState({ isLoading: false });
          console.log("error ----- ", err);
        });
        // });
      }
    }
  };

  const renderLocationSugetion = (item: any) => {
    const { description, place_id, eLoc, addressTokens } = item;
    const POI = item.type === "POI" ? item.placeName : "";
    const address = item.description;
    return (
      <View
      // style={{ borderBottomWidth: 1, paddingVertical: 2, backgroundColor: placeId === place_id ? colorPallete.gray : null }}
      // style = {{borderBottomWidth:verticalScale(.5),paddingVertical:verticalScale(4),backgroundColor:placeId === place_id ?COLORS.gray :null}}
      >
        <TouchableOpacity
          onPress={() => {
            onSelectItemPress(address, place_id, eLoc, POI, item, addressTokens);
          }}
        >
          <View style={styles.alignAddressText}>
            <Text
              style={{ fontSize: 14 }}
              fontWeight={FontWeight.Regular}
              testId="renderLocationSuggestion"
              textSize={TextSize.Small}
            >
              {address}
            </Text>
          </View>
        </TouchableOpacity>
      </View>
    );
  };

  const onSelectItemPress = (
    description: string,
    place_id: string,
    eLoc: string,
    POI: string,
    item: any,
    addressTokens: string
  ) => {
    setPlaceId(place_id);
    const data = {
      place_id,
      detectedLanguageCode
    };
    getLocationByPlaceId(data, (status: boolean, response: any) => {
      let selectedPlaceObj: any = {};
      selectedPlaceObj["city"] = "";
      selectedPlaceObj["district"] = "";
      selectedPlaceObj["state"] = "";
      selectedPlaceObj["country"] = "";
      selectedPlaceObj["establishment"] = "";
      setCity("");
      setDistrict("");
      setState("");
      setCountry("");
      setLat("");
      setLong("");
      setEstablishmentName("");
      if (status === true) {
        console.log("============response by place id", response);
        response.address_components.map((item: any) => {
          if (item.types[0] === "locality") {
            setCity(item.long_name);
            selectedPlaceObj["city"] = item.long_name;
          }
          if (item.types[0] === "administrative_area_level_2") {
            setDistrict(item.long_name);
            selectedPlaceObj["district"] = item.long_name;
          }
          if (item.types[0] === "administrative_area_level_1") {
            setState(item.long_name);
            selectedPlaceObj["state"] = item.long_name;
          }
          if (item.types[0] === "country") {
            setCountry(item.long_name);
            selectedPlaceObj["country"] = item.long_name;
          }
        });
        selectedPlaceObj["subDistrict"] = "";
        let type = response.types;
        let checkType = type.includes("establishment");
        if (checkType === true) {
          setEstablishmentName(response.name);
        } else {
          setEstablishmentName("");
        }
        const { location } = response.geometry;
        setLat(location.lat);
        setLong(location.lng);
        setLocationArray([]);
        console.log("selectedPlaceObj", selectedPlaceObj);
        let baseLocation = auxiliaryMethods.fromatBaseLocation(selectedPlaceObj);
        console.log("baseLocation", baseLocation);
        setBaseLocation(baseLocation);
        console.log("location arrya is emptied here ------- +++++++++++ =====");
      }
    }).catch((err) => {});
  };

  const getLocationByPlaceId = async (data: any, callback: any) => {
    try {
      // let response: any = await axios.get(
      //   `/maps/api/place/details/json?key=AIzaSyDVf-To9hehz4ORteAxgsP-yzHxsahVcDo&place_id=${data.place_id}&language=${data.detectedLanguageCode}`
      // );
      const response = await ApiClient.get(profileEndPoints.placeDetails(), {
        reference: data.place_id
      });
      if (response) {
        callback(true, response?.response?.result);
      }
    } catch (error) {
      callback(false, error);
    }
  };

  const onCancelPress = () => {
    setBaseLocation("");
    setLocationArray([]);
    setCity("");
    setDistrict("");
    setState("");
    setCountry("");
    setSubDistrict("");
    setLat(undefined);
    setLong(undefined);
  };

  return (
    <View>
      <ToastContainer />
      {loading ? (
        <Loader customLoadingContainer={styles.customLoadingContainer} />
      ) : (
        <>
          {city === "" && district === "" && state === "" && country === "" ? (
            <>
              <EmailInput
                title="Add new base location"
                onClose={() => props.onClose()}
                testId="addBaseLocationText"
                label="Base location"
                handleChange={(baseLocation: string) => {
                  handleBaseLocation(baseLocation);
                }}
                handleBlur={() =>
                  googleWebAnalytics("Profile_Search_Base_Location", "Text_Input", "Profile")
                }
                value={baseLocation}
                placeholder="Enter base location to search"
                name="addBaseLocation"
                id="addBaseLocation"
                handleSubmit={() => updateBaseLocation()}
                disabled={disableState}
                // error={error}
                // errorMessage="Please enter valid email"
                // serverError={incorrectEmailMsg}
                locationArray={locationArray}
                renderLocationSugetion={renderLocationSugetion}
              />
            </>
          ) : (
            <>
              <EmailInput
                title="Add new base location"
                onClose={() => props.onClose()}
                testId="addBaseLocationText"
                label="Base location"
                handleChange={(baseLocation: string) => {
                  handleBaseLocation(baseLocation);
                }}
                handleBlur={() =>
                  googleWebAnalytics("Profile_Search_Base_Location", "Text_Input", "Profile")
                }
                value={baseLocation}
                // defaultValue={baseLocation}
                placeholder="Enter base location to search"
                name="addBaseLocation"
                id="addBaseLocation"
                handleSubmit={() => updateBaseLocation()}
                cancelPress={() => onCancelPress()}
                isCancelVisible={false}
                // isCancelVisible={!(city === "" && district === "" && state === "" && country === "")}
                // disabled={error || !email}
                // error={error}
                // errorMessage="Please enter valid email"
                // serverError={incorrectEmailMsg}
                locationArray={locationArray}
                renderLocationSugetion={renderLocationSugetion}
              />
            </>
          )}
        </>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  modalHeader: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row"
  },
  customLoadingContainer: {
    width: 655,
    height: 260
  },
  headerText: {
    fontWeight: "700",
    fontSize: 14,
    fontFamily: FontFamily.Medium,
    lineHeight: 17
  },
  subheaderText: {
    fontWeight: "400",
    fontSize: 14,
    fontFamily: FontFamily.Regular,
    lineHeight: 17,
    color: colorPallete.textBlack,
    marginTop: 13,
    flex: 20
  },
  submitButtonContainer: {
    alignItems: "center",
    marginTop: 30,
    marginBottom: 10
  },
  alignSubmitButton: {
    marginTop: 50
  },
  submitbutton: {
    width: "175px",
    height: "50px",
    borderRadius: 10,
    marginLeft: 10,
    marginRight: 10
  },
  emailInputContainer: {
    marginTop: 20,
    flexDirection: "row",
    justifyContent: "center"
  },
  subHeaderContainer: {
    marginTop: 10,
    marginRight: 15
  },
  inputRole: {
    height: 40,
    outline: "none",
    borderRadius: 5,
    padding: 7,
    fontSize: 14,
    fontFamily: FontFamily.Regular,
    fontWeight: "400",
    width: "330px"
  },
  error: {
    position: "relative",
    color: "red",
    alignSelf: "flex-start",
    fontSize: 10,
    fontWeight: "400",
    zIndex: -1
  },
  credits: {
    // color: colorPallete.gray,
    // textAlign: "right"
  },
  iconStyle: {
    color: colorPallete.cordioTaupe
  },
  alignAddressText: {
    marginBottom: 5
  }
});

import React, { useEffect, useState } from "react";
import { FlatList, SafeAreaView, StyleSheet, TouchableOpacity, View } from "react-native";
import { ProSidebar, Menu, MenuItem, SubMenu } from "react-pro-sidebar";
import Button, { ButtonType } from "@socion-cordio/common/src/components/atoms/button";
import Icon, { IconNames } from "@socion-cordio/common/src/components/atoms/icon";
import {
  Text,
  TextSize,
  FontWeight,
  FontFamily
} from "@socion-cordio/common/src/components/atoms/text";
import { colorPallete } from "@socion-cordio/common/src/assets/styles/colors";
import { Link } from "react-router-dom";
import LinearGradient from "react-native-web-linear-gradient";
import { LocalStorage } from "@socion-cordio/common/src/services/storage/storageService";
import { googleWebAnalytics } from "@socion-cordio/web/src/utils/firebaseAnalytics";

interface Props {
  data: Array<Object>;
  onLogout?: Function;
  getSidePanelWidth?: Function;
}

export default function List(props: Props) {
  const [selectedId, setSelectedId] = useState(null);
  const [menuCollapse, setMenuCollapse] = useState(true);
  const [widthCollapse, setWidthCollapse] = useState(220);
  const { data, getSidePanelWidth } = props;
  let menuData: any = [];
  data.map((item: any) => {
    menuData.push(item);
  });

  useEffect(() => {
    collapseHandler();
  });

  const collapseHandler = async () => {
    // Close left side panel only when you click on any live session,
    // on QP view details participant count, topics participant count,
    // on dashboard participant details and participant attestation count,
    const isSidePanelCollapse =
      window.location.pathname.includes("session/view-session/live") ||
      window.location.pathname.includes("/qualification-packs/participant-mapping") ||
      window.location.pathname.includes("app/topicparticipantdetail") ||
      window.location.pathname.includes("app/participationDetails") ||
      window.location.pathname.includes("app/participationAttestation");
    if (isSidePanelCollapse) {
      let isSidePanelCollapsed: boolean = await LocalStorage.getStorage("isSidePanelCollapsed");
      if (isSidePanelCollapsed) {
        getSidePanelWidth(60);
        setWidthCollapse(60);
        setMenuCollapse(false);
      }
    }
  };

  useEffect(() => {
    // widthCollapse === 220 ? getSidePanelWidth(220, false) : null;
    getLocalStoreWidth();
  }, []);

  const getLocalStoreWidth = async () => {
    const isSidePanelCollapsed: any = await LocalStorage.getStorage("isSidePanelCollapsed");
    isSidePanelCollapsed
      ? [getSidePanelWidth(60), setWidthCollapse(60), setMenuCollapse(false)]
      : getSidePanelWidth(220);
  };

  if (selectedId === 99) {
    props.onLogout(selectedId);
  }

  const Item = ({ onPress, item }: { onPress: any; item: any }) => (
    <TouchableOpacity onPress={onPress} style={[]}>
      {window.location.pathname.includes(item.path) ? (
        <LinearGradient
          colors={["#B7504A", "#6E302C"]}
          start={{ y: 0.0, x: 0.0 }}
          end={{ y: 0.0, x: 1.0 }}
        >
          {renderViewList(item)}
        </LinearGradient>
      ) : (
        <View>{renderViewList(item)}</View>
      )}
    </TouchableOpacity>
  );

  const validateItemHandler = (item: any) => {

    console.log("fvvdnbdnvfds", item);

    if (item.id == 9 || item.id == 10) {
      window.open(item.url, "_blank");
    } else {
      setSelectedId(item.id);
    }
  };

  const renderItem = ({ item }: { item: any }) => (
    <Item item={item} onPress={() => validateItemHandler(item)} />
  );

  const renderViewList = (item: any) => (
    <Link
      to={item.path}
      style={{ textDecorationLine: "none" }}
      onClick={() => googleWebAnalytics(item?.eventAnalytics, "Button", "SideBar")}
    >
      <View
        style={
          item.id === 10 && !menuCollapse && menuData.length > 9
            ? styles.itemSecondary
            : styles.item
        }
      >
        <View
          style={
            menuCollapse
              ? styles.selectedBarHighlighContainer
              : [styles.selectedBarHighlighContainer, styles.selectedBarHighlightAlign]
          }
        >
          {window.location.pathname.includes(item.path) ? (
            <View style={styles.selectedBarHighlight}></View>
          ) : (
            <View></View>
          )}
        </View>
        <View
          style={
            menuCollapse
              ? styles.subContainerIconLabel
              : [styles.subContainerIconLabel, styles.alignSubIconContainer]
          }
        >
          <View>
            <Icon
              name={item.iconName}
              customStyle={
                menuCollapse
                  ? [styles.iconStyle, item.id === 9 && styles.taskIconAlign]
                  : [styles.iconStyle, styles.alignIcons]
              }
            />
          </View>
          {menuCollapse === true && (
            <View>
              <Text
                fontWeight={FontWeight.Bold}
                testId="title"
                textSize={TextSize.Small}
                style={
                  window.location.pathname.includes(item.path) ? [styles.title] : [styles.lightFont]
                }
              >
                {item.title}
              </Text>
            </View>
          )}
        </View>
      </View>
    </Link>
  );

  const menuIconClick = () => {
    if (menuCollapse) {
      menuData = [];
      data.map((item: any) => {
        menuData.push({ iconName: item.iconName, id: item.id, path: item.path });
      });
      setMenuCollapse(false);
      setWidthCollapse(60);
      getSidePanelWidth(60, false);
      LocalStorage.setStorage("isSidePanelCollapsed", true);
    } else {
      menuData = [];
      data.map((item: any, index: number) => {
        menuData.push(item);
      });
      setMenuCollapse(true);
      setWidthCollapse(220);
      getSidePanelWidth(220, true);
      LocalStorage.setStorage("isSidePanelCollapsed", false);
    }
  };

  useEffect(() => {
    programSelectedTabClearHandler();
    entitySelectedTabClearHandler();
    clearQpLocationFilterHandler();
    topicsTabClearHandler();
    removeAssignBadgeClearHandler();
  }, [window.location.pathname]);

  const programSelectedTabClearHandler = () => {
    !window.location.pathname.includes("/program")
      ? LocalStorage.removeStorage("programTabsOpen")
      : null;
  };

  const entitySelectedTabClearHandler = () => {
    !window.location.pathname.includes("/entity")
      ? LocalStorage.removeStorage("entityTabsOpen")
      : null;
  };

  const topicsTabClearHandler = () => {
    !window.location.pathname.includes("/topics")
      ? LocalStorage.removeStorage("topicsTabOpen")
      : null;
  };

  const clearQpLocationFilterHandler = () => {
    LocalStorage.removeStorage("qpfilterList");
    LocalStorage.removeStorage("globalNameList");
  };

  const removeAssignBadgeClearHandler = () => {
    !window.location.pathname.includes("/badges/assign")
      ? [
          LocalStorage.removeStorage("noneLocationListSet"),
          LocalStorage.removeStorage("noAssignedBadgesSelected")
        ]
      : null;
  };

  return (
    <>
      <View style={styles.mainContainer}>
        <ProSidebar collapsed={menuCollapse} collapsedWidth={widthCollapse} width={60}>
          <View style={styles.sidebar}>
            <SafeAreaView style={styles.container}>
              <FlatList
                data={menuData}
                renderItem={renderItem}
                keyExtractor={(item) => item.id}
                extraData={selectedId}
                contentContainerStyle={{}}
              />
            </SafeAreaView>
          </View>
        </ProSidebar>
        <View style={menuCollapse ? styles.miniContainer3 : styles.miniContainer2}>
          <Icon
            customStyle={styles.sidebarIconStyle2}
            name={menuCollapse ? IconNames.doubleArrowLeft : IconNames.doubleArrowRight}
            onPress={() => menuIconClick()}
          />
        </View>
      </View>
    </>
  );
}

const styles = StyleSheet.create({
  mainContainer: {
    flexDirection: "row",
    display: "flex",
    backgroundColor: colorPallete.cordioRedDark1
  },
  miniContainer2: {
    // marginTop: 380,
    marginLeft: -37,
    marginTop: 470,
    backgroundColor: colorPallete.cordioRedDark1,
    width: 60,
    height: 60,
    borderRadius: 10,
    borderTopRightRadius: 30,
    transform: [{ rotate: "45deg" }]
  },
  miniContainer3: {
    // marginTop: 380,
    marginLeft: -37,
    marginTop: 460,
    backgroundColor: colorPallete.cordioRedDark1,
    width: 60,
    height: 60,
    borderRadius: 10,
    borderTopRightRadius: 30,
    transform: [{ rotate: "45deg" }]
  },
  sidebarIconStyle2: {
    fontSize: 25,
    alignSelf: "flex-end",
    margin: 10,
    color: colorPallete.white,
    transform: [{ rotate: "315deg" }]
  },
  sidebar: {
    left: 0,
    height: "100%",
    backgroundColor: colorPallete.cordioRedDark1,
    minHeight: "calc(100vh - 48px)" // To ajdust scroll bar, earlier 50 (50 -> 48)
  },
  container: {
    top: 70
  },
  item: {
    height: 45,
    flexDirection: "row",
    alignItems: "center",
    display: "flex"
  },
  itemSecondary: {
    height: 45,
    flexDirection: "row",
    alignItems: "center",
    display: "flex",
    marginTop: 35
  },
  title: {
    fontSize: 12,
    color: colorPallete.cordioBeige,
    fontFamily: FontFamily.Medium,
    fontWeight: "700",
    letterSpacing: 0.6
  },
  lightFont: {
    fontSize: 12,
    color: colorPallete.cordioBeige,
    fontFamily: FontFamily.Regular
  },
  iconStyle: {
    color: colorPallete.white,
    marginEnd: 10
  },
  alignIcons: {
    marginEnd: 0
  },
  selectedBarHighlighContainer: {
    flex: 13
    // width: 30
  },
  selectedBarHighlightAlign: {
    flex: 0
  },
  selectedBarHighlight: {
    width: 4,
    height: 45,
    backgroundColor: "#DBCBBC"
  },
  subContainerIconLabel: {
    flexDirection: "row",
    flex: 87,
    alignItems: "center"
  },
  alignSubIconContainer: {
    justifyContent: "center",
    marginRight: 4 // off set to adjust with the highlighted bar in the left
  },
  taskIconAlign: {
    marginStart: 3,
    marginEnd: 12
  }
});

import React, { useEffect, useState } from "react";
import { IconNames } from "@socion-cordio/common/src/components/atoms/icon";
import List from "@socion-cordio/common/src/components/molecules/List";
import { LocalStorage } from "@socion-cordio/common/src/services/storage/storageService";
import { useHistory } from "react-router-dom";
import { allRoutesNames as routeNames } from "@socion-cordio/web/src/navigation/allRouteNames";
import { useDispatch } from "react-redux";
import Moment from "moment";
import AddTelemetryService from "@socion-cordio/common/src/services/telemetryService";
import { googleWebAnalytics } from "@socion-cordio/web/src/utils/firebaseAnalytics";
import { LoginRepository } from "@socion-cordio/common/src/repositories/login/login";
import { HTTP_STATUS_CODES } from "@socion-cordio/common/src/network/constants";
import { toast } from "react-toastify";

export default function ProfileNavbar(props: any) {
  const [isUserEligibleProgramBadges, setIsUserEligibleProgramBadges] = useState(false);
  const history: any = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    getUserEligibleProgramsForBadges();
  }, []);

  const getUserEligibleProgramsForBadges = () => {
    const response = LoginRepository.getUserEligibleProgramsForBadges();
    response
      .then((res: any) => {
        if (res.responseCode === HTTP_STATUS_CODES.ok && res?.response?.length > 0) {
          setIsUserEligibleProgramBadges(true);
        }
      })
      .catch((error) => {
        console.log("error");
      });
  };

  const DATA = [
    {
      id: 1,
      title: "Personal Information",
      iconName: IconNames.personalInfo,
      path: `${routeNames.app}${routeNames.PROFILE}${routeNames.PERSONALINFO}`,
      eventAnalytics: "SideBar_Personal_Information"
    },
    {
      id: 2,
      title: "My Account",
      iconName: IconNames.UserAccount,
      path: `${routeNames.app}${routeNames.PROFILE}${routeNames.MYACCOUNT}`,
      eventAnalytics: "SideBar_Change_Password"
    },
    {
      id: 3,
      title: "About",
      iconName: IconNames.about,
      path: `${routeNames.app}${routeNames.PROFILE}${routeNames.ABOUT}`,
      eventAnalytics: "SideBar_About"
    },
    {
      id: 4,
      title: "Privacy Policy",
      iconName: IconNames.privacyPolicy,
      path: `${routeNames.app}${routeNames.PROFILE}${routeNames.PRIVACYPOLICY}`,
      eventAnalytics: "SideBar_Privacy_Policy"
    },
    {
      id: 5,
      title: "Terms of Use",
      iconName: IconNames.termsOfUse,
      path: `${routeNames.app}${routeNames.PROFILE}${routeNames.TERMSOFUSE}`,
      eventAnalytics: "SideBar_Terms_Of_Use"
    },
    {
      id: 99,
      title: "Logout",
      iconName: IconNames.logOut,
      eventAnalytics: "SideBar_Logout"
    }
  ];

  if (isUserEligibleProgramBadges) {
    const payload = {
      id: 7,
      title: "Additional Profile",
      iconName: IconNames.additionalProfile,
      path: `${routeNames.app}${routeNames.PROFILE}${routeNames.ADDITIONALPROFILEINFO}`,
      eventAnalytics: "Additional_Profile"
    };
    DATA.splice(2, 0, payload);
  }

  const handleLogout = (item: number) => {
    if (item === 99) {
      // updateTelemetry();
      history.push("/new/iam/login");
      LocalStorage.removeStoredKeys();
      dispatch({ type: "USER_LOGOUT" });
    }
  };

  const updateTelemetry = async () => {
    const user: any = await LocalStorage.getStorage("user");
    const body = {
      timestamp: Moment().toLocaleString(),
      createdAt: Moment().toLocaleString(),
      deleted: "false",
      updatedAt: Moment().toLocaleString(),
      eventType: "Logout"
    };
    AddTelemetryService(body, undefined, user);
    googleWebAnalytics("Profile_Logout", "Telemetry_Event", "Profile");
  };

  return <List getSidePanelWidth={props.getSidePanelWidth} onLogout={handleLogout} data={DATA} />;
}

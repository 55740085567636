import React from "react";
import { allRoutesNames as routeNames } from "navigation/allRouteNames";
import Topics from "@socion-cordio/common/src/components/organisms/topic/topics";
import EntityPage from "@socion-cordio/common/src/components/organisms/entity/entityPage";
import { Roles } from "@socion-cordio/common/src/constants/roles";
import ProgramPage from "@socion-cordio/common/src/components/organisms/program/programPage";
import DashboardMainPage from "@socion-cordio/common/src/components/organisms/dashboard/dashboardMainPage";
import VerifyPage from "@socion-cordio/common/src/components/organisms/verify/verifyPage";
import SessionPage from "@socion-cordio/common/src/components/organisms/session/sessionPage";
import BadgesPage from "@socion-cordio/common/src/components/organisms/badges/badgesPage";
import QualificationPackPage from "@socion-cordio/common/src/components/organisms/qualification-packs/qualificationPackPage";
import CreateNewSessionPage from "@socion-cordio/common/src/components/organisms/session/createSession";
import CreateSessionPage from "@socion-cordio/common/src/components/organisms/session/createSessionPage";
import ParticipantDetailsPage from "@socion-cordio/common/src/components/organisms/participation-details/participationDetailsPage";
import CompletedSessionParticipationListPage from "@socion-cordio/common/src/components/organisms/session/completedSessionParticipationListPage";
import ViewSessionPage from "@socion-cordio/common/src/components/organisms/session/viewSessionPage";
import AssignBadgePage from "@socion-cordio/common/src/components/organisms/badges/assignBadge";
import UnassignBadgePage from "@socion-cordio//common/src/components/organisms/badges/unassignBadge";
import QualificationViewDetails from "@socion-cordio/common/src/components/organisms/qualification-packs/qualificationViewDetailsPage";
import QualificationPariticpantMappingPage from "@socion-cordio/common/src/components/organisms/qualification-packs/qualiParticipantMappingPage";
import TopicDetails from "@socion-cordio/common/src/components/organisms/topic/topicDetails";
import AttestationPage from "@socion-cordio/common/src/components/organisms/participation-details/attestation";
import TopicParticipantPage from "@socion-cordio/common/src/components/organisms/topic/topicParticipantPage";
import AddQualificationPackPage from "@socion-cordio/common/src/components/organisms/qualification-packs/addQualificationPackPage";
import ViewQualificationPackDetailsPage from "@socion-cordio/common/src/components/organisms/qualification-packs/viewQualificationPackDetailsPage";
import Accomplishment from "@socion-cordio/common/src/components/organisms/accomplishment/accomplishment";
import ATTENDANCE from "@socion-cordio/common/src/components/organisms/accomplishment/components/attendance";
import QualificationPack from "@socion-cordio/common/src/components/organisms/accomplishment/qualificationPack";
import Tasks from "@socion-cordio/common/src/components/organisms/accomplishment/tasks";
import TaskDetails from "@socion-cordio/common/src/components/organisms/accomplishment/taskDetails";
import Artefact from "@socion-cordio/common/src/components/organisms/accomplishment/artefact";
import ArtefactDetails from "@socion-cordio/common/src/components/organisms/accomplishment/artefactDetails";
import EntityDetails from "@socion-cordio/common/src/components/organisms/registry/entity/entityDetails";
import Administration from "@socion-cordio/common/src/components/organisms/registry/entity/admins";
import Programs from "@socion-cordio/common/src/components/organisms/registry/entity/programs";
import ProgramPartnersList from "@socion-cordio/common/src/components/organisms/registry/entity/programPartnersList";
import EntityPartnersList from "@socion-cordio/common/src/components/organisms/registry/entity/entityPartnersList";
import ProgramDetails from "@socion-cordio/common/src/components/organisms/registry/entity/programDetails";


const SpringshedRoutes = () => [
  {
    path: `${routeNames.ENTITY}`,
    component: EntityDetails,
    isExact: false,
    permission: [Roles.Admin, Roles.AreaManager, Roles.StoreAccess]
  },
  {
    path: `${routeNames.TOPICS}${routeNames.TOPICDETAILS}`,
    component: TopicDetails,
    isExact: false,
    permission: [Roles.Admin, Roles.AreaManager, Roles.StoreAccess]
  },
  {
    path: `${routeNames.ADMINS}`,
    component: Administration,
    isExact: false,
    permission: [Roles.Admin, Roles.AreaManager, Roles.StoreAccess]
  },
  {
    path: `${routeNames.TOPICPARTIPANTDETAIL}`,
    component: TopicParticipantPage,
    isExact: false,
    permission: [Roles.Admin, Roles.AreaManager, Roles.StoreAccess]
  },
  {
    path: `${routeNames.PROGRAM}`,
    component: Programs,
    isExact: true,
    // permission: [Roles.Admin, Roles.AreaManager, Roles.StoreAccess],
    // children: [
    //   {
    //     path: `${routeNames.PARTNERS}`,
    //     component: ProgramPartnersList,
    //     isExact: false,
    //     permission: [Roles.Admin, Roles.AreaManager, Roles.StoreAccess]
    //   },
    // ]
  },
  {
    path: `${routeNames.PROGRAM}${routeNames.PARTNERS}`,
    component: ProgramPartnersList,
    isExact: false,
    // permission: [Roles.Admin, Roles.AreaManager, Roles.StoreAccess]
  },
  {
    path: `${routeNames.PARTNERSHIP}`,
    component: EntityPartnersList,
    isExact: false,
    // permission: [Roles.Admin, Roles.AreaManager, Roles.StoreAccess]
  },
  {
    path: `${routeNames.PROGRAM_DETAILS}`,
    component: ProgramDetails,
    isExact: false,
    // permission: [Roles.Admin, Roles.AreaManager, Roles.StoreAccess]
  },
  {
    path: `${routeNames.DASHBOARD}`,
    component: DashboardMainPage,
    isExact: false,
    permission: [Roles.Admin, Roles.AreaManager, Roles.StoreAccess]
  },
  {
    path: `${routeNames.VERIFY}`,
    component: VerifyPage,
    isExact: false,
    permission: [Roles.Admin, Roles.AreaManager, Roles.StoreAccess]
  },
  {
    path: `${routeNames.BADGES}${routeNames.UNASSIGNBADGE}`,
    component: UnassignBadgePage,
    isExact: false,
    permission: [Roles.Admin, Roles.AreaManager, Roles.StoreAccess]
  },
  {
    path: `${routeNames.BADGES}${routeNames.ASSIGNBADGE}`,
    component: AssignBadgePage,
    isExact: false,
    permission: [Roles.Admin, Roles.AreaManager, Roles.StoreAccess]
  },
  {
    path: `${routeNames.BADGES}`,
    component: BadgesPage,
    isExact: false,
    permission: [Roles.Admin, Roles.AreaManager, Roles.StoreAccess]
  },
  {
    path: `${routeNames.QUALIFICATIONPACKS}${routeNames.VIEWPARTICIPANTMAPPING}`,
    component: QualificationPariticpantMappingPage,
    isExact: false,
    permission: [Roles.Admin, Roles.AreaManager, Roles.StoreAccess]
  },
  {
    path: `${routeNames.QUALIFICATIONPACKS}${routeNames.VIEWQUALIFICATIONPACKDETAILS}`,
    component: ViewQualificationPackDetailsPage,
    isExact: false,
    permission: [Roles.Admin, Roles.AreaManager, Roles.StoreAccess]
  },
  {
    path: `${routeNames.QUALIFICATIONPACKS}${routeNames.ADDQUALIFICATIONPACK}`,
    component: AddQualificationPackPage,
    isExact: false,
    permission: [Roles.Admin, Roles.AreaManager, Roles.StoreAccess]
  },
  {
    path: `${routeNames.QUALIFICATIONPACKS}${routeNames.VIEWQUALIFICATIONPACKS}`,
    component: QualificationViewDetails,
    isExact: false,
    permission: [Roles.Admin, Roles.AreaManager, Roles.StoreAccess]
  },
  {
    path: `${routeNames.QUALIFICATIONPACKS}`,
    component: QualificationPackPage,
    isExact: false,
    permission: [Roles.Admin, Roles.AreaManager, Roles.StoreAccess]
  },
  {
    path: `${routeNames.SESSION}${routeNames.PARTICIPATIONLIST}`,
    component: CompletedSessionParticipationListPage,
    isExact: false,
    permission: [Roles.Admin, Roles.AreaManager, Roles.StoreAccess]
  },
  {
    path: `${routeNames.SESSION}${routeNames.CREATENEWSESSION}${routeNames.CREATESESSION}`,
    component: CreateSessionPage,
    isExact: false,
    permission: [Roles.Admin, Roles.AreaManager, Roles.StoreAccess]
  },
  {
    path: `${routeNames.SESSION}${routeNames.CREATENEWSESSION}`,
    component: CreateNewSessionPage,
    isExact: false,
    permission: [Roles.Admin, Roles.AreaManager, Roles.StoreAccess]
  },
  {
    path: `${routeNames.SESSION}${routeNames.VIEWSESSION}`,
    component: ViewSessionPage,
    isExact: false,
    permission: [Roles.Admin, Roles.AreaManager, Roles.StoreAccess]
  },
  {
    path: `${routeNames.SESSION}`,
    component: SessionPage,
    isExact: false,
    permission: [Roles.Admin, Roles.AreaManager, Roles.StoreAccess]
  },
  {
    path: `${routeNames.PARTICIPATIONDETAILS}`,
    component: ParticipantDetailsPage,
    isExact: false,
    permission: [Roles.Admin, Roles.AreaManager, Roles.StoreAccess]
  },
  {
    path: `${routeNames.PARTICIPATIONATTESTATION}`,
    component: AttestationPage,
    isExact: false,
    permission: [Roles.Admin, Roles.AreaManager, Roles.StoreAccess]
  },
  {
    path: `${routeNames.ACCOMPLISHMENT}/:programId/:userId/:accessToken`,
    component: () => Accomplishment,
    isExact: false,
    permission: [Roles.Admin, Roles.AreaManager, Roles.StoreAccess]
  },
  {
    path: `${routeNames.ACCOMPLISHMENTTASKS}/:programId/:userId/:accessToken`,
    component: () => Tasks,
    isExact: false
  },
  {
    path: `${routeNames.ACCOMPLISHMENTTASKSDETAILS}`,
    component: () => TaskDetails,
    isExact: false
  },
  {
    path: `${routeNames.ACCOMPLESMENTARTEFACT}/:programId/:userId/:accessToken`,
    component: () => Artefact,
    isExact: false
  },
  {
    path: `${routeNames.ACCOMPLESMENTARTEFACTDETAILS}`,
    component: () => ArtefactDetails,
    isExact: false
  },
  {
    path: `${routeNames.ACCOMPLISHMENTQP}/:programId/:userId/:accessToken`,
    component: () => QualificationPack,
    isExact: false
  },
  {
    path: `${routeNames.ATTENDANCE}/:programId/:userId/:accessToken`,
    component: () => ATTENDANCE,
    isExact: false
  }
];

const SpringshedRouter = () => {
  return SpringshedRoutes();
};
export default SpringshedRouter;

import React, { useEffect, useState } from "react";
import { Pressable, StyleSheet, View, TouchableOpacity, ScrollView } from "react-native";
import Icon, { IconNames } from "@socion-cordio/common/src/components/atoms/icon";
import { colorPallete } from "@socion-cordio/common/src/assets/styles/colors";
import {
  FontFamily,
  FontWeight,
  Text,
  TextSize
} from "@socion-cordio/common/src/components/atoms/text";
import { ApiClient } from "@socion-cordio/common/src/network/apiClient";
import { HTTP_STATUS_CODES } from "@socion-cordio/common/src/network/constants";
import { registryEndPoints } from "@socion-cordio/common/src/repositories/endPoints";
import { toast, ToastContainer } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import TextField from "@socion-cordio/common/src/components/atoms/textField";
import Button, { ButtonType } from "@socion-cordio/common/src/components/atoms/button";
import { Form, Formik, FormikProps } from "formik";
import * as Yup from "yup";
import { EntityActions } from "@socion-cordio/common/src/modules/registry/entity/actions/actions";
import { Image } from "@socion-cordio/common/src/components/atoms/image";
import { ScrollContainer } from "@socion-cordio/common/src/components/organisms/scrollWrapper";
import Loader from "@socion-cordio/common/src/components/atoms/loader";
import { googleWebAnalytics } from "@socion-cordio/web/src/utils/firebaseAnalytics";
import { TABS, STATUS, REGISTRY_TYPE } from "@socion-cordio/common/src/constants/registry";
import DatePicker from "@socion-cordio/common/src/components/molecules/datePicker";
import Moment from "moment";
import { isEmpty } from "lodash";
import { EntityRepository } from "@socion-cordio/common/src/repositories/registry/entity/entity";
import { UserHelper } from "@socion-cordio/common/src/utils/userHelper";
import { UploadFileHelper } from "@socion-cordio/common/src/utils/registryHelpers/uploadFileHelper";
import { DocsRepository } from "@socion-cordio/common/src/repositories/registry/docs/docs";
import { enableScroll, disableScroll } from "@socion-cordio/common/src/utils/scrollToTop";


interface Props {
  onClose: Function;
  dispatchEvent?: Function;
  selectedInvite?: any;
}

export default function CreateEntityModal(props: Props) {
  const { onClose, dispatchEvent } = props;
  const dispatch = useDispatch();
  const hiddenFileInput = React.useRef(null);
  const [docsArray, setDocsArray] = useState([]);
  const [loading, setLoading] = useState(false);
  const [entityDocuments, setEntityDocuments] = useState([]);
  const [saveLoader, setSaveLoader] = useState(false);
  const [disable, setDisable] = useState(false);

  const handleClick = (event: any) => {
    hiddenFileInput.current.click();
  };
  const formValidaionSchema = Yup.object({
    name: Yup.string().required("Required"),
    registrationNumber: Yup.string().required("Required"),
    taxRegNumber: Yup.string().required("Required"),
    legalStatus: Yup.string().required("Required"),
    address: Yup.string().required("Required"),
    city: Yup.string().required("Required"),
    state: Yup.string().required("Required"),
    country: Yup.string().required("Required"),
    pinCode: Yup.string().required("Required"),
  });

  const handleInvite = async (values: any) => {
    setSaveLoader(true);

    console.log("valuesjsjs", values);

    const createEntityRes = await EntityRepository.createEntity(values).catch((err) => {
      console.log("Error", err);
      setSaveLoader(false);
    });

    if (!isEmpty(createEntityRes) && docsArray?.length > 0) {
      const entityId = createEntityRes[0].entityId;
      console.log("entityDocsuemnsams", entityDocuments, docsArray);
      const uploadRes = await UploadFileHelper.upload(
        `${REGISTRY_TYPE.entity}/${entityId}`,
        docsArray,
        entityId,
        REGISTRY_TYPE.entity
      ).catch((err) => {
        console.log("Error while Uploading Docs", err);
        closePopup();
      });

      if (uploadRes) {
        closePopup();
      }
    } else {
      closePopup();
    }
  };

  const closePopup = () => {
    setSaveLoader(false);
    onClose();
    dispatchEvent(TABS.Entity);
  };

  const handleCustomChange = async (e: any) => {
    let fileNamesArray: any = [];
    docsArray.map((i) => {
      fileNamesArray.push(i.name);
    });
    if (fileNamesArray.includes(e.target.files[0].name)) {
      toast.error("Document already added");
      return;
    }
    const validTypes = ["mp4", "mpeg", "mov", "flv", "png", "jpg", "jpeg", "pdf"];
    const splitFileName = e?.currentTarget?.files[0]?.name.split(".");
    const extension = splitFileName[splitFileName.length - 1].toLowerCase();
    if (e.target.validity.valid && validTypes.includes(extension)) {
      setLoading(true);
      let docs = [...docsArray];
      docs.push(e?.currentTarget?.files[0]);
      setDocsArray(docs);
      setLoading(false);
    } else {
      toast.error(
        "Invalid File Type! Only Files with format of mp4, mpeg, mov, flv, png, jpg, jpeg, pdf will be uploaded!!"
      );
    }
  };

  const getDocName = (document: string) => {
    let docName = undefined;
    if (document.includes("!-!-")) {
      let splitDocName = document.split("!-!-");
      docName = splitDocName[splitDocName.length - 1];
    } else if (document.includes("Qualification-Docs")) {
      let splitDocName = document.split("Qualification-Docs/");
      docName = splitDocName[splitDocName.length - 1];
    } else {
      let splitDocName = document.split("--._.--");
      docName = splitDocName[splitDocName.length - 1];
    }
    return docName;
  };

  const handleDeleteDocument = (index: any) => {
    let tempArray = [...docsArray];
    tempArray.splice(index, 1);
    entityDocuments.splice(index, 1);
    setDocsArray(tempArray);
  };

  const handleOnClick = (event: any) => {
    const { target = {} } = event || {};
    target.value = "";
  };

  const getDate = (date?: Date) => {
    const fDate: Date = date ? date : new Date();
    return fDate.toISOString().toString().replace("T", " ").replace("Z", "");
  };

  return (
    <ScrollView
      showsVerticalScrollIndicator={false}
      style={{
        width: 550,
        height: 700
      }}
      contentContainerStyle={{ flexGrow: 1 }}
    >
      <>
        {saveLoader ? (
          <Loader customLoadingContainer={styles.fullModalLoader} />
        ) : (
          <View style={styles.container}>
            <ToastContainer />
            <View style={styles.modalHeader}>
              <Text
                fontWeight={FontWeight.Medium}
                testId="addEmailText"
                textSize={TextSize.Small}
                textStyle={styles.headerText}
              >
                {"Register Entity"}
              </Text>
              <View>
                <Pressable onPress={() => onClose()}>
                  <Icon testID="close" name={IconNames.crossCircle} />
                </Pressable>
              </View>
            </View>

            <Formik
              initialValues={{
                name: undefined,
                registrationNumber: undefined,
                taxRegNumber: undefined,
                legalStatus: undefined,
                address: undefined,
                address2: undefined,
                city: undefined,
                state: undefined,
                country: undefined,
                pinCode: undefined,
                websiteUrl: undefined,
                noOfEmployees: undefined,
                mission: undefined,
                status: "active",
                createdBy: UserHelper.getUserName(),
                userId: UserHelper.getUserId()
              }}
              onSubmit={(values, { setSubmitting }) => {
                handleInvite(values);
              }}
              validationSchema={formValidaionSchema}
              // enableReinitialize={true}
            >
              {(
                formikProps: FormikProps<{
                  name: string;
                  registrationNumber: string;
                  taxRegNumber: string;
                  legalStatus: string;
                  address: string;
                  address2: string;
                  city: string;
                  state: string;
                  country: string;
                  pinCode: string;
                  websiteUrl: string;
                  noOfEmployees: string;
                  mission: string;
                  status: string;
                  createdBy: string;
                  userId: string;
                }>
              ) => (
                <Form>
                  <View>
                    <View style={styles.titleContainer}>
                      <View style={styles.textFieldContianer}>
                        <View style={styles.labelTextContainer}>
                          <Text
                            fontWeight={FontWeight.Regular}
                            testId="qualificationTitleText"
                            textSize={TextSize.Small}
                            textStyle={styles.subheaderText}
                          >
                            Entity Name
                          </Text>
                        </View>
                        <View style={styles.contentContainer}>
                          <TextField
                            label=""
                            handleChange={formikProps.handleChange("name")}
                            handleBlur={() => formikProps.setFieldTouched}
                            value={formikProps.values.name}
                            inputStyle={[styles.inputMobile, styles.entityContainer]}
                            placeholder="Enter entity name"
                            name="name"
                            id="name"
                            editable={true}
                            formikPropsTouched={
                              formikProps.touched.name === undefined
                                ? false
                                : formikProps.touched.name
                            }
                            customChange={() => formikProps.setFieldTouched("name", false)}
                          />
                        </View>
                      </View>
                    </View>

                    {/* <View style={styles.titleContainer}>
                      <View style={styles.textFieldContianer}>
                        <View style={styles.labelTextContainer}>
                          <Text
                            fontWeight={FontWeight.Regular}
                            testId="qualificationTitleText"
                            textSize={TextSize.Small}
                            textStyle={styles.subheaderText}
                          >
                            Registered Date
                          </Text>
                        </View>
                        <View style={styles.contentContainer}>
                          <DatePicker
                            min="2019-01-01"
                            max="2050-12-31"
                            handleBlur={() => formikProps.setFieldTouched}
                            onChange={formikProps.handleChange("startDate")}
                            value={formikProps.values.startDate}
                            name="startDate"
                            id="startDate"
                            inputStyle={true}
                          />
                        </View>
                      </View>
                    </View>

                    <View style={styles.titleContainer}>
                      <View style={styles.textFieldContianer}>
                        <View style={styles.labelTextContainer}>
                          <Text
                            fontWeight={FontWeight.Regular}
                            testId="qualificationTitleText"
                            textSize={TextSize.Small}
                            textStyle={styles.subheaderText}
                          >
                            Register By End Date
                          </Text>
                        </View>
                        <View style={styles.contentContainer}>
                          <DatePicker
                            min={formikProps.values.startDate}
                            handleBlur={() => formikProps.setFieldTouched}
                            onChange={formikProps.handleChange("endDate")}
                            value={formikProps.values.endDate}
                            name="endDate"
                            id="endDate"
                            inputStyle={true}
                          />
                        </View>
                      </View>
                    </View> */}

                    <View style={styles.titleContainer}>
                      <View style={styles.textFieldContianer}>
                        <View style={styles.labelTextContainer}>
                          <Text
                            fontWeight={FontWeight.Regular}
                            testId="qualificationTitleText"
                            textSize={TextSize.Small}
                            textStyle={[styles.subheaderText, { marginTop: 4 }]}
                          >
                            Business Registration No.
                          </Text>
                        </View>
                        <View style={styles.contentContainer}>
                          <TextField
                            label=""
                            handleChange={formikProps.handleChange("registrationNumber")}
                            handleBlur={() => formikProps.setFieldTouched}
                            value={formikProps.values.registrationNumber}
                            inputStyle={[
                              styles.inputMobile,
                              styles.entityContainer,
                              { width: "100%" }
                            ]}
                            placeholder="Enter Business registration number"
                            name="registrationNumber"
                            id="registrationNumber"
                            formikPropsTouched={
                              formikProps.touched.registrationNumber === undefined
                                ? false
                                : formikProps.touched.registrationNumber
                            }
                            customChange={() =>
                              formikProps.setFieldTouched("registrationNumber", false)
                            }
                          />
                        </View>
                      </View>
                    </View>

                    <View style={styles.titleContainer}>
                      <View style={styles.textFieldContianer}>
                        <View style={styles.labelTextContainer}>
                          <Text
                            fontWeight={FontWeight.Regular}
                            testId="qualificationTitleText"
                            textSize={TextSize.Small}
                            textStyle={[styles.subheaderText, { marginTop: 4 }]}
                          >
                            Tax Registration No.
                          </Text>
                        </View>
                        <View style={styles.contentContainer}>
                          <TextField
                            label=""
                            handleChange={formikProps.handleChange("taxRegNumber")}
                            handleBlur={() => formikProps.setFieldTouched}
                            value={formikProps.values.taxRegNumber}
                            inputStyle={[
                              styles.inputMobile,
                              styles.entityContainer,
                              { width: "100%" }
                            ]}
                            placeholder="Enter tax registration number"
                            name="taxRegNumber"
                            id="taxRegNumber"
                            formikPropsTouched={
                              formikProps.touched.taxRegNumber === undefined
                                ? false
                                : formikProps.touched.taxRegNumber
                            }
                            customChange={() => formikProps.setFieldTouched("taxRegNumber", false)}
                          />
                        </View>
                      </View>
                    </View>

                    <View style={styles.titleContainer}>
                      <View style={styles.textFieldContianer}>
                        <View style={styles.labelTextContainer}>
                          <Text
                            fontWeight={FontWeight.Regular}
                            testId="qualificationTitleText"
                            textSize={TextSize.Small}
                            textStyle={[styles.subheaderText]}
                          >
                            Legal Status
                          </Text>
                        </View>
                        <View style={styles.contentContainer}>
                          <TextField
                            label=""
                            handleChange={formikProps.handleChange("legalStatus")}
                            handleBlur={() => formikProps.setFieldTouched}
                            value={formikProps.values.legalStatus}
                            inputStyle={[styles.inputMobile, styles.entityContainer]}
                            placeholder="Enter legal status"
                            name="legalStatus"
                            id="legalStatus"
                            formikPropsTouched={
                              formikProps.touched.legalStatus === undefined
                                ? false
                                : formikProps.touched.legalStatus
                            }
                            customChange={() => formikProps.setFieldTouched("legalStatus", false)}
                          />
                        </View>
                      </View>
                    </View>

                    <View style={styles.titleContainer}>
                      <View style={styles.textFieldContianer}>
                        <View style={styles.labelTextContainer}>
                          <Text
                            fontWeight={FontWeight.Regular}
                            testId="qualificationTitleText"
                            textSize={TextSize.Small}
                            textStyle={styles.subheaderText}
                          >
                            Address Line 1
                          </Text>
                        </View>
                        <View style={styles.contentContainer}>
                          <TextField
                            label=""
                            handleChange={formikProps.handleChange("address")}
                            handleBlur={() => formikProps.setFieldTouched}
                            value={formikProps.values.address}
                            inputStyle={[styles.inputMobile, styles.entityContainer]}
                            placeholder="Enter address"
                            name="address"
                            id="address"
                            formikPropsTouched={
                              formikProps.touched.address === undefined
                                ? false
                                : formikProps.touched.address
                            }
                            customChange={() => formikProps.setFieldTouched("address", false)}
                          />
                        </View>
                      </View>
                    </View>
                    <View style={styles.titleContainer}>
                      <View style={styles.textFieldContianer}>
                        <View style={styles.labelTextContainer}>
                          <Text
                            fontWeight={FontWeight.Regular}
                            testId="qualificationTitleText"
                            textSize={TextSize.Small}
                            textStyle={styles.subheaderText}
                          >
                            Address Line 2
                          </Text>
                        </View>
                        <View style={styles.contentContainer}>
                          <TextField
                            label=""
                            handleChange={formikProps.handleChange("address2")}
                            handleBlur={() => formikProps.setFieldTouched}
                            value={formikProps.values.address2}
                            inputStyle={[styles.inputMobile, styles.entityContainer]}
                            placeholder="Enter address2"
                            name="address2"
                            id="address2"
                            formikPropsTouched={
                              formikProps.touched.address2 === undefined
                                ? false
                                : formikProps.touched.address2
                            }
                            customChange={() => formikProps.setFieldTouched("address2", false)}
                          />
                        </View>
                      </View>
                    </View>

                    <View style={styles.titleContainer}>
                      <View style={styles.textFieldContianer}>
                        <View style={styles.labelTextContainer}>
                          <Text
                            fontWeight={FontWeight.Regular}
                            testId="qualificationTitleText"
                            textSize={TextSize.Small}
                            textStyle={styles.subheaderText}
                          >
                            City
                          </Text>
                        </View>
                        <View style={styles.contentContainer}>
                          <TextField
                            label=""
                            handleChange={formikProps.handleChange("city")}
                            handleBlur={() => formikProps.setFieldTouched}
                            value={formikProps.values.city}
                            inputStyle={[styles.inputMobile, styles.entityContainer]}
                            placeholder="Enter city"
                            name="city"
                            id="city"
                            formikPropsTouched={
                              formikProps.touched.city === undefined
                                ? false
                                : formikProps.touched.city
                            }
                            customChange={() => formikProps.setFieldTouched("city", false)}
                          />
                        </View>
                      </View>
                    </View>

                    <View style={styles.titleContainer}>
                      <View style={styles.textFieldContianer}>
                        <View style={styles.labelTextContainer}>
                          <Text
                            fontWeight={FontWeight.Regular}
                            testId="qualificationTitleText"
                            textSize={TextSize.Small}
                            textStyle={[styles.subheaderText, { marginRight: 55 }]}
                          >
                            State
                          </Text>
                        </View>
                        <View style={styles.contentContainer}>
                          <TextField
                            label=""
                            handleChange={formikProps.handleChange("state")}
                            handleBlur={() => formikProps.setFieldTouched}
                            value={formikProps.values.state}
                            inputStyle={[styles.inputMobile, styles.entityContainer]}
                            placeholder="Enter state"
                            name="state"
                            id="state"
                            formikPropsTouched={
                              formikProps.touched.state === undefined
                                ? false
                                : formikProps.touched.state
                            }
                            customChange={() => formikProps.setFieldTouched("state", false)}
                          />
                        </View>
                      </View>
                    </View>

                    <View style={styles.titleContainer}>
                      <View style={styles.textFieldContianer}>
                        <View style={styles.labelTextContainer}>
                          <Text
                            fontWeight={FontWeight.Regular}
                            testId="qualificationTitleText"
                            textSize={TextSize.Small}
                            textStyle={styles.subheaderText}
                          >
                            Country
                          </Text>
                        </View>
                        <View style={styles.contentContainer}>
                          <TextField
                            label=""
                            handleChange={formikProps.handleChange("country")}
                            handleBlur={() => formikProps.setFieldTouched}
                            value={formikProps.values.country}
                            inputStyle={[styles.inputMobile, styles.entityContainer]}
                            placeholder="Enter country"
                            name="country"
                            id="country"
                            formikPropsTouched={
                              formikProps.touched.country === undefined
                                ? false
                                : formikProps.touched.country
                            }
                            customChange={() => formikProps.setFieldTouched("country", false)}
                          />
                        </View>
                      </View>
                    </View>

                    <View style={styles.titleContainer}>
                      <View style={styles.textFieldContianer}>
                        <View style={styles.labelTextContainer}>
                          <Text
                            fontWeight={FontWeight.Regular}
                            testId="qualificationTitleText"
                            textSize={TextSize.Small}
                            textStyle={[styles.subheaderText, { marginRight: 10 }]}
                          >
                            Pin/Zip code
                          </Text>
                        </View>
                        <View style={styles.contentContainer}>
                          <TextField
                            label=""
                            handleChange={formikProps.handleChange("pinCode")}
                            handleBlur={() => formikProps.setFieldTouched}
                            value={formikProps.values.pinCode}
                            inputStyle={[styles.inputMobile, styles.entityContainer]}
                            placeholder="Enter pin/zip code"
                            name="pinCode"
                            id="pinCode"
                            formikPropsTouched={
                              formikProps.touched.pinCode === undefined
                                ? false
                                : formikProps.touched.pinCode
                            }
                            customChange={() => formikProps.setFieldTouched("pinCode", false)}
                          />
                        </View>
                      </View>
                    </View>

                    <View style={styles.titleContainer}>
                      <View style={styles.textFieldContianer}>
                        <View style={styles.labelTextContainer}>
                          <Text
                            fontWeight={FontWeight.Regular}
                            testId="qualificationTitleText"
                            textSize={TextSize.Small}
                            textStyle={styles.subheaderText}
                          >
                            website
                          </Text>
                        </View>
                        <View style={styles.contentContainer}>
                          <TextField
                            label=""
                            handleChange={formikProps.handleChange("websiteUrl")}
                            handleBlur={() => formikProps.setFieldTouched}
                            value={formikProps.values.websiteUrl}
                            inputStyle={[styles.inputMobile, styles.entityContainer]}
                            placeholder="Enter websiteUrl"
                            name="websiteUrl"
                            id="websiteUrl"
                            formikPropsTouched={
                              formikProps.touched.websiteUrl === undefined
                                ? false
                                : formikProps.touched.websiteUrl
                            }
                            customChange={() => formikProps.setFieldTouched("websiteUrl", false)}
                          />
                        </View>
                      </View>
                    </View>

                    <View style={styles.titleContainer}>
                      <View style={styles.textFieldContianer}>
                        <View style={styles.labelTextContainer}>
                          <Text
                            fontWeight={FontWeight.Regular}
                            testId="qualificationTitleText"
                            textSize={TextSize.Small}
                            textStyle={styles.subheaderText}
                          >
                            Number of Employees
                          </Text>
                        </View>
                        <View style={styles.contentContainer}>
                          <TextField
                            label=""
                            handleChange={formikProps.handleChange("noOfEmployees")}
                            handleBlur={() => formikProps.setFieldTouched}
                            value={formikProps.values.noOfEmployees}
                            inputStyle={[styles.inputMobile, styles.entityContainer]}
                            placeholder="Enter Number of Employees"
                            name="noOfEmployees"
                            id="noOfEmployees"
                            formikPropsTouched={
                              formikProps.touched.noOfEmployees === undefined
                                ? false
                                : formikProps.touched.noOfEmployees
                            }
                            customChange={() => formikProps.setFieldTouched("noOfEmployees", false)}
                          />
                        </View>
                      </View>
                    </View>

                    <View style={styles.titleContainer}>
                      <View style={styles.textFieldContianer}>
                        <View style={styles.labelTextContainer}>
                          <Text
                            fontWeight={FontWeight.Regular}
                            testId="qualificationTitleText"
                            textSize={TextSize.Small}
                            textStyle={styles.subheaderText}
                          >
                            Mission
                          </Text>
                        </View>
                        <View style={styles.contentContainer}>
                        <TextField
                            label=""
                            handleChange={formikProps.handleChange("mission")}
                            handleBlur={() => formikProps.setFieldTouched}
                            value={formikProps.values.mission}
                            inputStyle={[styles.inputMobile, styles.entityContainer]}
                            placeholder="Enter Mission"
                            name="mission"
                            id="mission"
                            formikPropsTouched={
                              formikProps.touched.mission === undefined
                                ? false
                                : formikProps.touched.mission
                            }
                            customChange={() => formikProps.setFieldTouched("mission", false)}
                          />
                        </View>
                      </View>
                    </View>

                    <View style={styles.lineThrough}></View>
                    <View style={styles.flexContainer}>
                      <Text
                        fontWeight={FontWeight.Regular}
                        testId="qualificationTitleText"
                        textSize={TextSize.Small}
                        textStyle={[styles.uploadHeaderText, { fontSize: 10 }]}
                      >
                        Documents
                      </Text>
                      <TouchableOpacity style={styles.addDocument} onPress={handleClick}>
                        <Image
                          testId="primaryAddImg"
                          source={require("@socion-cordio/common/src/assets/images/primary_add.svg")}
                          imageStyle={{
                            width: 28,
                            height: 28
                          }}
                        />
                        <input
                          style={{
                            display: "none",
                            zIndex: -1
                          }}
                          ref={hiddenFileInput}
                          type="file"
                          onChange={async (e) => {
                            await handleCustomChange(e);
                          }}
                          onClick={handleOnClick}
                        />
                      </TouchableOpacity>
                    </View>
                    {docsArray.length > 0 && (
                      <View style={styles.docsContainer}>
                        {loading ? (
                          <Loader customLoadingContainer={styles.loaderContiner} />
                        ) : (
                          <ScrollContainer
                            height="100px"
                            scrollable={docsArray.length > 1 ? true : false}
                          >
                            {docsArray?.map((item: any, index: number) => (
                              <View style={styles.qualificationContainer} key={index}>
                                <View style={styles.docNameContainer}>
                                  <Text
                                    fontWeight={FontWeight.Regular}
                                    testId="qualificationTitleText"
                                    textSize={TextSize.Small}
                                    textStyle={styles.qualificationTitle}
                                  >
                                    {getDocName(item?.name)}
                                  </Text>
                                </View>
                                <Icon
                                  testID="close"
                                  name={IconNames.deleteFile}
                                  customStyle={styles.uploadDocIcon}
                                  onPress={() => handleDeleteDocument(index)}
                                />
                              </View>
                            ))}
                          </ScrollContainer>
                        )}
                      </View>
                    )}
                    <View style={styles.buttonContainer}>
                      <Button
                        type={ButtonType.Primary}
                        buttonStyles={styles.button}
                        textStyles={styles.buttonFont}
                        // disabled={
                        //   isEmpty(formikProps.touched) || !isEmpty(formikProps.errors || disable)
                        // }
                        title="Save"
                        onPress={() => {
                          formikProps.handleSubmit();
                        }}
                      />
                    </View>
                  </View>
                </Form>
              )}
            </Formik>
          </View>
        )}
      </>
    </ScrollView>
  );
}

const styles = StyleSheet.create({
  container: {
    display: "flex",
    // width: 655,
    width: 550
  },
  modalHeader: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row",
    marginBottom: 25
  },
  headerText: {
    fontWeight: "700",
    fontSize: 12,
    fontFamily: FontFamily.Bold,
    lineHeight: 17
  },
  uploadPictureContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center"
    // marginTop: "1rem"
  },
  uploadPicture: {
    width: "450px",
    height: "300px",
    backgroundColor: "#F8F8F8",
    borderRadius: 5
  },
  uploadPictureText: {
    margin: "auto",
    color: colorPallete.textBlack,
    fontSize: 14,
    opacity: 0.5
  },
  customUpload: {
    alignSelf: "center",
    opacity: 0.8
  },
  titleContainer: {
    display: "flex",
    flexDirection: "row",
    flex: 50,
    minHeight: 65
  },
  subheaderText: {
    fontWeight: "400",
    fontSize: 12,
    fontFamily: FontFamily.Regular,
    lineHeight: 17,
    color: colorPallete.textLight,
    marginTop: 13
  },
  textFieldContianer: {
    flexDirection: "row",
    width: "100%",
    display: "flex"
  },
  labelTextContainer: {
    flex: 30
  },
  contentContainer: {
    flex: 70
  },
  labelSecondaryContainer: {
    flexDirection: "row"
  },
  labelTextSecondaryContainer: {
    flex: 28
  },
  contentSecondaryContainer: {
    flex: 58
  },
  labelTextSmallContainer: {
    flex: 26
  },
  contentSmallContainer: {
    flex: 50
  },
  textFieldContainer: {
    flex: 36
  },
  textInputContainer: {
    flex: 40,
    alignItems: "center"
  },
  flex50Container: {
    flex: 50
  },
  alignContentContainer: {
    flex: 80,
    flexDirection: "row",
    justifyContent: "space-between"
  },
  subTextFieldContianer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around"
  },
  inputMobile: {
    height: 40,
    outline: "none",
    borderRadius: 5,
    padding: 7,
    fontSize: 12,
    fontFamily: FontFamily.Medium,
    color: colorPallete.textBlack,
    // fontWeight: "400",
    width: "100%"
  },
  entityContainer: {
    fontFamily: FontFamily.Medium,
    fontWeight: "400",
    padding: 0
  },
  alignInputFieldMedium: {
    width: "70%"
  },
  alignHeader: {
    marginTop: 13
  },
  uploadHeaderText: {
    fontWeight: "400",
    fontSize: 14,
    fontFamily: FontFamily.Regular,
    lineHeight: 17,
    color: colorPallete.textLight
  },
  button: {
    width: "140px",
    height: "50px",
    borderRadius: 10
  },
  disabledButton: {},
  buttonFont: {
    fontSize: 12
  },
  alignAddressText: {
    paddingLeft: 5,
    marginTop: 5,
    borderRadius: 5,
    borderColor: colorPallete.gray,
    borderStyle: "solid",
    borderWidth: 1
  },
  buttonContainer: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "12px"
  },
  iconStyle: {
    color: colorPallete.cordioTaupe,
    marginLeft: 5,
    marginRight: 5,
    fontSize: 15
  },
  iconContainer: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center"
  },
  memberInfoContainer: {
    flex: 80
  },
  alignText: {
    fontSize: 12,
    color: colorPallete.textLight,
    marginTop: 10,
    FontFamily: FontFamily.Medium,
    // fontWeight:'900'
    fontWeight: "400"
  },
  textColor: {
    color: colorPallete.textBlack,
    fontFamily: FontFamily.Regular,
    fontWeight: "700"
  },
  subContainer: {
    flexDirection: "row",
    marginBottom: 25,
    width: "680px"
  },
  listMemberContainer: {},
  addDocument: {
    alignSelf: "flex-end",
    float: "right",
    marginLeft: "auto"
  },
  uploadDocIcon: {
    opacity: 0.5
  },
  docsContainer: {
    marginBottom: 10
  },
  qualificationContainer: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row",
    alignItems: "center",
    borderRadius: 5,
    // height: "80px",
    marginRight: "5px",
    padding: 15,
    marginTop: 10,
    borderColor: "#3FA202",
    borderStyle: "solid",
    borderWidth: 1
  },
  qualificationTitle: {
    fontWeight: "700",
    fontSize: 12,
    fontFamily: FontFamily.Medium,
    lineHeight: 17
  },
  loaderContiner: {
    width: 655,
    height: 100
  },
  lineThrough: {
    width: 655,
    height: 0,
    opacity: 0.1,
    border: "1px solid #675850",
    marginBottom: "10px"
  },
  flexContainer: {
    display: "flex",
    flexDirection: "row",
    marginBottom: "10px"
  },
  fullModalLoader: {
    // width: 655,
    // height: 482,
    height: "50%"
  },
  docNameContainer: {
    width: 600
  }
});

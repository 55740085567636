import React, { ReactElement } from "react";
import { StyleSheet, View } from "react-native";
import { colorPallete } from "@socion-cordio/common/src/assets/styles/colors";
import {
  Text,
  TextSize,
  FontWeight,
  FontFamily
} from "@socion-cordio/common/src/components/atoms/text";
import Icon, { IconNames } from "@socion-cordio/common/src/components/atoms/icon";
import Button, { ButtonType } from "@socion-cordio/common/src/components/atoms/button";
import { auxiliaryMethods } from "@socion-cordio/common/src/utils/auxiliaryMethods";
import { Image } from "@socion-cordio/common/src/components/atoms/image";

interface Props {
  onClose: Function;
  userDetails?: any;
  status?: string;
  toggleActivate?: Function;
}

export default function RemoveAdminModal(props: Props): ReactElement {

  const getPageTitle = () => {
    return (
      <View style={{paddingBottom: 20}}>
        <Text
          fontWeight={FontWeight.Bold}
          testId="headerText"
          textSize={TextSize.Small}
          textStyle={[styles.headerText, styles.miniContainerHeaderText]}
        >
          {props.status === "deactivate" ? 'Remove Administrator' : 'Reactivate Administrator'}
        </Text>
      </View>
    );
  };

  return (
    <>
    {getPageTitle()}
      {props.userDetails && (
        <>
          <View style={styles.header}>
            <Icon
              testID="close"
              name={IconNames.crossCircle}
              customStyle={styles.crossIcon}
              onPress={() => props.onClose()}
            />
          </View>
          <View style={styles.subContainer}>
            <View style={styles.profilePictureContainer}>
              <View style={styles.profilePicture}>
                <Image
                  imageStyle={styles.profilePicture}
                  testId="socionImg"
                  source={
                    props.userDetails?.photo === ""
                      ? require("@socion-cordio/common/src/assets/images/user_circle.svg")
                      : props.userDetails?.photo
                  }
                />
              </View>
            </View>
            <View style={styles.memberInfoContainer}>
              <Text
                fontWeight={FontWeight.Bold}
                testId="addRoleText"
                textSize={TextSize.Small}
                textStyle={styles.headerText}
              >
                {props.userDetails.name}
              </Text>
              <View style={styles.userDetailsContainer}>
                <Text
                  fontWeight={FontWeight.Regular}
                  testId="phoneNumberLabel"
                  textSize={TextSize.Small}
                  textStyle={[styles.alignText]}
                >
                  {"Contact Number : "}
                </Text>
                <Text
                  fontWeight={FontWeight.Regular}
                  testId="phoneNumber"
                  textSize={TextSize.Small}
                  textStyle={[styles.alignText]}
                >
                  {props.userDetails.piiInfo
                    ? `${props.userDetails.countryCode} ${props.userDetails.phoneNumber}`
                    : `NA`}
                </Text>
              </View>
              <View style={styles.userDetailsContainer}>
                <Text
                  fontWeight={FontWeight.Regular}
                  testId="addRoleText"
                  textSize={TextSize.Small}
                  textStyle={[styles.alignText]}
                >
                  {"Contact Email : "}
                </Text>
                <Text
                  fontWeight={FontWeight.Regular}
                  testId="addRoleText"
                  textSize={TextSize.Small}
                  textStyle={[styles.alignText]}
                >
                  {props.userDetails.piiInfo
                    ? props.userDetails.emailId !== ""
                      ? props.userDetails.emailId
                      : ""
                    : `NA`}
                </Text>
              </View>
              <Text
                fontWeight={FontWeight.Regular}
                testId="addRoleText"
                textSize={TextSize.Small}
                textStyle={[styles.alignText]}
              >
                Base location :
              </Text>
              <Text
                fontWeight={FontWeight.Regular}
                testId="addRoleText"
                textSize={TextSize.Small}
                textStyle={[styles.alignText, styles.textColor]}
              >
                {auxiliaryMethods.fromatBaseLocation(props.userDetails) === ""
                  ? "None"
                  : auxiliaryMethods.fromatBaseLocation(props.userDetails)}
              </Text>
            </View>
          </View>
          {props.status === "deactivate" && (
            <View style={styles.buttonContainer}>
              <Button
                type={ButtonType.Primary}
                buttonStyles={styles.button}
                textStyles={styles.buttonFont}
                title="Remove Administrator"
                onPress={() => props.toggleActivate(props.userDetails)}
              />
            </View>
          )}
          {props.status === "activate" && (
            <View style={styles.buttonContainer}>
              <Button
                type={ButtonType.Primary}
                buttonStyles={styles.button}
                textStyles={styles.buttonFont}
                title="Reactivate Administrator"
                onPress={() => props.toggleActivate(props.userDetails)}
              />
            </View>
          )}
        </>
      )}
    </>
  );
}

const styles = StyleSheet.create({
  header: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row"
  },
  headerText: {
    fontSize: 14,
    fontFamily: FontFamily.Medium,
    lineHeight: 17
  },
  miniContainerHeaderText: {
    color: colorPallete.textBlack,
    fontSize: 16,
    fontFamily: FontFamily.Medium,
    lineHeight: 20
  },
  memberInfoContainer: {
    flex: 80
  },
  subContainer: {
    flexDirection: "row",
    marginBottom: 25,
    width: "680px"
  },
  profilePictureContainer: {
    flex: 20,
    alignItems: "center"
  },
  profilePicture: {
    borderRadius: 54,
    width: " 80px",
    height: "80px"
  },
  alignText: {
    fontSize: 14,
    color: colorPallete.textLight,
    marginTop: 10,
    FontFamily: FontFamily.Medium,
    fontWeight: "400"
  },
  textColor: {
    color: colorPallete.textBlack,
    fontFamily: FontFamily.Regular,
    fontWeight: "700"
  },
  button: {
    // width: "167px",
    height: "50px",
    borderRadius: 10
  },
  buttonFont: {
    fontSize: 14
  },
  buttonContainer: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    marginTop: 15
  },
  crossIcon: {
    fontSize: 15,
    marginLeft: 700,
    marginBottom: 49
  },
  userDetailsContainer: {
    flexDirection: "row"
  }
});
